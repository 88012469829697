export default function DatabassesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinejoin="round"
        d="M15.51 17.01c3.14 0 5.09-1.32 5.09-1.32.23-.15.41-.51.41-.78V3.49M10.01 3.49V7"
      ></path>
      <path
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinejoin="round"
        d="M15.5 4.98c3.038 0 5.5-.667 5.5-1.49S18.538 2 15.5 2 10 2.667 10 3.49s2.462 1.49 5.5 1.49ZM15.51 10.7c3.14 0 5.5-1.35 5.5-1.35M3.01 8.49V19.9c0 .27.19.62.42.76 0 0 2.15 1.34 5.58 1.34s5.58-1.34 5.58-1.34c.23-.15.42-.49.42-.76V8.49"
      ></path>
      <path
        stroke="currentColor"
        strokeWidth="1.75"
        strokeMiterlimit="10"
        d="M3.01 14.35s2.57 1.35 6 1.35 6-1.35 6-1.35M9 9.98c3.314 0 6-.667 6-1.49S12.314 7 9 7s-6 .667-6 1.49 2.686 1.49 6 1.49Z"
      ></path>
    </svg>
  )
}
