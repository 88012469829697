import Heading from '@fool/jester-ui/Heading'
import {getUserTier} from '~data/api/tiers'
import {useSession} from 'next-auth/react'

export default function GameplanHeader() {
  const session = useSession()
  const userTier = getUserTier({session: session.data})

  return (
    <div className="flex flex-col">
      <div className="flex flex-nowrap gap-8px items-center">
        <Heading as="h1" looksLike="h2">
          {userTier.tier >= 2 ? 'GamePlan+' : 'GamePlan'}
        </Heading>
      </div>
    </div>
  )
}
