import {JSX} from 'react'
import classNames from 'classnames'
import React from 'react'

type CheckboxProps = Omit<JSX.IntrinsicElements['input'], 'type'>

//TODO: needs disabled style eventually, maybe hover states? need mock
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({className, ...inputProps}: CheckboxProps, ref) => (
    <input
      type="checkbox"
      className={classNames(
        className,
        `appearance-none rounded-[4px] cursor-pointer size-[20px] border-[2px] border-content-70
        after:checked:text-[18px] after:checked:content-['✓'] after:checked:text-content-0 after:checked:w-[16px]
        after:checked:h-[16px] after:checked:block after:checked:text-center after:checked:leading-3
        after:checked:absolute
        checked:border-primary-100 checked:bg-primary-100 disabled:text-content-36 disabled:border-content-36`,
      )}
      {...inputProps}
      ref={ref}
    />
  ),
)

Checkbox.displayName = 'Checkbox'
export default Checkbox
