'use client'

import * as React from 'react'
import {Slot} from '@radix-ui/react-slot'
import {type VariantProps, cva} from 'class-variance-authority'
import {ChevronLeftIcon} from '@heroicons/react/20/solid'
import {cn} from '~components/lib/utils'
import {Button} from '~components/ui/button'
import {Input} from '~components/ui/input'
import {Separator} from '~components/ui/separator'
import {Skeleton} from '~components/ui/skeleton'
import {useIsClient} from 'usehooks-ts'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '~components/ui/tooltip'
import {type SeparatorProps} from '@radix-ui/react-separator'
import useScreenSize, {
  isLargeSize,
  isMobileSize,
} from '~app/(main)/_hooks/useScreenSize'

const SIDEBAR_COOKIE_NAME = 'sidebar:state'
const SIDEBAR_WIDTH = '14.75rem'
const SIDEBAR_WIDTH_ICON = '4.5rem'
const SIDEBAR_KEYBOARD_SHORTCUT = 'b'
import GlobalTooltip from '~components/tooltip/tooltip'

type SidebarContext = {
  state: 'expanded' | 'collapsed'
  open: boolean
  setOpen: (open: boolean) => void
  openMobile: boolean
  setOpenMobile: (open: boolean) => void
  isMobile: boolean
  toggleSidebar: () => void
}

interface SidebarBaseProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

interface SidebarProviderProps extends SidebarBaseProps {
  defaultOpen?: boolean
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

interface SidebarProps extends React.ComponentProps<'div'> {
  side?: 'left' | 'right'
  variant?: 'sidebar' | 'floating' | 'inset'
  collapsible?: 'offcanvas' | 'icon' | 'none'
}

interface SidebarTriggerProps extends React.ComponentProps<typeof Button> {
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

interface SidebarRailProps extends React.ComponentProps<'button'> {
  className?: string
}

interface SidebarGroupLabelProps extends React.ComponentProps<'div'> {
  asChild?: boolean
  className?: string
}

interface SidebarGroupActionProps extends React.ComponentProps<'button'> {
  asChild?: boolean
  className?: string
}

interface SidebarMenuButtonProps extends React.ComponentProps<'button'> {
  asChild?: boolean
  isActive?: boolean
  variant?: VariantProps<typeof sidebarMenuButtonVariants>['variant']
  size?: VariantProps<typeof sidebarMenuButtonVariants>['size']
  tooltip?: string | React.ComponentProps<typeof TooltipContent>
  className?: string
}

interface SidebarMenuActionProps extends React.ComponentProps<'button'> {
  asChild?: boolean
  showOnHover?: boolean
  className?: string
}

interface SidebarMenuSkeletonProps extends React.ComponentProps<'div'> {
  showIcon?: boolean
  className?: string
}

interface SidebarMenuSubButtonProps extends React.ComponentProps<'a'> {
  asChild?: boolean
  size?: 'sm' | 'md'
  isActive?: boolean
  className?: string
}

interface ComponentWithClassName {
  className?: string
}

interface SidebarInsetProps
  extends React.ComponentProps<'main'>,
    ComponentWithClassName {}
interface SidebarDivProps
  extends React.ComponentProps<'div'>,
    ComponentWithClassName {}
interface SidebarMenuProps
  extends React.ComponentProps<'ul'>,
    ComponentWithClassName {}
interface SidebarMenuItemProps
  extends React.ComponentProps<'li'>,
    ComponentWithClassName {}
interface SidebarInputProps
  extends React.ComponentProps<typeof Input>,
    ComponentWithClassName {}

const SidebarContext = React.createContext<SidebarContext | null>(null)

function useSidebar() {
  const context = React.useContext(SidebarContext)
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider.')
  }

  return context
}

function usePersistedState(key: string, defaultValue: boolean) {
  const [state, setState] = React.useState(defaultValue)

  React.useEffect(() => {
    const stored = sessionStorage.getItem(key)
    if (stored !== null) {
      setState(stored === 'true')
    }
  }, [key])

  const setPersistedState = React.useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      setState((currentValue) => {
        const newValue =
          typeof value === 'function' ? value(currentValue) : value
        sessionStorage.setItem(key, String(newValue))
        return newValue
      })
    },
    [key],
  )

  return [state, setPersistedState] as const
}

const SidebarProvider = ({
  ref,
  defaultOpen = true,
  open: openProp,
  onOpenChange: setOpenProp,
  ...props
}: SidebarProviderProps & {ref?: React.Ref<HTMLDivElement>}) => {
  const isClient = useIsClient()
  const currentScreen = useScreenSize()
  const isMobile = isClient && isMobileSize(currentScreen)

  const [openMobile, setOpenMobile] = React.useState(false)

  const [_open, _setOpen] = usePersistedState(SIDEBAR_COOKIE_NAME, defaultOpen)
  const open = openProp ?? _open
  const setOpen = React.useCallback(
    (value: boolean | ((value: boolean) => boolean)) => {
      if (setOpenProp) {
        setOpenProp(typeof value === 'function' ? value(open) : value)
      } else {
        _setOpen(value)
      }
    },
    [setOpenProp, open, _setOpen],
  )

  const toggleSidebar = React.useCallback(() => {
    return isMobile ? setOpenMobile((open) => !open) : setOpen((open) => !open)
  }, [isMobile, setOpen, setOpenMobile])

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === SIDEBAR_KEYBOARD_SHORTCUT &&
        (event.metaKey || event.ctrlKey)
      ) {
        event.preventDefault()
        toggleSidebar()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [toggleSidebar])

  const state = open ? 'expanded' : 'collapsed'

  const contextValue = React.useMemo<SidebarContext>(
    () => ({
      state,
      open,
      setOpen,
      isMobile,
      openMobile,
      setOpenMobile,
      toggleSidebar,
    }),
    [state, open, setOpen, isMobile, openMobile, setOpenMobile, toggleSidebar],
  )

  const getSidebarWidth = React.useCallback(() => {
    // Mobile or large screens hide sidebar completely
    if (isMobile || isLargeSize(currentScreen)) {
      return '0'
    }

    // Expanded state shows full width, collapsed shows icon only
    return state === 'expanded' ? SIDEBAR_WIDTH : SIDEBAR_WIDTH_ICON
  }, [isMobile, currentScreen, state])

  return (
    <SidebarContext.Provider value={contextValue}>
      <TooltipProvider delayDuration={0}>
        <div
          style={
            {
              '--sidebar-width': SIDEBAR_WIDTH,
              '--sidebar-width-icon': SIDEBAR_WIDTH_ICON,
              '--sidebar-active-width': getSidebarWidth(),
            } as React.CSSProperties
          }
          className={cn(
            'group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-sidebar z',
            props.className,
          )}
          ref={ref}
          {...props}
        >
          {props.children}
        </div>
      </TooltipProvider>
    </SidebarContext.Provider>
  )
}
SidebarProvider.displayName = 'SidebarProvider'

const Sidebar = ({
  ref,
  side = 'left',
  variant = 'sidebar',
  collapsible = 'offcanvas',
  className,
  children,
  ...props
}: SidebarProps & {ref?: React.Ref<HTMLDivElement>}) => {
  const {isMobile, state} = useSidebar()

  if (collapsible === 'none') {
    return (
      <div
        className={cn(
          'flex h-full w-[--sidebar-width] flex-col text-sidebar-foreground relative z-10',
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  }

  if (isMobile) return <></>

  return (
    <div
      ref={ref}
      className="group peer hidden lg:block text-sidebar-foreground bg-content-92 text-content-4 relative z-10"
      data-state={state}
      data-collapsible={state === 'collapsed' ? collapsible : ''}
      data-variant={variant}
      data-side={side}
    >
      <div
        className={cn(
          'duration-200 relative h-svh w-[--sidebar-width] bg-transparent transition-[width] ease-linear',
          'group-data-[collapsible=offcanvas]:w-0',
          'group-data-[side=right]:rotate-180',
          variant === 'floating' || variant === 'inset'
            ? 'group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]'
            : 'group-data-[collapsible=icon]:w-[--sidebar-width-icon]',
        )}
      />
      <div
        className={cn(
          'duration-200 fixed inset-y-0 z-10 hidden h-svh w-[--sidebar-width] transition-[left,right,width] ease-linear md:flex',
          side === 'left'
            ? 'left-0 group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]'
            : 'right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]',
          variant === 'floating' || variant === 'inset'
            ? 'p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]'
            : 'group-data-[collapsible=icon]:w-[--sidebar-width-icon]',
          className,
        )}
        {...props}
      >
        <div
          data-sidebar="sidebar"
          className="flex h-full w-full flex-col bg-sidebar group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-sidebar-border group-data-[variant=floating]:shadow py-24px md:py-32px"
        >
          {children}
        </div>
      </div>
    </div>
  )
}
Sidebar.displayName = 'Sidebar'

const SidebarTrigger = ({
  ref,
  className,
  onClick,
  ...props
}: SidebarTriggerProps & {
  ref?: React.Ref<React.ElementRef<typeof Button>>
}) => {
  const sideBarState = useSidebar()
  const {toggleSidebar} = sideBarState

  return (
    <Button
      ref={ref}
      data-sidebar="trigger"
      variant="ghost"
      size="icon"
      className={cn(
        'absolute top-2 -ml-px transition-all duration-300 bg-secondary-nav hover:bg-secondary-nav/90 h-6 w-6 rounded-none border-none',
        sideBarState.state === 'collapsed'
          ? 'right-1/2 translate-x-1/2'
          : 'right-0',
        className,
      )}
      onClick={(event) => {
        onClick?.(event)
        toggleSidebar()
      }}
      {...props}
    >
      <GlobalTooltip
        className="grid"
        text={sideBarState.open ? 'Collapse Menu' : 'Expand Menu'}
      >
        <ChevronLeftIcon
          className={cn(
            sideBarState.open ? '' : '-scale-x-100',
            'transition-transform duration-300 size-4 text-content-4',
          )}
        />
        <span className="sr-only">Toggle Sidebar</span>
      </GlobalTooltip>
    </Button>
  )
}
SidebarTrigger.displayName = 'SidebarTrigger'

const SidebarRail = ({
  ref,
  className,
  ...props
}: SidebarRailProps & {ref?: React.Ref<HTMLButtonElement>}) => {
  const {toggleSidebar} = useSidebar()

  return (
    <button
      ref={ref}
      data-sidebar="rail"
      aria-label="Toggle Sidebar"
      tabIndex={-1}
      onClick={toggleSidebar}
      title="Toggle Sidebar"
      className={cn(
        'absolute inset-y-0 z-20 hidden w-4 -translate-x-1/2 transition-all ease-linear after:absolute after:inset-y-0 after:left-1/2 after:w-[2px] hover:after:bg-sidebar-border group-data-[side=left]:-right-4 group-data-[side=right]:left-0 sm:flex',
        '[[data-side=left]_&]:cursor-w-resize [[data-side=right]_&]:cursor-e-resize',
        '[[data-side=left][data-state=collapsed]_&]:cursor-e-resize [[data-side=right][data-state=collapsed]_&]:cursor-w-resize',
        'group-data-[collapsible=offcanvas]:translate-x-0 group-data-[collapsible=offcanvas]:after:left-full group-data-[collapsible=offcanvas]:hover:bg-sidebar',
        '[[data-side=left][data-collapsible=offcanvas]_&]:-right-2',
        '[[data-side=right][data-collapsible=offcanvas]_&]:-left-2',
        className,
      )}
      {...props}
    />
  )
}
SidebarRail.displayName = 'SidebarRail'

const SidebarInset = ({
  ref,
  className,
  ...props
}: SidebarInsetProps & {ref?: React.Ref<HTMLDivElement>}) => {
  return (
    <main
      ref={ref}
      className={cn(
        'relative flex min-h-svh flex-1 flex-col bg-background',
        'peer-data-[variant=inset]:min-h-[calc(100svh-theme(spacing.4))] md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-xl md:peer-data-[variant=inset]:shadow',
        className,
      )}
      {...props}
    />
  )
}
SidebarInset.displayName = 'SidebarInset'

const SidebarInput = ({
  ref,
  className,
  ...props
}: SidebarInputProps & {ref?: React.Ref<React.ElementRef<typeof Input>>}) => {
  return (
    <Input
      ref={ref}
      data-sidebar="input"
      className={cn(
        'h-8 w-full bg-background shadow-none focus-visible:ring-2 focus-visible:ring-sidebar-ring',
        className,
      )}
      {...props}
    />
  )
}
SidebarInput.displayName = 'SidebarInput'

const SidebarHeader = ({
  ref,
  className,
  ...props
}: SidebarDivProps & {ref?: React.Ref<HTMLDivElement>}) => {
  return (
    <div
      ref={ref}
      data-sidebar="header"
      className={cn('flex flex-col gap-2 p-2', className)}
      {...props}
    />
  )
}
SidebarHeader.displayName = 'SidebarHeader'

const SidebarFooter = ({
  ref,
  className,
  ...props
}: SidebarDivProps & {ref?: React.Ref<HTMLDivElement>}) => {
  return (
    <div
      ref={ref}
      data-sidebar="footer"
      className={cn('flex flex-col gap-2 p-2', className)}
      {...props}
    />
  )
}
SidebarFooter.displayName = 'SidebarFooter'

const SidebarSeparator = ({
  ref,
  className,
  ...props
}: SeparatorProps & {ref?: React.Ref<React.ElementRef<typeof Separator>>}) => {
  return (
    <Separator
      ref={ref}
      data-sidebar="separator"
      className={cn('mx-2 w-auto bg-sidebar-border', className)}
      {...props}
    />
  )
}
SidebarSeparator.displayName = 'SidebarSeparator'

const SidebarContent = ({
  ref,
  className,
  ...props
}: SidebarDivProps & {ref?: React.Ref<HTMLDivElement>}) => {
  return (
    <div
      ref={ref}
      data-sidebar="content"
      className={cn(
        'flex min-h-0 flex-1 flex-col gap-2 overflow-y-auto overflow-x-hidden',
        className,
      )}
      {...props}
    />
  )
}
SidebarContent.displayName = 'SidebarContent'

const SidebarGroup = ({
  ref,
  className,
  ...props
}: SidebarDivProps & {ref?: React.Ref<HTMLDivElement>}) => {
  return (
    <div
      ref={ref}
      data-sidebar="group"
      className={cn('relative flex w-full min-w-0 flex-col', className)}
      {...props}
    />
  )
}
SidebarGroup.displayName = 'SidebarGroup'

const SidebarGroupLabel = ({
  ref,
  className,
  asChild = false,
  ...props
}: SidebarGroupLabelProps & {ref?: React.Ref<HTMLDivElement>}) => {
  const Comp = asChild ? Slot : ('div' as React.ElementType)
  return (
    <Comp
      ref={ref}
      data-sidebar="group-label"
      className={cn(
        'duration-200 flex h-8 shrink-0 items-center rounded-md px-2 text-xs font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opa] ease-linear focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0',
        'group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0',
        className,
      )}
      {...props}
    />
  )
}
SidebarGroupLabel.displayName = 'SidebarGroupLabel'

const SidebarGroupAction = ({
  ref,
  className,
  asChild = false,
  ...props
}: SidebarGroupActionProps & {
  ref?: React.Ref<HTMLButtonElement | HTMLElement>
}) => {
  const Comp = asChild ? Slot : ('button' as React.ElementType)

  return (
    <Comp
      ref={ref}
      data-sidebar="group-action"
      className={cn(
        'absolute right-3 top-3.5 flex aspect-square w-5 items-center justify-center rounded-md p-0 text-sidebar-foreground outline-none ring-sidebar-ring transition-transform hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0',
        'after:absolute after:-inset-2 after:md:hidden',
        'group-data-[collapsible=icon]:hidden',
        className,
      )}
      {...props}
    />
  )
}
SidebarGroupAction.displayName = 'SidebarGroupAction'

const SidebarGroupContent = ({
  ref,
  className,
  ...props
}: SidebarDivProps & {ref?: React.Ref<HTMLDivElement>}) => (
  <div
    ref={ref}
    data-sidebar="group-content"
    className={cn('w-full text-sm', className)}
    {...props}
  />
)
SidebarGroupContent.displayName = 'SidebarGroupContent'

const SidebarMenu = ({
  ref,
  className,
  ...props
}: SidebarMenuProps & {ref?: React.Ref<HTMLUListElement>}) => (
  <ul
    ref={ref}
    data-sidebar="menu"
    className={cn('flex w-full min-w-0 flex-col', className)}
    {...props}
  />
)
SidebarMenu.displayName = 'SidebarMenu'

const SidebarMenuItem = ({
  ref,
  className,
  ...props
}: SidebarMenuItemProps & {ref?: React.Ref<HTMLLIElement>}) => (
  <li
    ref={ref}
    data-sidebar="menu-item"
    className={cn('group/menu-item relative grid', className)}
    {...props}
  />
)
SidebarMenuItem.displayName = 'SidebarMenuItem'

const sidebarMenuButtonVariants = cva(
  'peer/menu-button flex w-full items-center gap-2 overflow-hidden rounded-md p-2 text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground data-[state=open]:hover:bg-sidebar-accent data-[state=open]:hover:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 group-data-[collapsible=icon]:!p-2 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0',
  {
    variants: {
      variant: {
        default: 'hover:bg-sidebar-accent hover:text-sidebar-accent-foreground',
        outline:
          'bg-background shadow-[0_0_0_1px_hsl(var(--sidebar-border))] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground hover:shadow-[0_0_0_1px_hsl(var(--sidebar-accent))]',
      },
      size: {
        default: 'h-8 text-sm',
        sm: 'h-7 text-xs',
        lg: 'h-12 text-sm group-data-[collapsible=icon]:!p-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

const SidebarMenuButton = ({
  ref,
  className,
  asChild = false,
  isActive = false,
  variant = 'default',
  size = 'default',
  tooltip,
  ...props
}: SidebarMenuButtonProps & {
  ref?: React.Ref<HTMLButtonElement | HTMLElement>
}) => {
  const Comp = asChild ? Slot : ('button' as React.ElementType)
  const {isMobile, state} = useSidebar()

  const button = (
    <Comp
      ref={ref}
      data-sidebar="menu-button"
      data-size={size}
      data-active={isActive}
      className={cn(sidebarMenuButtonVariants({variant, size}), className)}
      {...props}
    />
  )

  if (!tooltip) {
    return button
  }

  if (typeof tooltip === 'string') {
    tooltip = {
      children: tooltip,
    }
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>{button}</TooltipTrigger>
      <TooltipContent
        side="right"
        align="center"
        hidden={state !== 'collapsed' || isMobile}
        {...tooltip}
      />
    </Tooltip>
  )
}
SidebarMenuButton.displayName = 'SidebarMenuButton'

const SidebarMenuAction = ({
  ref,
  className,
  asChild = false,
  showOnHover = false,
  ...props
}: SidebarMenuActionProps & {ref?: React.Ref<HTMLButtonElement>}) => {
  const Comp = asChild ? Slot : ('button' as React.ElementType)

  return (
    <Comp
      ref={ref}
      data-sidebar="menu-action"
      className={cn(
        'absolute right-1 top-1.5 flex aspect-square w-5 items-center justify-center rounded-md p-0 text-sidebar-foreground outline-none ring-sidebar-ring transition-transform hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 peer-hover/menu-button:text-sidebar-accent-foreground [&>svg]:size-4 [&>svg]:shrink-0',
        'after:absolute after:-inset-2 after:md:hidden',
        'peer-data-[size=sm]/menu-button:top-1',
        'peer-data-[size=default]/menu-button:top-1.5',
        'peer-data-[size=lg]/menu-button:top-2.5',
        'group-data-[collapsible=icon]:hidden',
        showOnHover &&
          'group-focus-within/menu-item:opacity-100 group-hover/menu-item:opacity-100 data-[state=open]:opacity-100 peer-data-[active=true]/menu-button:text-sidebar-accent-foreground md:opacity-0',
        className,
      )}
      {...props}
    />
  )
}
SidebarMenuAction.displayName = 'SidebarMenuAction'

const SidebarMenuBadge = ({
  ref,
  className,
  ...props
}: SidebarDivProps & {ref?: React.Ref<HTMLDivElement>}) => (
  <div
    ref={ref}
    data-sidebar="menu-badge"
    className={cn(
      'absolute right-1 flex h-5 min-w-5 items-center justify-center rounded-md px-1 text-xs font-medium tabular-nums text-sidebar-foreground select-none pointer-events-none',
      'peer-hover/menu-button:text-sidebar-accent-foreground peer-data-[active=true]/menu-button:text-sidebar-accent-foreground',
      'peer-data-[size=sm]/menu-button:top-1',
      'peer-data-[size=default]/menu-button:top-1.5',
      'peer-data-[size=lg]/menu-button:top-2.5',
      'group-data-[collapsible=icon]:hidden',
      className,
    )}
    {...props}
  />
)
SidebarMenuBadge.displayName = 'SidebarMenuBadge'

const SidebarMenuSkeleton = ({
  ref,
  className,
  showIcon = false,
  ...props
}: SidebarMenuSkeletonProps & {ref?: React.Ref<HTMLDivElement>}) => {
  const width = React.useMemo(() => {
    return `${Math.floor(Math.random() * 40) + 50}%`
  }, [])

  return (
    <div
      ref={ref}
      data-sidebar="menu-skeleton"
      className={cn('rounded-md h-8 flex gap-2 px-2 items-center', className)}
      {...props}
    >
      {showIcon && (
        <Skeleton
          className="size-4 rounded-md"
          data-sidebar="menu-skeleton-icon"
        />
      )}
      <Skeleton
        className="h-4 flex-1 max-w-[--skeleton-width]"
        data-sidebar="menu-skeleton-text"
        style={
          {
            '--skeleton-width': width,
          } as React.CSSProperties
        }
      />
    </div>
  )
}
SidebarMenuSkeleton.displayName = 'SidebarMenuSkeleton'

const SidebarMenuSub = ({
  ref,
  className,
  ...props
}: SidebarMenuProps & {ref?: React.Ref<HTMLUListElement>}) => (
  <ul
    ref={ref}
    data-sidebar="menu-sub"
    className={cn(
      'mx-3.5 flex min-w-0 translate-x-px flex-col gap-1 border-l border-sidebar-border px-2.5 py-0.5',
      'group-data-[collapsible=icon]:hidden',
      className,
    )}
    {...props}
  />
)
SidebarMenuSub.displayName = 'SidebarMenuSub'

const SidebarMenuSubItem = ({...props}: React.ComponentProps<'li'>) => (
  <li {...props} />
)
SidebarMenuSubItem.displayName = 'SidebarMenuSubItem'

const SidebarMenuSubButton = ({
  ref,
  className,
  asChild = false,
  size = 'md',
  isActive,
  ...props
}: SidebarMenuSubButtonProps & {ref?: React.Ref<HTMLElement>}) => {
  const Comp = asChild ? Slot : ('a' as React.ElementType)

  return (
    <Comp
      ref={ref}
      data-sidebar="menu-sub-button"
      data-size={size}
      data-active={isActive}
      className={cn(
        'flex h-7 min-w-0 -translate-x-px items-center gap-2 overflow-hidden rounded-md px-2 text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground',
        'data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground',
        size === 'sm' && 'text-xs',
        size === 'md' && 'text-sm',
        'group-data-[collapsible=icon]:hidden',
        className,
      )}
      {...props}
    />
  )
}
SidebarMenuSubButton.displayName = 'SidebarMenuSubButton'

export {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupAction,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInput,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSkeleton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarSeparator,
  SidebarTrigger,
  useSidebar,
}
