import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {CommentData, CommentType, RepliesData} from '~data/api/comments-types'

export type CommentListData = {
  comment_and_reply_count?: number | null
  comments?: CommentData[] | []
  commentType?: CommentType | undefined
  replies?: RepliesData
  discourseTopicId?: number | null
  discoursePostNumber?: number | null
  loadMore?: boolean
  nextPage?: number | null | undefined
}

export type CommentState = {
  comment_and_reply_count?: number | null
  comments?: CommentData[]
  commentType: CommentType
  loadMore?: boolean
  nextPage?: number | null | undefined
}

const initialState: CommentState = {
  comments: [],
  comment_and_reply_count: 0,
  commentType: 'note',
  loadMore: false,
  nextPage: null,
}

const commentSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    //For now, update the state of all comments and replies -- regardless of whether Article, Advisor Note or Portfolio. Update if problems arise so that each type of Comment has its own dispatch action and related fields.
    //There is a lot going on in these state updates, including updating just comments, pushing new replies and updating existing replies
    currentComments: (state, action: PayloadAction<CommentListData>) => {
      const {
        discourseTopicId,
        discoursePostNumber,
        replies,
        comments,
        commentType,
        comment_and_reply_count,
        loadMore,
        nextPage,
      } = action.payload
      state.nextPage = nextPage
      if (comments) {
        if (loadMore) {
          state.comments = [
            ...(state.comments || []), // Spread existing comments or an empty array if undefined
            ...(comments as CommentData[]), // Spread the new comments
          ]
        } else {
          state.comments = [...(comments as CommentData[])]
        }
      }
      if (commentType) {
        state.commentType = commentType
      }

      if (comment_and_reply_count !== null) {
        state.comment_and_reply_count = comment_and_reply_count
      }

      //Add a new reply
      if (discourseTopicId && discoursePostNumber && replies) {
        if (state.comments) {
          const updatedComments = state.comments.map((comment) => {
            if (
              comment.discourse_post_number === discoursePostNumber &&
              comment.discourse_topic_id === discourseTopicId
            ) {
              // If the comment matches, create a new object with the updated 'replies' property
              return {
                ...comment,
                replies: [replies, ...comment.replies],
              } as CommentData
            }
            // Return all other comments as they are
            return comment
          })
          // Now update the state with the new 'comments' array
          state.comments = updatedComments
        }
      } else if (discourseTopicId && replies) {
        //Edit an existing reply
        state.comments?.forEach((comment) => {
          if (comment.discourse_topic_id === replies?.discourse_topic_id) {
            const updatedReplies = comment.replies.map((reply: RepliesData) => {
              return reply.id === replies.id
                ? {...reply, comment_content: replies.comment_content}
                : reply
            })

            // Create a completely new array and assign it to the original property
            comment.replies = [...updatedReplies]
          }
        })
      }
    },
  },
})

export const {currentComments} = commentSlice.actions
export default commentSlice.reducer
