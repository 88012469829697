'use client'
import {usePathname} from 'next/navigation'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '~data/client/store'
import getPageLayoutData from '~data/getPageLayoutData'
import {useBraze} from '~hooks/use-braze'

export default function BrazeLogger() {
  const brazeClient = useBraze()
  const pathName = usePathname()
  const uuid = useSelector((state: RootState) => state.user.uuid)

  useEffect(() => {
    if (brazeClient && uuid) {
      brazeClient.setUser(uuid)
      brazeClient.requestContentCardsRefresh()
    }
  }, [brazeClient, uuid])

  useEffect(() => {
    brazeClient?.requestContentCardsRefresh()
    brazeClient?.logPageViewEvent(
      getPageLayoutData(pathName).headerTitle,
      pathName,
    )
  }, [brazeClient, pathName])

  return null
}
