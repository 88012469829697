'use client'
import {useDispatch, useSelector} from 'react-redux'
import Button from '@fool/jester-ui/Button'
import {Dialog} from '@headlessui/react'
import {AppDispatch, RootState} from '~data/client/store'
import {setFollowModal} from '~data/client/watches.slice'
import {invalidateBothHoldings} from '~app/(main)/my-stocks/my-stocks.slice'
import {useCallback, useEffect, useState} from 'react'
import {
  toggleWatches,
  fetchAndSetWatches,
  getMyStocksPortfolios,
} from '~app/(main)/my-stocks/utils/thunks'
import {Portfolio, MyPortfolioType} from '~types/my-stocks'
import Heading from '@fool/jester-ui/Heading'
import Checkbox from '~components/common/checkbox'
import {Link} from '~components/common/link'
import {MinimalQuote, getQuoteByInstrumentId} from '~data/api/instruments'
import PriceAndChangeChip from './priceAndChangeChip'
import {trackEvent} from 'src/app/(main)/tracking/infotrack'

function WatchModal() {
  const dispatch: AppDispatch = useDispatch()
  const watch = useSelector((state: RootState) => state.watches.modalWatch)
  const portfolios = useSelector(
    (state: RootState) => state.myStocks.portfolios,
  )
  const [quote, setQuote] = useState<MinimalQuote | undefined>(undefined)
  const [symbol, setSymbol] = useState('')
  const [isLoadingAccounts, setLoadingAccounts] = useState(!portfolios)
  const [syncedPortfoliosWithWatch, setSyncedPortfoliosWithWatch] = useState<
    MyPortfolioType[]
  >([])
  const [manualPortfolios, setManualPortfolios] = useState<Portfolio[]>([])
  const [watchingAccounts, setWatchingAccounts] = useState<string[]>([])
  const [isNewWatch, setIsNewWatch] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (watch && watch.symbol) {
      setSymbol(watch.symbol)
    }
    if (watch && watch.portfolios.length === 0) {
      setIsNewWatch(true)
    }
  }, [watch])

  const fetchQuoteData = useCallback(async () => {
    if (watch && watch.instrumentId) {
      const quote = await getQuoteByInstrumentId(watch?.instrumentId)
      setQuote(quote)
      setSymbol(quote?.symbol || '')
    }
  }, [watch])

  useEffect(() => {
    fetchQuoteData()
  }, [fetchQuoteData])

  useEffect(() => {
    // If portfolios are not loaded into redux, load them.
    if (!portfolios) {
      dispatch(getMyStocksPortfolios({}))
      // TODO: Target the loading state in the store instead
      setLoadingAccounts(false)
    }
  }, [])

  useEffect(() => {
    // Determine the manual/synced portfolios and which have watch in them already.
    if (portfolios && watch) {
      const accountsWithWatch = watch.portfolios.map((p) => p?.uuid)
      const plaidPortfoliosWithWatch = watch.portfolios.filter((p) => {
        return p && p.type === 'plaid'
      })
      const manualPortfolios = portfolios.filter((p) => {
        return p && p.type === 'manual'
      })
      const manualPortfoliosWithWatch = manualPortfolios.filter((p) => {
        return p && p.uuid && accountsWithWatch.includes(p.uuid)
      })
      setSyncedPortfoliosWithWatch(plaidPortfoliosWithWatch)
      setManualPortfolios(manualPortfolios)
      setWatchingAccounts(manualPortfoliosWithWatch.map((p) => p?.uuid || ''))
    }
  }, [portfolios, watch])

  const handlePorfolioCheckboxClick = (uuid: string) => () => {
    if (watchingAccounts.includes(uuid)) {
      setWatchingAccounts(watchingAccounts.filter((id) => id !== uuid))
    } else {
      setWatchingAccounts([...watchingAccounts, uuid])
    }
  }

  const cancelUnfollowHandler = () => {
    setShowDeleteConfirmation(false)
  }

  const updateWatchHandler = async () => {
    if (isNewWatch && watchingAccounts.length === 0) {
      closeFollowModal()
      return
    }
    if (
      watchingAccounts.length === 0 &&
      syncedPortfoliosWithWatch.length === 0
    ) {
      setShowDeleteConfirmation(true)
      return
    }
    await updatePortfolioWatches()
  }

  const confirmDeleteHandler = async () => {
    await updatePortfolioWatches()
  }

  const updatePortfolioWatches = async () => {
    if (watch) {
      const portfolioUpdates = manualPortfolios
        .map((p) => {
          const accountUuid = p?.uuid
          if (!accountUuid) return null

          const isWatching = watchingAccounts.includes(accountUuid)
          const wasWatching = watch.portfolios
            .map((p) => p?.uuid)
            .includes(accountUuid)

          // Only include portfolios that have changed
          if (isWatching === wasWatching) return null

          return {
            instrumentId: watch.instrumentId,
            isAdd: isWatching,
            portfolioUuid: accountUuid,
          }
        })
        .filter(
          (update): update is NonNullable<typeof update> => update !== null,
        )
      if (portfolioUpdates.length > 0) {
        setIsSaving(true)
        // Single dispatch with all updates
        await dispatch(toggleWatches(portfolioUpdates))

        const changedPortfolioUuids = portfolioUpdates.map(
          (u) => u.portfolioUuid,
        )
        dispatch(invalidateBothHoldings(changedPortfolioUuids))

        // Track events for each change
        portfolioUpdates.forEach((update) => {
          const portfolio = manualPortfolios.find(
            (p) => p?.uuid === update.portfolioUuid,
          )
          trackEvent(
            update.isAdd ? 'Portfolio_Add' : 'Portfolio_Remove',
            portfolio?.name || '',
            symbol || watch?.symbol,
          )
        })

        setTimeout(async () => {
          await dispatch(fetchAndSetWatches())
          setIsSaving(false)
          closeFollowModal()
        }, 2000)
      } else {
        closeFollowModal()
      }
    }
  }

  const closeFollowModal = () => {
    dispatch(
      setFollowModal({
        showModal: false,
        modalWatch: undefined,
      }),
    )
  }

  return (
    <Dialog
      open={true}
      as="div"
      className="relative z-[60] overflow-hidden"
      onClose={closeFollowModal}
    >
      <div className="fixed inset-0 bg-content-100/[.4] transition-opacity" />
      <div className="fixed inset-0 z-60 w-screen overflow-y-auto">
        <div className="flex flex-start items-center justify-center self-stretch sm:gap-8px lg:gap-32px min-h-full max-w-full  sm:mx-16px md:mx-auto md:max-w-xl p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative transform rounded-lg p-8px bg-primary-0 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 min-h-[200px]">
            {isLoadingAccounts && (
              <>
                <h3 className="mb-16px">Loading your Portfolios...</h3>
                <Button variant="secondary" onClick={closeFollowModal}>
                  Cancel
                </Button>
              </>
            )}
            {!isLoadingAccounts && !showDeleteConfirmation && symbol && (
              <>
                <div className="flex justify-between">
                  <Heading
                    as="h3"
                    looksLike="h3"
                    className="text-content-80 pt-8px"
                  >
                    {isNewWatch && <span>Follow {symbol}</span>}
                    {!isNewWatch && <span>Update {symbol} Follow Status</span>}
                  </Heading>
                  {quote &&
                    quote?.currentPrice !== undefined &&
                    quote?.percentChange !== undefined && (
                      <PriceAndChangeChip
                        currencyCode={quote?.currencyCode}
                        price={quote?.currentPrice}
                        change={quote?.percentChange * 100}
                      />
                    )}
                </div>
                <p className="my-12px">
                  Make selections to add or remove {symbol} from portfolios.
                  Deselect from all portfolios to remove Follow status.
                </p>
                <ul className="mb-16px">
                  {manualPortfolios.map((p) => (
                    <li
                      key={p?.uuid}
                      className="flex items-center gap-4px mb-4px"
                    >
                      <Checkbox
                        id={`cb-${p?.uuid}`}
                        checked={watchingAccounts.includes(p?.uuid || '')}
                        onChange={handlePorfolioCheckboxClick(p?.uuid || '')}
                      />
                      <label className="pl-8px" htmlFor={`cb-${p?.uuid}`}>
                        {p?.name}
                      </label>
                    </li>
                  ))}
                </ul>
                {syncedPortfoliosWithWatch.length > 0 && (
                  <>
                    <div className="border-1px bg-primary-8 border-primary-8 rounded-[8px] p-16px">
                      You follow {symbol} because it is owned in:
                      {syncedPortfoliosWithWatch.map((p) => (
                        <Link
                          key={p?.uuid}
                          href={`/my-stocks/?uuid=${p?.uuid}`}
                        >
                          <span className="text-primary-100 font-bold mx-8px">
                            {p?.name}
                          </span>
                        </Link>
                      ))}
                      *
                    </div>
                    <div className="text-content-60 text-sm mt-16px mb-24px">
                      * Removing {symbol} from a list will not remove it from
                      your portfolio.
                    </div>
                  </>
                )}
                <div className="flex justify-end gap-8">
                  <Button variant="secondary" onClick={closeFollowModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={updateWatchHandler}
                    disabled={isSaving}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {!isLoadingAccounts && showDeleteConfirmation && (
              <>
                <Heading
                  as="h3"
                  looksLike="h3"
                  className="text-content-80 mb-16px"
                >
                  Are you sure you want to unfollow {symbol}?
                </Heading>
                <p className="mb-16px">
                  By unfollowing (removing from all manual portfolios) all
                  transactions associated with {symbol} will also be deleted.
                </p>
                <div className="flex justify-end gap-8">
                  <Button variant="secondary" onClick={cancelUnfollowHandler}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={confirmDeleteHandler}
                    disabled={isSaving}
                  >
                    Yes, Unfollow
                  </Button>
                </div>
              </>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}

export default WatchModal
