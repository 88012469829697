'use client'
import {useApolloClient} from '@apollo/client'
import {Provider} from 'react-redux'
import {createStore} from '~data/client/store'
import {useEffect, useMemo} from 'react'
import {usePathname, useSearchParams} from 'next/navigation'
import {setRoute} from '~data/client/route.slice'
import type {Session} from 'next-auth/types'
import {UserQuery} from '~types/__generated__/graphql'
import {setUserFromGQL} from '~data/client/user.slice'

export const ReduxProvider = ({
  session,
  userData,
  children,
}: {
  session?: Session | null
  userData?: UserQuery['user']
  children: React.ReactNode
}) => {
  const apolloClient = useApolloClient()
  const store = useMemo(
    () => createStore(apolloClient, session),
    [apolloClient, session],
  )

  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (userData) {
      store.dispatch(setUserFromGQL(userData))
    }
  }, [store])

  useEffect(() => {
    const url = `${pathname}${
      searchParams.toString() ? `?${searchParams.toString()}` : ''
    }`
    store.dispatch(setRoute(url))

    // Cleanup for listeners
    return () => {
      // Todo: Add cleanup logic here
    }
  }, [pathname, searchParams, store])
  return <Provider store={store}>{children}</Provider>
}
