'use client'
import Script from 'next/script'
import {useEffect, useRef} from 'react'
import {usePathname} from 'next/navigation'

declare global {
  interface Window {
    Infotrack: {
      page: () => void
      track: (
        eventName: string,
        eventProperties?: object,
        eventOptions?: object,
        eventCallback?: () => void,
      ) => void
    }
  }
}

export type InfotrackConfig = {
  projectName: string
  segmentKey?: string
}

// Loads OneTrust optAnonWrapper to load Infotrack/Segment
const loadInfotrackWrapper = (projectName: string, segmentKey: string) => {
  if (!window.Infotrack) {
    const wrapperScript = document.createElement('script')
    wrapperScript.innerHTML = `function OptanonWrapper(){var t;window.infotrackInitialized||void 0===window.Infotrack?window.Infotrack.setSegmentIntegrations():(window.infotrackInitialized=!0,Infotrack.load("https://g.foolcdn.com/mms/resources/js/infotrack-ot_min.js"),t=t||0,setTimeout(function(){window.Infotrack.initialize('${projectName}')},t))}window.top!=window&&(window.analytics=window.top.analytics,window.inIframe=!0);var segmentKey="${segmentKey}",segmentSnippetVersion="4.15.2",getSegmentUrl=function(t){return t=t||window.segmentKey,("https:"===document.location.protocol?"https://":"http://")+"evs.pink-boat.fool.com/analytics.js/v1/"+t+"/analytics.min.js"},trackerMaker=function(t){function n(n){function t(){var t=Array.prototype.slice.call(arguments,0),t=[n].concat(t);e.push(t)}return t.stub=!0,t}for(var e=[],o=(e.invoked=!1,e.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"],t&&(e.methods=e.methods.concat(t)),0);o<e.methods.length;o++){var i=e.methods[o];e[i]=n(i)}return e.load=function(t){var n;e.invoked?window.console&&console.error&&console.error("Tracking snippet included twice."):((n=document.createElement("script")).type="text/javascript",n.async=!0,n.src=t,(t=document.getElementsByTagName("script")[0]).parentNode.insertBefore(n,t),e.invoked=!0)},e},analytics=window.analytics=window.analytics||trackerMaker(["addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"]),Infotrack=window.Infotrack=window.Infotrack||trackerMaker(["initialize","setSegmentIntegrations"]);`
    document.head.appendChild(wrapperScript)
  }
}

const trackEvent = (
  action: string,
  category: string,
  label: string,
  additionalProperties: object = {page_type: 'premium'},
) => {
  if (window.Infotrack) {
    window.Infotrack.track(action, {
      category: category,
      label: label,
      ...additionalProperties,
    })
  }
}

function Infotrack({projectName, segmentKey = 'smro6jytgs'}: InfotrackConfig) {
  const otAutoBlockScript =
    'https://cdn.cookielaw.org/consent/02abb198-81a8-49e5-a9b1-f69a5dd9c039/OtAutoBlock.js'
  const otSDKStubScript =
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  const pathName = usePathname()
  const hasInfotrackBeenLoaded = useRef(false)

  // used to track page views on pathName change
  useEffect(() => {
    if (hasInfotrackBeenLoaded.current && window.Infotrack) {
      window.Infotrack.page()
    }
    hasInfotrackBeenLoaded.current = true
  }, [pathName])

  return (
    <>
      {/* Loads OneTrust AutoBlock Script */}
      <Script src={otAutoBlockScript} />

      {/* OneTrust Banner Script */}
      <Script
        src={otSDKStubScript}
        data-domain-script="02abb198-81a8-49e5-a9b1-f69a5dd9c039"
        onLoad={() => {
          loadInfotrackWrapper(projectName, segmentKey)
        }}
      />
    </>
  )
}
export {Infotrack, trackEvent}
