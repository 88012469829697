/**
 * Page Layout Data provides minor page information to the main
 * layout in the server based on the pathname.
 */
import pageData from './pageData'
import type {PageDataObject, PageLayoutData} from '~data/types/page'
import {match} from 'path-to-regexp'

const defaultLayoutData: PageLayoutData = {
  headerTitle: null,
  hasServiceMenu: false,
  showTickerTape: false,
  enableRightBar: true,
}

export function getPageConfig(pageConfigs: PageDataObject, path: string) {
  for (const [routePattern, config] of Object.entries(pageConfigs)) {
    const matchFn = match(routePattern, {decode: decodeURIComponent})
    const result = matchFn(path)
    if (result) {
      return config
    }
  }
  return null
}

function getPageLayoutData(path: string | null) {
  if (!path) return defaultLayoutData
  const pageConfig = getPageConfig(pageData, path)
  return {
    ...defaultLayoutData,
    ...(pageConfig && pageConfig.layoutData),
  }
}

export default getPageLayoutData
