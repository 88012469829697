import {Transition} from '@headlessui/react'
import {ChevronRightIcon} from 'lucide-react'
import {useState, useEffect, useCallback} from 'react'
import {SecondaryNavItem, secondaryClasses, NavItem} from '../main-navigation'
import {StarButton} from '../product-prefs'
import {cn} from '~components/lib/utils'
import {useFloating, offset, arrow, autoUpdate} from '@floating-ui/react'
import {createPortal} from 'react-dom'
import Link from 'next/link'

export default function TertiaryNav({
  item,
  setTertiaryOpen,
  setSecondaryOpen,
  onNavigate,
}: {
  item: SecondaryNavItem
  setTertiaryOpen: (isOpen: boolean) => void
  setSecondaryOpen: () => void
  onNavigate: (href: string) => void
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [bodyElement, setBodyElement] = useState<HTMLElement | null>(null)

  useEffect(() => setTertiaryOpen(isOpen), [isOpen])
  useEffect(() => {
    setBodyElement(document.body)
  }, [])

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  const {x, y, strategy, middlewareData, refs} = useFloating({
    elements: {
      reference: referenceElement,
      floating: popperElement,
    },
    strategy: 'fixed',
    placement: 'right',
    middleware: [arrow({element: arrowElement}), offset(24)],
    whileElementsMounted: autoUpdate,
  })

  const styles = {
    popper: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0,
    },
    arrow: middlewareData.arrow
      ? {
          left:
            middlewareData.arrow.x != null ? `${middlewareData.arrow.x}px` : '',
          top:
            middlewareData.arrow.y != null ? `${middlewareData.arrow.y}px` : '',
        }
      : {},
  }

  const attributes = {popper: {}}

  // Sync refs
  useEffect(() => {
    if (referenceElement) {
      refs.setReference(referenceElement)
    }
  }, [referenceElement, refs])

  useEffect(() => {
    if (popperElement) {
      refs.setFloating(popperElement)
    }
  }, [popperElement, refs])

  const handleTertiaryClick = useCallback(
    (e: React.MouseEvent, href: string) => {
      e.preventDefault()
      setIsOpen(false)
      onNavigate(href)
    },
    [onNavigate],
  )

  const isHovering = useCallback(() => {
    const refHover = referenceElement?.matches(':hover')
    const popHover = popperElement?.matches(':hover')
    const refFocus = referenceElement?.contains(document.activeElement)
    const popFocus = popperElement?.contains(document.activeElement)

    return refHover || popHover || refFocus || popFocus
  }, [referenceElement, popperElement])

  return (
    <>
      <div
        key={`${item.name}-${item.starred}`}
        ref={setReferenceElement}
        className={cn(secondaryClasses, isOpen && 'bg-content-92')}
        onMouseEnter={() => {
          setIsOpen(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            const hovering = isHovering()

            if (!hovering) {
              setIsOpen(false)
              setSecondaryOpen()
            }
          }, 250)
        }}
      >
        <div className="flex items-center gap-x-8px">
          <StarButton
            item={item}
            onStarClick={() => {
              setIsOpen(false)
              setTertiaryOpen(false)
              setSecondaryOpen()
            }}
          />
          <span>{item.name}</span>
        </div>
        <ChevronRightIcon
          className={cn(
            'ml-auto shrink-0 size-5',
            isOpen ? 'text-primary-0' : 'text-content-50',
          )}
        />
      </div>
      {bodyElement &&
        createPortal(
          <Transition
            show={isOpen}
            as="div"
            data-tertiary-popper
            className="grid grid-cols-1 bg-content-0 text-text-100 z-50 rounded-[8px] py-8px scrollbar-nav overflow-y-auto"
            enter="transition-opacity ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onMouseLeave={() => {
              setTimeout(() => {
                if (!isHovering()) {
                  setIsOpen(false)
                  setSecondaryOpen()
                }
              }, 250)
            }}
          >
            <div>
              <div
                ref={setArrowElement}
                style={styles.arrow}
                className="-left-4px -z-10"
              >
                <span className="absolute size-8px transform rotate-45 bg-content-0 -mt-4px"></span>
              </div>
              {(item.children || []).map((secondary: NavItem) => {
                return secondary?.href ? (
                  <Link
                    href={secondary.href}
                    className="hover:bg-primary-8 px-16px py-8px transition ease-in-out duration-300 block text-black"
                    key={secondary.name}
                    onClick={(e) => handleTertiaryClick(e, secondary.href!)}
                  >
                    {secondary.name}
                  </Link>
                ) : null
              })}
            </div>
          </Transition>,
          bodyElement,
        )}
    </>
  )
}
