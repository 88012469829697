'use client'
import {
  Fragment,
  isValidElement,
  useEffect,
  useMemo,
  useState,
  useTransition,
  useCallback,
} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Dialog, Transition} from '@headlessui/react'
import type {RootState} from '~data/client/store'
import {getUserTier} from '~data/api/tiers.ts'
import {updateMainNavActive} from '~data/client/navigation.slice'
import type {Session} from 'next-auth'
import useScreenSize from 'src/app/(main)/_hooks/useScreenSize'
import {CloseIcon} from '@fool/jester-ui/Icon'
import {
  StockAdvisorJesterHat,
  EpicJesterHat,
  EpicPlusJesterHat,
  FoolPortfoliosJesterHat,
  FoolOneJesterHat,
} from '~components/jester-temp/icons.tsx'
import AdPlacement from '@fool/jester-ui/AdPlacement'
import {trackEvent} from 'src/app/(main)/tracking/infotrack'
import {Link} from '~components/common/link'
import {usePathname, useRouter} from 'next/navigation'
import classNames from 'classnames'
import AccessibilityIcon from '~components/nav-icons/accessibility'
import HelpIcon from '~components/nav-icons/help'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid'
import {toggleWidget} from 'src/app/(main)/a11y/userway'
import Divider from '@fool/jester-ui/Divider'
import Heading from '@fool/jester-ui/Heading'
import {getMappedNavProducts, StarButton} from './product-prefs'
import {
  checkActiveLink,
  getNavItems,
  MainNavItem,
  PrimaryNavLink,
  SecondaryNavItem,
  secondaryClasses,
} from './main-navigation'
import {useQuery} from '@apollo/client'
import {HUB_ARTICLE_QUERY} from '~data/queries.js'
import {HubArticlesQuery, Product} from '~types/__generated__/graphql.ts'
import {selectSubnavPortfolios} from '~app/(main)/my-stocks/utils/selectors'
import {AppDispatch} from '~data/client/store.ts'
import {Portfolio} from '~types/my-stocks.ts'
import {useRouteToPath} from 'src/app/(main)/my-stocks/helpers/hooks'
import {selectPortfolioStatus} from '~app/(main)/my-stocks/utils/selectors'
import {MY_STOCKS_NAVITEM_ID, STATUS_STATES} from '~data/constants'
import {getMyStocksPortfolios} from '~app/(main)/my-stocks/utils/thunks'

interface MobileNavProps {
  session?: Session | null
}

const navFooterItems = [
  {
    name: 'Help',
    href: 'https://support.fool.com/',
    newTab: true,
    icon: <HelpIcon aria-hidden="true" />,
  },
  {
    name: 'Accessibility',
    href: '#accessibility',
    icon: <AccessibilityIcon aria-hidden="true" />,
    onClick: toggleWidget,
  },
]

function MobileNav({session}: MobileNavProps): JSX.Element {
  const dispatch = useDispatch<AppDispatch>()
  const routeToPath = useRouteToPath()
  const userTier = session ? getUserTier({session}) : null
  const userAccessibleProductIds: number[] = useSelector(
    (state: RootState) => state.user.accessibleProductIds,
  )
  const productPortfolios = useSelector(
    (state: RootState) => state.user.nestedProducts,
  )
  const starredProducts = useSelector(
    (state: RootState) => state.navigation.starredProducts,
  )
  const myStockPortfolioLoadingStatus = useSelector(selectPortfolioStatus)
  const myStockPortfolioItems = useSelector(selectSubnavPortfolios)
  const [productsWithPrefs, setProductsWithPrefs] = useState<
    SecondaryNavItem[] | null
  >(null)
  const [isPending, startTransition] = useTransition()
  const router = useRouter()
  const pathname = usePathname()

  const handleNavigation = useCallback(
    (href: string) => {
      startTransition(() => {
        router.push(href)
      })
    },
    [router],
  )

  useEffect(() => {
    if (session?.accessToken) {
      getMappedNavProducts(
        productPortfolios,
        session?.accessToken,
        starredProducts,
      ).then((data) => setProductsWithPrefs(data as SecondaryNavItem[] | null))
    }
  }, [session, productPortfolios, starredProducts])

  useEffect(() => {
    dispatch(updateMainNavActive({mainNavActive: false}))
  }, [pathname, dispatch])

  const navItems: MainNavItem[] = useMemo(() => {
    const myStockPortfolios = myStockPortfolioItems?.map((portfolio) => {
      return {
        name: portfolio?.name,
        uuid: portfolio?.uuid,
        type: portfolio?.type,
      }
    })
    return getNavItems(
      productsWithPrefs,
      userTier,
      userAccessibleProductIds as number[] | [],
      productPortfolios as Product[] | [],
      myStockPortfolios as Portfolio[] | [],
      myStockPortfolioLoadingStatus as string,
      dispatch,
      routeToPath,
      pathname,
    )
  }, [
    productsWithPrefs,
    userTier,
    userAccessibleProductIds,
    myStockPortfolioItems,
    myStockPortfolioLoadingStatus,
    pathname,
  ])

  const currentScreen = useScreenSize()
  const screenIsMobile =
    currentScreen && ['xs', 'sm', 'md', 'lg'].includes(currentScreen)
  const dispatchSetNavBar = (val: boolean | undefined) => {
    if (!screenIsMobile) return
    dispatch(updateMainNavActive({mainNavActive: val || !navOpen}))
  }
  const navOpen = useSelector(
    (state: RootState) => state.navigation.mainNavActive,
  )
  const {data: hubArticlesData} = userTier
    ? useQuery<HubArticlesQuery>(HUB_ARTICLE_QUERY, {
        variables: {
          collectionName: 'Premium Hub Articles',
        },
      })
    : {data: null}

  const handleNavItemClick = (item: MainNavItem) => {
    trackEvent('Premium_click', 'Mobile Navigation', item.name)

    if (!item.children) dispatchSetNavBar(false)

    // Check if the user hovers over the My Stocks nav item
    if (item.id && item.id === MY_STOCKS_NAVITEM_ID) {
      // Check if portfolios are available at all or in the process of being
      // retrieved. If not, initiate call to get portfolios.
      if (myStockPortfolioLoadingStatus === STATUS_STATES.INITIAL) {
        dispatch(getMyStocksPortfolios({}))
      }
    }
  }

  return (
    <>
      <Transition.Root show={navOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden mobile-nav"
          onClose={dispatchSetNavBar}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div />
          </Transition.Child>
          <div className="fixed inset-0 flex w-80">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 h-full w-screen">
                    <button
                      type="button"
                      className="h-full w-full bg-black/65"
                      onClick={() => dispatchSetNavBar(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                {/* Animated Nav Bar for mobile */}
                <div className="flex grow flex-col gap-y-1 py-24px overflow-y-auto bg-content-92 w-full">
                  <div className="flex justify-between mb-24px h-[45px] px-3">
                    <div className="flex gap-2">
                      <div>
                        {userTier?.tier === 1 && <StockAdvisorJesterHat />}
                        {userTier?.tier === 2 && <EpicJesterHat />}
                        {userTier?.tier === 3 && <EpicPlusJesterHat />}
                        {userTier?.tier === 4 && <FoolPortfoliosJesterHat />}
                        {userTier?.tier === 5 && <FoolOneJesterHat />}
                      </div>
                      <div className="flex flex-col">
                        <Link href="/">
                          <Heading
                            as="h4"
                            looksLike="h4"
                            className="text-content-4 font-black"
                          >
                            {userTier?.name || 'Fool'}
                          </Heading>
                        </Link>

                        {userTier?.name &&
                          hubArticlesData?.curatedArticles?.length &&
                          hubArticlesData?.curatedArticles[0].link && (
                            <Link
                              href={hubArticlesData?.curatedArticles[0].link}
                            >
                              <p className="text-content-4 text-caption-regular font-bold">
                                About {userTier.name}
                              </p>
                            </Link>
                          )}
                      </div>
                    </div>

                    <button
                      onClick={() => dispatchSetNavBar(false)}
                      className="flex items-center justify-center bg-primary-8 text-primary-100 hover:bg-primary-16  border-1px size-40px border-text-100 rounded-full"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <Divider
                    noBaseStyles
                    className="bg-primary-0 h-[1px] min-h-[1px]"
                  />
                  <div>
                    <AdPlacement
                      pitchPlacement={'premium_nav_cta'}
                      pitchClassName={
                        'pt-8px px-16px text-primary-0 text-center text-caption-regular'
                      }
                      authToken={session?.accessToken}
                    >
                      {/* <!--
                          ***
                          premium_nav_cta
                          ***
                        -->
                        <!-- variable href -->
                        <a id="premium_nav_cta" href="" class="w-full">
                          <button
                            type="button"
                            class="flex gap-8px justify-center w-full bg-primary-100 hover:bg-primary-120 focus:bg-primary-120 text-content-4 rounded-lg py-8px px-4px text-[14px]"
                          >
                            <!-- variable CTA copy -->
                            Your Upgrade Credits: $0
                          </button>
                        </a> */}
                    </AdPlacement>
                  </div>
                  <nav className="flex flex-col justify-between flex-grow flex-shrink basis-auto scrollbar-nav overflow-y-auto bottom-0px">
                    <ul className="w-full" role="list">
                      {navItems.map((item: MainNavItem) => (
                        <li
                          key={item.name}
                          onClick={() => handleNavItemClick(item)}
                        >
                          {/* Nav Item */}
                          {!item.children && item.href ? (
                            <PrimaryNavLink
                              key={item.name}
                              item={{...item, href: item.href}}
                              pathname={pathname}
                              isPending={isPending}
                              onNavigate={handleNavigation}
                            />
                          ) : item.href === '/my-services' &&
                            productPortfolios.length === 1 ? (
                            <PrimaryNavLink
                              item={{
                                ...item,
                                href: `/my-services/${productPortfolios[0].slug}`,
                              }}
                              pathname={pathname}
                              isPending={isPending}
                              onNavigate={handleNavigation}
                            />
                          ) : userTier ? (
                            // SA Latest Rec / User Tier Case
                            // If user has no tier, we don't render the secondary nav.
                            // We are rending a modified version desktop currently.
                            // That behavoir may be worth replicating on mobile in the future.
                            <SecondaryNav item={item} key={item.name} />
                          ) : (
                            <></>
                          )}
                        </li>
                      ))}
                    </ul>

                    {/* Nav Footer Items */}
                    <ul className="flex flex-col bottom-0px w-full">
                      {navFooterItems.map((item) => (
                        <li key={item.name}>
                          <PrimaryNavLink
                            item={item}
                            pathname={pathname}
                            key={item.name}
                            isPending={isPending}
                            onNavigate={handleNavigation}
                          />
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

const SecondaryNav = ({item}: {item: MainNavItem}) => {
  const [isOpen, setIsOpen] = useState(false)
  const pathname = usePathname()
  const dispatch = useDispatch()
  const dispatchSetNavBar = (val: boolean) => {
    dispatch(updateMainNavActive({mainNavActive: val}))
  }

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  return (
    <>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          checkActiveLink(item, pathname)
            ? 'bg-content-100 bg-opacity-[.4] font-black hover:bg-secondary-nav'
            : 'group-hover:bg-secondary-nav hover:bg-secondary-nav hover:font-black font-regular',
          'group flex text-primary-0 px-24px py-4 py-16px transition ease-in-out duration-300 transform cursor-pointer',
        )}
      >
        <figure
          className={classNames(
            checkActiveLink(item, pathname)
              ? 'text-primary-0'
              : 'text-content-70',
            'size-24px group-hover:text-primary-0 transition ease-in-out duration-300 transform',
          )}
        >
          {item.icon}
        </figure>
        <span className="ml-8px">{item.name}</span>
        <ChevronRightIcon
          width={24}
          className={classNames(
            'ml-auto',
            checkActiveLink(item, pathname)
              ? 'text-primary-0'
              : 'text-content-50 group-hover:text-primary-0 transition-all duration-300',
          )}
        />
      </div>
      <Transition.Root show={isOpen} as={Fragment}>
        <div className="fixed inset-0 z-50 h-screen w-80">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative w-full bg-secondary-nav opacity-100 z-50 flex flex-col scrollbar-nav overflow-y-auto h-screen p-16px text-primary-0">
              <div className="flex justify-between items-center">
                <div className="flex" onClick={() => setIsOpen(false)}>
                  <ChevronLeftIcon width={24} />
                  <Heading as="h3" looksLike="h3" className="pl-2">
                    {item.name}
                  </Heading>
                </div>
                <button
                  onClick={() => dispatchSetNavBar(false)}
                  className="flex items-center justify-center bg-primary-8 text-primary-100 hover:bg-primary-16  border-1px size-40px border-text-100 rounded-full my-[10px]"
                >
                  <CloseIcon size="lg" />
                </button>
              </div>
              <Divider
                noBaseStyles
                className="bg-primary-0 h-[1px] min-h-[1px] my-8px -mx-16px"
              />
              {(item.children || []).map((secondary) => {
                if (isValidElement(secondary)) {
                  return secondary
                }
                secondary = secondary as SecondaryNavItem
                if ((secondary as SecondaryNavItem)?.children) {
                  return <TertiaryNav item={secondary} key={secondary.name} />
                } else {
                  return secondary.href ? (
                    <span className={secondaryClasses} key={secondary.name}>
                      {typeof secondary.starred === 'boolean' && (
                        <StarButton item={secondary} />
                      )}
                      <Link href={secondary.href}>{secondary.name}</Link>
                    </span>
                  ) : secondary.onClick ? (
                    <a
                      onClick={secondary.onClick}
                      key={secondary.name}
                      className={`relative z-0 ${secondaryClasses}`}
                    >
                      <div className="flex items-center gap-x-8px">
                        {typeof secondary.starred === 'boolean' && (
                          <StarButton
                            item={secondary}
                            className="relative z-30"
                          />
                        )}
                        <span className="flex flex-nowrap items-center">
                          {secondary.name} {secondary.icon}
                        </span>
                      </div>
                    </a>
                  ) : null
                }
              })}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </>
  )
}

const TertiaryNav = ({item}: {item: SecondaryNavItem}) => {
  const [isOpen, setIsOpen] = useState(false)
  const pathname = usePathname()
  const dispatch = useDispatch()

  // Add effect to close tertiary nav when route changes
  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  const closeAllNavs = () => {
    setIsOpen(false)
    dispatch(updateMainNavActive({mainNavActive: false}))
  }

  return (
    <>
      <div
        className={classNames(secondaryClasses, 'group')}
        onClick={() => setIsOpen(true)}
      >
        <StarButton item={item} />
        {item.name}
        <ChevronRightIcon
          className="ml-auto text-content-50 group-hover:text-primary-0 transition-all duration-300 transform"
          width={24}
          height={24}
        />
      </div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[70] lg:hidden mobile-nav"
          onClose={() => closeAllNavs()}
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="fixed inset-0 z-[70] h-screen w-full">
              <div className="absolute w-full max-w-80 bg-secondary-nav opacity-100 z-[70] flex flex-col scrollbar-nav overflow-y-auto h-screen p-16px text-primary-0">
                <div className="flex justify-between items-center">
                  <div className="flex" onClick={() => setIsOpen(false)}>
                    <ChevronLeftIcon width={24} />
                    <Heading as="h3" looksLike="h3" className="pl-2">
                      {item.name}
                    </Heading>
                  </div>
                  <button
                    onClick={() => closeAllNavs()}
                    className="flex items-center justify-center bg-primary-8 text-primary-100 hover:bg-primary-16 border-1px size-40px border-text-100 rounded-full my-[10px]"
                  >
                    <CloseIcon size="lg" />
                  </button>
                </div>
                <Divider
                  noBaseStyles
                  className="bg-primary-0 h-[1px] min-h-[1px] my-8px -mx-16px"
                />
                {(item.children || []).map((tertiary) => {
                  if (isValidElement(tertiary)) {
                    return tertiary
                  }
                  tertiary = tertiary as SecondaryNavItem
                  if ((tertiary as SecondaryNavItem)?.children) {
                    return (
                      <div className={secondaryClasses} key={tertiary.name}>
                        {tertiary.name}
                      </div>
                    )
                  } else {
                    return tertiary?.href ? (
                      <Link
                        key={tertiary.href}
                        href={tertiary.href}
                        className={secondaryClasses}
                        onClick={() => {
                          setIsOpen(false)
                        }}
                      >
                        {tertiary.name}
                      </Link>
                    ) : null
                  }
                })}
              </div>
              <button
                type="button"
                className="absolute top-0 left-0 h-full w-full z-10"
                onClick={() => closeAllNavs()}
              >
                <span className="sr-only">Close sidebar</span>
              </button>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default MobileNav
