import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export type ButtonState = {
  showSlideIn: boolean
}

const initialState: ButtonState = {
  showSlideIn: false,
}

const buttonSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    buttonState: (state, action: PayloadAction<boolean>) => {
      state.showSlideIn = action.payload
    },
  },
})

export const {buttonState} = buttonSlice.actions
export default buttonSlice.reducer
