import {useSession} from 'next-auth/react'
import {Link} from '~components/common/link'
import {getUserTier} from '~data/api/tiers'

export default function RankingsDisclaimer() {
  const session = useSession()
  const userTier = getUserTier({session: session.data})
  return (
    <p className="text-caption-regular text-content-70 py-2">
      Rankings allows you to explore the conviction a service&#39;s investing
      team has in a company&#39;s ability to deliver a target return.{' '}
      {userTier.hasMultipleScorecards
        ? `Select a service to see how the team has ranked the stocks
    based on the potential to reach the portfolio's goal.`
        : ''}{' '}
      Please see our Tools{' '}
      <Link
        href="https://www.fool.com/legal/terms-and-conditions/motley-fool-tools-terms-and-conditions-service/"
        className="text-primary-100"
      >
        Terms of Service.
      </Link>
    </p>
  )
}
