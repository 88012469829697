'use client'
import {useSelector, useDispatch} from 'react-redux'
import type {RootState} from '~data/client/store'
import {updateMainNavActive} from '~data/client/navigation.slice'
import {HamburgerIcon} from '@fool/jester-ui/Icon'
import IconButton from '@fool/jester-ui/IconButton'

function MainNavToggle() {
  const dispatch = useDispatch()
  const navOpen = useSelector(
    (state: RootState) => state.navigation.mainNavActive,
  )
  const dispatchSetNavBar = (val: boolean | undefined) =>
    dispatch(updateMainNavActive({mainNavActive: val || !navOpen}))

  return (
    <IconButton
      onClick={() => dispatchSetNavBar(true)}
      variant="secondary"
      style={{border: 'none'}}
      aria-label={navOpen ? 'close navigation menu' : 'open navigation menu'}
    >
      <HamburgerIcon />
    </IconButton>
  )
}

export default MainNavToggle
