'use client'

import {useSelector} from 'react-redux'
import {useMemo} from 'react'
import Heading from '@fool/jester-ui/Heading'
import VaultIcon from '~components/nav-icons/vault'
import {RootState} from '~data/client/store.ts'
import {Product} from '~types/__generated__/graphql'
import {usePathname} from 'next/navigation'
import {getPortfolio, getVaultedPortfolioSlug} from '~hooks/utils'

const usePortfolioName = () => {
  const products = useSelector(
    (state: RootState) => state.user.nestedProducts,
  ) as Product[]
  const pathname = usePathname()

  const portfolioSlug = useMemo(() => {
    const segments = pathname.split('/').filter(Boolean)
    const baseIndex = segments.indexOf('vault')
    const value =
      baseIndex !== -1 && segments.length > baseIndex + 2
        ? segments[baseIndex + 1]
        : segments[segments.length - 1]

    return getVaultedPortfolioSlug(value)
  }, [pathname])

  const portfolioName = useMemo(() => {
    const product = products.find((product: Product) =>
      getPortfolio(product, portfolioSlug),
    )
    const portfolio = getPortfolio(product!, portfolioSlug)

    return portfolio?.displayName || ''
  }, [products, portfolioSlug])

  return portfolioName
}

export default function VaultHeader() {
  const portfolioName = usePortfolioName()

  return (
    <div className="flex flex-col">
      <div className="flex flex-nowrap gap-8px items-center">
        <span className="text-content-70">
          <VaultIcon size="36" />
        </span>
        <Heading as="h1" looksLike="h2">
          {portfolioName}
        </Heading>
      </div>
    </div>
  )
}
