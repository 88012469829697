import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '~data/client/store'

export type NavigationState = {
  activeRouteId: string
  mainNavActive: boolean
  rightBarActive: boolean
  starredProducts: string[]
}

const initialState: NavigationState = {
  activeRouteId: 'home',
  mainNavActive: false,
  rightBarActive: false,
  starredProducts: [],
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    updateMainNavActive: (
      state,
      action: PayloadAction<{mainNavActive: boolean}>,
    ) => {
      state.mainNavActive = action.payload.mainNavActive
    },
    updateRightBarActive: (
      state,
      action: PayloadAction<{rightBarActive: boolean}>,
    ) => {
      state.rightBarActive = action.payload.rightBarActive
    },
    updateStarredProducts: (
      state,
      action: PayloadAction<{starredProducts: string[]}>,
    ) => {
      state.starredProducts = action.payload.starredProducts
    },
  },
})

export const {
  updateMainNavActive,
  updateRightBarActive,
  updateStarredProducts,
} = navigationSlice.actions

export const getActiveRouteId = (state: RootState) =>
  state.navigation.activeRouteId

export default navigationSlice.reducer
