import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {TOAST_NOTIFICATION_DURATION} from '~data/constants'

type NotificationType = 'success' | 'error' | 'default'

export type Notification = {
  type: NotificationType
  title?: string
  message: string
  expiration: number
}

export type NotificationState = {
  notifications: Notification[]
}

const initialState: NotificationState = {
  notifications: [],
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<{
        notification: {
          title?: string
          message: string
          type: NotificationType
        }
      }>,
    ) => {
      state.notifications = [
        ...state.notifications,
        {
          ...action.payload.notification,
          expiration: new Date().getTime() + TOAST_NOTIFICATION_DURATION,
        },
      ]
    },
  },
})

export const {addNotification} = notificationSlice.actions
export default notificationSlice.reducer
