'use client'
import {useEffect, useState} from 'react'
import {usePathname} from 'next/navigation'
import getPageLayoutData from '~data/getPageLayoutData'
import {PageLayoutData} from '~data/types/page'
import type {Quote} from '~types/my-stocks'
import StaticTickerTape from '~components/common/static-ticker-tape'

const tickers: Quote[] = [
  {name: 'S&P 500', instrumentId: 220472},
  {name: 'Nasdaq', instrumentId: 220473},
  {name: 'Bitcoin', instrumentId: 343539},
]

function HeaderTicker() {
  const pathname = usePathname()
  const [pageInfo, setPageInfo] = useState<PageLayoutData>({})

  useEffect(() => {
    setPageInfo(getPageLayoutData(pathname))
  }, [pathname])

  const defaultValues = {
    showTickerTape: false,
  }
  const {showTickerTape} = {
    ...defaultValues,
    ...pageInfo,
  }

  return <>{showTickerTape && <StaticTickerTape tickers={tickers} />}</>
}

export default HeaderTicker
