'use client'

import {useState, useEffect} from 'react'

const START_TIME = 8 // 8 AM
const END_TIME = 20 // 8 PM
const TIMEZONE = 'America/New_York'
const INTERVAL_MS = 60000 // 60000 ms = 1 minute

const checkIfLive = (): boolean => {
  const now = new Date()
  const etTime = new Date(now.toLocaleString('en-US', {timeZone: TIMEZONE}))
  const hours = etTime.getHours()
  return hours >= START_TIME && hours < END_TIME
}

export function useFool24LivestreamSchedule() {
  const [isLive, setIsLive] = useState(checkIfLive())

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLive(checkIfLive())
    }, INTERVAL_MS)

    return () => clearInterval(interval)
  }, [])

  return {isLive}
}
