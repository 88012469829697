'use client'
import {useCallback} from 'react'
import {Link} from '~components/common/link'
import {SecondaryNavItem, secondaryClasses} from '../main-navigation'
import {cn} from '~lib/utils'

export default function SecondaryNavLink({
  item,
  children,
  isPending,
  onNavigate,
  className,
}: {
  item: SecondaryNavItem
  children: React.ReactNode
  isPending: boolean
  onNavigate: (href: string) => void
  className?: string
}) {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (item.onClick) {
        item.onClick()
        return
      }

      if (item.newTab) {
        return
      }

      e.preventDefault()
      onNavigate(item.href!)
    },
    [item, onNavigate],
  )

  return (
    <Link
      href={item.href || ''}
      onClick={handleClick}
      forceContainerClasses
      className={cn(
        `relative z-0 ${secondaryClasses}`,
        isPending && 'opacity-70 cursor-wait',
        className,
      )}
    >
      {children}
    </Link>
  )
}
