'use client'

import {useEffect, useMemo, useState, useCallback, useTransition} from 'react'
import {Product} from '~types/__generated__/graphql'
import {Portfolio} from '~types/my-stocks'
import {MainNavItem, SecondaryNavItem, getNavItems} from './main-navigation'
import {useSelector, useDispatch} from 'react-redux'
import {
  selectSubnavPortfolios,
  selectPortfolioStatus,
} from '~app/(main)/my-stocks/utils/selectors'
import {getUserTier} from '~data/api/tiers.ts'
import {usePathname, useRouter} from 'next/navigation'
import {AppDispatch} from '~data/client/store.ts'
import {useRouteToPath} from 'src/app/(main)/my-stocks/helpers/hooks'
import type {RootState} from '~data/client/store'
import {getMappedNavProducts, getStarredProducts} from './product-prefs'
import {updateStarredProducts} from '~data/client/navigation.slice'

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarHeader,
  SidebarMenu,
  SidebarTrigger,
} from '~components/ui/sidebar'

import {Logo} from './components/logo'
import {PrimaryNavLink} from './components/primary-nav-link'
import SecondaryNav from './components/secondary-nav'
import {HelpIcon} from '@fool/jester-ui/Icon'
import AccessibilityIcon from '../../nav-icons/accessibility.tsx'
import {toggleWidget} from '../../../app/(main)/a11y/userway.tsx'
import type {Session} from 'next-auth'
import {MY_STOCKS_NAVITEM_ID, STATUS_STATES} from '~data/constants.ts'
import {getMyStocksPortfolios} from '~app/(main)/my-stocks/utils/thunks.ts'

export const checkActiveLink = (item: MainNavItem, pathname: string) => {
  if (item.name === 'Dashboard') {
    return item.href === pathname
  }
  return pathname.includes(item?.href || '')
}

interface NavProps {
  session?: Session | null
}

export default function AppSidebar({session}: NavProps) {
  const dispatch = useDispatch<AppDispatch>()
  const routeToPath = useRouteToPath()
  const pathname = usePathname()
  const router = useRouter()
  const myStockPortfolioLoadingStatus = useSelector(selectPortfolioStatus)
  const myStockPortfolioItems = useSelector(selectSubnavPortfolios)
  const productPortfolios = useSelector(
    (state: RootState) => state.user.nestedProducts,
  )
  const [productsWithPrefs, setProductsWithPrefs] = useState<
    SecondaryNavItem[] | null
  >(null)
  const userTier = session ? getUserTier({session}) : null
  const userAccessibleProductIds: number[] = useSelector(
    (state: RootState) => state.user.accessibleProductIds,
  )
  const [isPending, startTransition] = useTransition()

  const starredProducts = useSelector(
    (state: RootState) => state.navigation.starredProducts,
  )

  useEffect(() => {
    if (session?.accessToken) {
      getStarredProducts(session.accessToken)
        .then((starred: string[] | null) => {
          if (starred) {
            dispatch(updateStarredProducts({starredProducts: starred}))
          }
        })
        .catch((error: Error) => {
          console.error('Error fetching starred products:', error)
        })
    }
  }, [session?.accessToken, dispatch])

  useEffect(() => {
    if (session?.accessToken) {
      getMappedNavProducts(
        productPortfolios,
        session?.accessToken,
        starredProducts,
      ).then((data) => setProductsWithPrefs(data as SecondaryNavItem[] | null))
    }
  }, [session, productPortfolios, starredProducts])

  const navItems: MainNavItem[] = useMemo(() => {
    const myStockPortfolios = myStockPortfolioItems?.map((portfolio) => {
      return {
        name: portfolio?.name,
        uuid: portfolio?.uuid,
        type: portfolio?.type,
      }
    })
    return getNavItems(
      productsWithPrefs,
      userTier,
      userAccessibleProductIds as number[] | [],
      productPortfolios as Product[] | [],
      myStockPortfolios as Portfolio[] | [],
      myStockPortfolioLoadingStatus as string,
      dispatch,
      routeToPath,
      pathname,
    )
  }, [
    productsWithPrefs,
    userTier,
    userAccessibleProductIds,
    myStockPortfolioItems,
    myStockPortfolioLoadingStatus,
    pathname,
  ])

  const handleNavigation = useCallback(
    (href: string) => {
      startTransition(() => {
        router.push(href)
      })
    },
    [router],
  )

  const navFooterItems = [
    {
      name: 'Help',
      href: 'https://support.fool.com/',
      newTab: true,
      icon: <HelpIcon aria-hidden="true" />,
    },
    {
      name: 'Accessibility',
      href: '#accessibility',
      icon: <AccessibilityIcon aria-hidden="true" />,
      onClick: toggleWidget,
    },
  ]

  const checkNavOnHover = (navId: string | undefined) => {
    // Check if the user hovers over the My Stocks nav item
    if (navId === MY_STOCKS_NAVITEM_ID) {
      // Check if portfolios are available at all or in the process of being
      // retrieved. If not, initiate call to get portfolios.
      if (myStockPortfolioLoadingStatus === STATUS_STATES.INITIAL) {
        dispatch(getMyStocksPortfolios({}))
      }
    }
  }
  return (
    <Sidebar
      collapsible="icon"
      side="left"
      className={isPending ? 'opacity-90 pointer-events-none' : ''}
    >
      <SidebarHeader>
        <SidebarTrigger />
        <Logo userTier={userTier} />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {navItems.map((item) => (
              <SidebarMenuItem
                key={item.name}
                onMouseOver={() => checkNavOnHover(item.id)}
              >
                <SidebarMenuButton
                  tooltip={item.name}
                  asChild
                  className="p-3 h-auto"
                >
                  {(() => {
                    // Simple link case
                    if (!item.children && item.href) {
                      return (
                        <PrimaryNavLink
                          item={{...item, href: item.href}}
                          pathname={pathname}
                          isPending={isPending}
                          onNavigate={handleNavigation}
                        />
                      )
                    }

                    // 2. User with tier gets secondary nav
                    if (userTier) {
                      return (
                        <SecondaryNav
                          item={item}
                          isPending={isPending}
                          onNavigate={handleNavigation}
                        />
                      )
                    }
                  })()}
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>

        <SidebarGroup>
          <SidebarMenu>
            {navFooterItems.map((item) => (
              <SidebarMenuItem key={item.name}>
                <SidebarMenuButton tooltip={item.name} asChild>
                  <PrimaryNavLink
                    item={item}
                    pathname={pathname}
                    isPending={isPending}
                    onNavigate={handleNavigation}
                  />
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter />
    </Sidebar>
  )
}
