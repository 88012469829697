'use client'

import type {Product, ProductPortfolio} from '~types/__generated__/graphql'
import {JSX, lazy, Suspense, useTransition, useCallback} from 'react'
import {useRouter, usePathname} from 'next/navigation'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import type {DropdownItem} from '@fool/jester-ui/Select/Select.types'
import useScreenSize, {isMobileSize} from 'src/app/(main)/_hooks/useScreenSize'
import {pathToRegexp} from 'path-to-regexp'
import './service-menu.css'
const Select = lazy(() => import('@fool/jester-ui/Select'))
import {orderBy} from 'lodash'
import {externalSlug} from '~utils/product-slug-helpers'
import {useSession} from 'next-auth/react'
import {getUserTier} from '~data/api/tiers'
import Heading from '@fool/jester-ui/Heading'
import {useSelector} from 'react-redux'
import {RootState} from '~data/client/store'

type ServiceMenuProps = {pathRegex?: string} & JSX.IntrinsicElements['select']

type ServiceMenuDropdownItem = DropdownItem & {slug: string}
type SelectItem = ServiceMenuDropdownItem | DropdownItem | DropdownItem[]

const defaultPathRegex = '/my-services/:portfolioSlug(.*)'

function Loader({isMobile}: {isMobile: boolean}) {
  return (
    <Skeleton
      {...(!isMobile && {borderRadius: '1.5em'})}
      height={40}
      width={isMobile ? '100%' : 240}
    />
  )
}

export default function ServiceMenu({
  className,
  pathRegex = defaultPathRegex,
}: ServiceMenuProps) {
  const [isPending, startTransition] = useTransition()
  if (pathRegex && !pathRegex.includes(':portfolioSlug')) {
    throw new Error(
      'Invalid prop `pathRegex` supplied to `ServiceMenu`. Validation failed. `pathRegex` must include `:portfolioSlug`',
    )
  }

  const products = useSelector(
    (state: RootState) => state.user.nestedProducts,
  ) as Product[]

  const router = useRouter()
  const pathname = usePathname()

  const screenWidth = useScreenSize()
  const isMobile = isMobileSize(screenWidth)
  const session = useSession()
  const userTier = getUserTier({session: session.data})

  const onChange = useCallback(
    (selected: SelectItem) => {
      const pathParts = pathname.split('/')
      const currentPortfolioSlug = pathParts[2]
      const extraPathSegments = pathParts.slice(3)

      if ('slug' in selected && currentPortfolioSlug !== selected.slug) {
        const to = `/my-services/${selected.slug}${
          extraPathSegments ? `/${extraPathSegments.join('/')}` : ''
        }`

        startTransition(() => router.push(to))
      }
    },
    [pathname, router],
  )

  if (!products) {
    return <Loader isMobile={isMobile} />
  }

  const items = orderBy(
    products?.flatMap((product) => {
      if (product.slug === 'options') {
        return mapItem({displayName: product.shortName, slug: product.slug})
      }

      //Exclude Top Stocks, Vaulted portfolios and Archived portfolios from the dropdown menu in My Services
      return Object.values(
        // With the exception of Options, limit My Services items to
        // conventional "portfolio products."
        product.portfolios.reduce(
          (acc, item) => {
            if (
              item.displayName !== 'Top Stocks' &&
              !item.vaulted &&
              !item.archive &&
              !acc[item.displayName]
            ) {
              acc[item.displayName] = item
            }
            return acc
          },
          {} as Record<string, (typeof product.portfolios)[number]>,
        ),
      ).map(mapItem)
    }),
    ['label'],
    ['asc'],
  )

  const getDefaultSelected = (portfolioSlug: string) => {
    if (!items || items.length < 1) return
    let selected = selectBySlug(portfolioSlug)
    if (!selected) selected = items[0]
    if (pathname.includes(selected.slug)) {
      //This ensures users can click on All Services and Vault without being redirected to 10x automatically
      return selected
    }
  }

  const selectBySlug = (portfolioSlug: string) => {
    return items?.find(({slug}) => slug === portfolioSlug)
  }

  const pattern = pathToRegexp(pathRegex)
  const match = pattern.exec(pathname)
  if (items && items.length > 0 && match) {
    const [portfolioSlug] = match[1].split('/')
    const defaultSelected = getDefaultSelected(portfolioSlug)
    return (
      <>
        {items && userTier.hasMultipleScorecards! ? (
          <>
            <Suspense fallback={<Loader isMobile={isMobile} />}>
              <Select
                key={pathname}
                items={items}
                className={classNames('service-menu relative', className)}
                buttonClasses={classNames(
                  'w-full',
                  isPending && 'opacity-70 cursor-wait',
                )}
                dropdownClasses="z-50 w-full max-h-[420px] overflow-y-scroll min-w-[300px]"
                defaultSelected={defaultSelected?.key}
                onChange={onChange}
                searchFilter
              />
            </Suspense>
          </>
        ) : (
          portfolioSlug === defaultSelected?.slug && (
            <Suspense fallback={<Loader isMobile={isMobile} />}>
              <Heading as="h2" looksLike="h2" className="p-2 md:pl-3">
                {items[0]?.label}
              </Heading>
            </Suspense>
          )
        )}
      </>
    )
  }
  return null
}

function mapItem({
  displayName: label,
  slug,
}: Pick<ProductPortfolio, 'displayName' | 'slug'>): ServiceMenuDropdownItem {
  return {
    label,
    key: slug,
    slug: externalSlug(slug),
  }
}
