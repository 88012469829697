import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import type {QueryResult, Content, Recs, SelectedRec} from '~types/recs'

const transformData = (data: QueryResult | undefined) => {
  type RecsArray = Recs[] | undefined
  let recsArray: RecsArray = []

  if (data?.contents) {
    const flattenedRecs: Content[] = []
    data.contents.forEach((d) => {
      uniqBy(d.recommendations, 'instrument.symbol').forEach((rec) => {
        flattenedRecs.push({
          ...d,
          recommendations: [rec],
        })
      })
    })

    recsArray = flattenedRecs.reduce((acc: Recs[], content: Content) => {
      const recommendations = content.recommendations[0]
      const id = recommendations?.instrument?.instrumentId
      const instrumentId = recommendations?.instrument?.instrumentId
      const product = content.product
      const abbreviation =
        recommendations?.portfolio?.abbreviation || product?.abbreviation
      const publishAt = content.publishAt
      const recDate = recommendations?.actionDate
      const {action, instrument} = recommendations || {}
      const company = instrument?.name
      const symbol = instrument?.symbol
      const exchange = instrument?.exchange
      const productId = product.productId
      const portfolioSlug = ['stock-advisor', 'options'].includes(product.slug)
        ? product.slug
        : recommendations?.portfolio?.slug
      const url = content.url
      const path = content.path
      const author = {
        firstName: content?.authors?.[0]?.author.firstName,
        lastName: content?.authors?.[0]?.author.lastName,
        smallAvatarUrl: content?.authors?.[0]?.author.smallAvatarUrl,
      }
      const beta = instrument?.quote?.beta5y || null
      const service = recommendations?.portfolio?.displayName || ''
      const marketCap = instrument?.quote?.marketCap?.amount || ''
      const projectedReturns = [
        instrument?.predictionByInstrument?.returnEstimateLow,
        instrument?.predictionByInstrument?.returnEstimateHigh,
      ]
      const estimatedDrawdown =
        instrument?.predictionByInstrument?.maxDrawdownRiskPerYear
      const cmaTagLabel =
        recommendations?.instrument?.predictionByInstrument?.cmaTagLabel || null

      const recsItem = {
        id,
        company,
        symbol,
        action,
        type: '',
        service,
        productId,
        portfolioSlug,
        publishAt,
        instrumentId,
        recDate,
        author,
        url,
        path,
        exchange,
        beta,
        abbreviation,
        cmaTagLabel,
        marketCap,
        projectedReturns,
        estimatedDrawdown,
      }
      if (
        recsItem.company &&
        recsItem.instrumentId &&
        recsItem.exchange &&
        recsItem.symbol
      )
        acc.push(recsItem as Recs)
      return acc
    }, [] as Recs[])
  }
  return recsArray
}

const initialState: {
  recs: Recs[]
  instrumentId: number | null
  selectedRec: SelectedRec
} = {
  recs: [],
  instrumentId: null,
  selectedRec: null,
}

const recsSlice = createSlice({
  name: 'recs',
  initialState,
  reducers: {
    transform: (state, action: PayloadAction<QueryResult>) => {
      const newPayload = transformData(action.payload)
      if (!Array.isArray(newPayload)) {
        return state // or handle this case accordingly
      }
      state.recs = [...newPayload]
    },
    transformAndConcat: (state, action: PayloadAction<QueryResult>) => {
      const newPayload = transformData(action.payload)
      if (!Array.isArray(newPayload)) {
        return state // or handle this case accordingly
      }
      state.recs = [...state.recs, ...newPayload]
    },
    clearRecs: (state) => {
      state.recs = []
    },
    setInstrumentIdOnRec: (state, action: PayloadAction<number | null>) => {
      state.instrumentId = action.payload
    },
  },
})

export const {clearRecs, transform, transformAndConcat, setInstrumentIdOnRec} =
  recsSlice.actions

export default recsSlice.reducer
