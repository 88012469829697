import Link from 'next/link'
import {Ref, useCallback} from 'react'
import {cn} from '~components/lib/utils'
import Fool24OnAirIndicator from '../fool24-on-air-indicator'
import {checkActiveLink} from '../utils'
import {MainNavItem} from '../main-navigation'
import {useSidebar} from '~components/ui/sidebar'
import {ChevronRightIcon} from '@heroicons/react/20/solid'

interface MainNavItemWithHref extends MainNavItem {
  href?: string
}

interface PrimaryNavLinkProps {
  item: MainNavItemWithHref
  pathname: string
  isPending: boolean
  onNavigate: (href: string) => void
  collapsed?: boolean
  ref?: Ref<HTMLDivElement>
  onMouseOver?: () => void
  onMouseOut?: () => void
  isHovered?: boolean
}

export function PrimaryNavLink({
  item,
  pathname,
  isPending,
  onNavigate,
  collapsed,
  ref,
  onMouseOver,
  onMouseOut,
  isHovered,
}: PrimaryNavLinkProps) {
  const sideBarState = useSidebar()
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      // Prevent default navigation in all cases except newTab
      if (!item.newTab) {
        e.preventDefault()
      }

      // if item has children, don't do anything
      if (item.children) {
        return
      }

      if (item.onClick) {
        item.onClick()
        return
      }

      if (item.newTab) {
        return
      }

      onNavigate(item.href!)
    },
    [item, onNavigate],
  )

  return (
    <Link
      href={item.href ? item.href : ''}
      target={item.newTab ? '_blank' : '_self'}
      prefetch={!item.disablePrefetch}
      onClick={handleClick}
      className={cn(
        checkActiveLink(item, pathname)
          ? 'bg-content-100 bg-opacity-[.4] font-black hover:bg-secondary-nav'
          : 'hover:bg-secondary-nav hover:font-black font-regular',
        'group [&_figure]:hover:text-primary-0 inline-flex text-primary-0 px-24px py-4 transition-all self-stretch items-center',
        isPending && 'opacity-70 cursor-wait',
        isHovered && 'bg-secondary-nav !font-black [&_figure]:text-primary-0',
        sideBarState.open && 'gap-2',
      )}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      ref={ref as Ref<HTMLAnchorElement>}
    >
      <figure
        className={cn(
          checkActiveLink(item, pathname)
            ? 'text-primary-0'
            : 'text-content-70',
          'size-24px transition ease-in-out duration-300 transform',
          isPending && 'opacity-70',
        )}
      >
        {item.icon}
      </figure>
      {!collapsed && (
        <span
          className={cn(
            'flex transition-opacity duration-300 items-center whitespace-nowrap',
            sideBarState.open
              ? 'opacity-100'
              : 'opacity-0 w-0 overflow-hidden -translate-x-1/2',
          )}
        >
          {item.nameSkeleton ? item.nameSkeleton : item.name}
          {item.name === 'Fool24' && <Fool24OnAirIndicator />}
        </span>
      )}
      {item.children && sideBarState.open && (
        <ChevronRightIcon
          className={cn(
            'size-5 text-content-4 ml-auto',
            isHovered ? 'text-primary-0' : 'text-content-50',
          )}
        />
      )}
    </Link>
  )
}
