enum StockAdvisorSlug {
  Internal = 'stockadvisortominclusion',
  External = 'stock-advisor',
}

const PORTFOLIO_SLUG_MAPPINGS = [StockAdvisorSlug]

export function externalSlug(portfolioSlug: string) {
  let slug = portfolioSlug
  const mapping = PORTFOLIO_SLUG_MAPPINGS.find(
    (portfolio) => portfolio.Internal === slug,
  )
  if (mapping) slug = mapping.External
  return slug
}

export function internalSlug(portfolioSlug: string) {
  let slug = portfolioSlug
  const mapping = PORTFOLIO_SLUG_MAPPINGS.find(
    (portfolio) => portfolio.External === slug,
  )
  if (mapping) slug = mapping.Internal
  return slug
}
