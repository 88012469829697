'use client'
import {Fragment, useMemo, useEffect, useRef, useState} from 'react'
import {usePathname} from 'next/navigation'
import classNames from 'classnames'
import {useSelector} from 'react-redux'
import {Transition} from '@headlessui/react'
import type {RootState} from '~data/client/store'
import getPageLayoutData from '~data/getPageLayoutData'
import WatchModal from '~components/watches/watchModel'
import {useDispatch} from 'react-redux'
import {updateRightBarActive} from '~data/client/navigation.slice'

function MainContent({
  children,
  rightBarContent,
}: {
  children: React.ReactNode
  rightBarContent: React.ReactNode
}) {
  const dispatch = useDispatch()
  const pathname = usePathname()
  const showFollowModal = useSelector(
    (state: RootState) => state.watches.showFollowModal,
  )
  const enableRightBar = useMemo(() => {
    return getPageLayoutData(pathname)?.enableRightBar !== false
  }, [pathname])

  const rightBarOpen = useSelector(
    (state: RootState) => state.navigation.rightBarActive,
  )

  const mainContentDomRef = useRef<HTMLDivElement>(null)

  const isArticle =
    !pathname.includes('my-stocks/coverage') &&
    !pathname.includes('gameplan/coverage') &&
    (pathname.includes('/commentary/') ||
      pathname.includes('/coverage/') ||
      pathname.includes('/exclusives/') ||
      pathname.includes('/guide/') ||
      pathname.includes('/optionsu/') ||
      pathname.includes('/recommendations/') ||
      pathname.includes('/featured-stocks/') ||
      pathname.includes('/earnings/') ||
      pathname.includes('/investing/') ||
      pathname.includes('/retirement/'))

  // These have Sli.do embeds in the sidebar
  const isRightBarDefault =
    pathname.includes('/news-and-analysis/fool24') ||
    pathname.includes('/ai-summit')

  useEffect(() => {
    dispatch(updateRightBarActive({rightBarActive: false}))
  }, [pathname])

  useEffect(() => {
    // Right Rail should be open by default on Desktop Articles only (and some exceptions)
    let windowWidth = 0
    if (typeof window !== 'undefined') {
      windowWidth = window.innerWidth
    }
    if ((isArticle || isRightBarDefault) && windowWidth >= 1260) {
      dispatch(updateRightBarActive({rightBarActive: true}))
    }
  }, [isArticle, isRightBarDefault, pathname])

  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0)

  useEffect(() => {
    const updateStickyHeaderHeight = () => {
      const stickyHeader = document.getElementById('layout-sticky-header')
      setStickyHeaderHeight(stickyHeader?.clientHeight || 0)
    }
    if (pathname === '/new-recs') {
      updateStickyHeaderHeight()
      window.addEventListener('resize', updateStickyHeaderHeight)
      return () => {
        window.removeEventListener('resize', updateStickyHeaderHeight)
      }
    }
  }, [pathname])

  useEffect(() => {
    if (isArticle) {
      // Dom manipulations to add/remove classes for roundtable question sub-headings
      const articleRoundtable =
        mainContentDomRef.current?.getElementsByClassName(
          'mt-36px mb-24px border-b-3px',
        )

      const articleBlockQuotes =
        mainContentDomRef.current?.querySelectorAll('blockquote')

      if (articleBlockQuotes && articleBlockQuotes?.length > 0) {
        Array.from(articleBlockQuotes).forEach((element) => {
          element?.removeAttribute(
            '-mt-36px border-l-1px pl-16px border-magenta-800 border-r-1px pr-16px',
          )
          const pElements = element?.parentElement?.getElementsByClassName(
            'text-center text-magenta-800',
          )
          if (pElements) {
            Array.from(pElements).forEach((pElement) => {
              pElement.remove()
            })
          }
          element?.parentElement?.removeAttribute(
            'bg-white w-5/12 float-left mr-36px -mb-12px mt-0px',
          )
          element?.parentElement?.setAttribute(
            'class',
            'bg-white border-blockquote',
          )
          element?.setAttribute(
            'class',
            'block-css pt-16px pb-16px pl-24px pr-24px',
          )
        })
      }
      if (articleRoundtable && articleRoundtable?.length > 0) {
        Array.from(articleRoundtable).forEach((element) => {
          element?.removeAttribute('mt-36px mb-24px border-b-3px')
          element?.setAttribute('class', 'sub-heading')
        })
      }
    }
  }, [mainContentDomRef.current, isArticle])

  return (
    <>
      <div
        ref={mainContentDomRef}
        className={`relative mx-auto flex w-full items-start ${
          isArticle ? 'article-backdrop' : ''
        }`}
      >
        <div
          className={`flex-1 min-w-0 relative z-0 min-h-screen ${
            isArticle ? 'max-w-[840px] article-backdrop' : ''
          }`}
        >
          {showFollowModal && <WatchModal />}
          <div
            className={classNames(
              enableRightBar && rightBarOpen
                ? `w-full ${
                    isArticle
                      ? 'xl:w-[840px] lg:w-[744px] md:w-[616px]'
                      : 'lg:w-[calc(100%-380px)]'
                  }`
                : 'w-full',
              'transition-[width] ease-in-out duration-300 p-4 lg:p-6',
            )}
          >
            {children}
          </div>
        </div>
        {enableRightBar && (
          <Transition show={rightBarOpen} as={Fragment}>
            <div className="z-10">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full opacity-0"
                enterTo="translate-x-0 opacity-1"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0 opacity-1"
                leaveTo="translate-x-full opacity-0"
              >
                <div
                  id="global-right-bar"
                  style={
                    pathname === '/new-recs'
                      ? {
                          top: stickyHeaderHeight + 'px',
                          minHeight: `calc(100% - ${stickyHeaderHeight}px)`,
                        }
                      : undefined
                  }
                  className={`${
                    isArticle ? 'article-sb-position' : 'right-0'
                  } ${
                    pathname === '/new-recs' ? `fixed` : 'absolute top-0'
                  } bottom-0 w-[380px]
                    min-h-full flex-1 overflow-y-auto border-l-1px border-content-8 bg-content-0`}
                >
                  <div className="first-line:overflow-y-auto h-full overflow-y-auto">
                    {/* Right Bar Content */}
                    {rightBarContent}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Transition>
        )}
      </div>
    </>
  )
}

export default MainContent
