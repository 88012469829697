import PerformanceChip from '@fool/jester-ui/PerformanceChip'
import Card from '~components/card'
import {formatPrice} from '~utils/formatters/numbers'

type PriceAndChangeChipProps = {
  currencyCode?: string
  price: number
  change: number
}

const PriceAndChangeChip = ({
  currencyCode = 'USD',
  price,
  change,
}: PriceAndChangeChipProps) => {
  const formatteddPrice = formatPrice(price, currencyCode)
  return (
    <>
      <Card shadow="large" padding="p-8px">
        <span className="pr-4px">{formatteddPrice}</span>
        <PerformanceChip value={change} />
      </Card>
    </>
  )
}

export default PriceAndChangeChip
