'use client'
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {usePathname} from 'next/navigation'
import Button from '@fool/jester-ui/Button'
import {SidebarIcon} from '@fool/jester-ui/Icon'
import IconButton from '@fool/jester-ui/IconButton'
import getPageLayoutData from '~data/getPageLayoutData'
import type {RootState} from '~data/client/store'
import {updateRightBarActive} from '~data/client/navigation.slice'
import {PageLayoutData} from '~data/types/page'

function RightBarToggle() {
  const pathname = usePathname()
  const [pageInfo, setPageInfo] = useState<PageLayoutData>({})

  useEffect(() => {
    setPageInfo(getPageLayoutData(pathname))
  }, [pathname])

  const dispatch = useDispatch()
  const rightBarOpen = useSelector(
    (state: RootState) => state.navigation.rightBarActive,
  )
  const dispatchSetRightBar = (val: boolean | undefined) =>
    dispatch(updateRightBarActive({rightBarActive: val || !rightBarOpen}))

  const defaultEnableRightBar = true
  const {enableRightBar} = {enableRightBar: defaultEnableRightBar, ...pageInfo}

  return (
    <>
      {/* Right Bar Toggle Button */}
      {enableRightBar && (
        <div className="flex items-center rightbar-togle">
          <IconButton
            onClick={() => dispatchSetRightBar(!rightBarOpen)}
            variant={`${!rightBarOpen ? 'primary' : 'secondary'}`}
            className="md:hidden w-full"
            style={{border: 'none'}}
            aria-label={rightBarOpen ? 'close right bar' : 'open right bar'}
          >
            <SidebarIcon />
          </IconButton>
          <Button
            onClick={() => dispatchSetRightBar(!rightBarOpen)}
            variant={`${!rightBarOpen ? 'primary' : 'secondary'}`}
            className="hidden md:block lg:hidden w-full"
            rounded="full"
            aria-label={rightBarOpen ? 'close right bar' : 'open right bar'}
          >
            <div className="flex items-center">
              <SidebarIcon />
              <span className="ml-[8px] whitespace-nowrap">
                {rightBarOpen ? 'Close Sidebar' : 'Open Sidebar'}
              </span>
            </div>
          </Button>
          <IconButton
            onClick={() => dispatchSetRightBar(!rightBarOpen)}
            variant={`${!rightBarOpen ? 'primary' : 'secondary'}`}
            className="hidden lg:flex w-full"
            style={{border: 'none'}}
            aria-label={rightBarOpen ? 'close right bar' : 'open right bar'}
          >
            <SidebarIcon />
          </IconButton>
        </div>
      )}
    </>
  )
}

export default RightBarToggle
