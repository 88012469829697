import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export type InvestingType = 'cautious' | 'moderate' | 'aggressive' | undefined

export type GamePlanState = {
  investingType: InvestingType
}

const initialState: GamePlanState = {
  investingType: undefined,
}

const gamePlanSlice = createSlice({
  name: 'gameplan',
  initialState,
  reducers: {
    setInvestingType: (state, action: PayloadAction<InvestingType>) => {
      state.investingType = action.payload
    },
  },
})

export const {setInvestingType} = gamePlanSlice.actions
export default gamePlanSlice.reducer
