'use client'

import {useEffect} from 'react'
import {datadogRum} from '@datadog/browser-rum'

export default function DDRum() {
  useEffect(() => {
    const ddEnv = process.env.NEXT_PUBLIC_DATADOG_RUM_ENV || ''
    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID || ''
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_TOKEN || ''
    const environments = ['production', 'staging']
    if (environments.includes(ddEnv)) {
      datadogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.com',
        service: 'epic-2.0',
        env: ddEnv,
        // TODO: specify version?
        // version: '1.0.0',
        sessionSampleRate: 10,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      })
      // datadogRum.startSessionReplayRecording()  // not sure if needed but used in project-pando?
    }
  }, [])

  return null
}
