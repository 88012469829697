import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {UserMessagingProfile} from '~data/api/account/account.types'
import {RootState} from '~data/client/store'
import {ACCOUNT_FORM_TYPE as AccountFormType} from '~data/constants'

type AccountState = {
  activeForm: AccountFormType | null
  messagingProfile: UserMessagingProfile | null
}

const initialState: AccountState = {
  activeForm: null,
  messagingProfile: null,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateActiveForm: (
      state,
      action: PayloadAction<{activeForm: AccountFormType | null}>,
    ) => {
      state.activeForm = action.payload.activeForm
    },
    updateMessagingProfile: (
      state,
      action: PayloadAction<UserMessagingProfile>,
    ) => {
      state.messagingProfile = action.payload
    },
  },
})

const getActiveFormType = (state: RootState) => state.account.activeForm
const getMessagingProfile = (state: RootState) => state.account.messagingProfile

export const {updateActiveForm, updateMessagingProfile} = accountSlice.actions

export {getActiveFormType, getMessagingProfile}

export default accountSlice.reducer
