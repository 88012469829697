export default function GamePlanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1.75 7.5V13V17.5C1.75 18.0523 2.19772 18.5 2.75 18.5H5.75C6.30228 18.5 6.75 18.0523 6.75 17.5V17C6.75 17 5.75 16.5 5.75 15.5C5.75 14.3614 7.11144 13.5 8.25 13.5C9.38856 13.5 10.7742 14.3617 10.75 15.5C10.735 16.2039 9.75 17 9.75 17V17.5C9.75 18.0523 10.1977 18.5 10.75 18.5H12.25C12.8023 18.5 13.25 18.0523 13.25 17.5V13.5C13.25 12.9477 13.6977 12.5 14.25 12.5H14.75C14.75 12.5 15.25 13.5 16.25 13.5C17.25 13.5 18.25 12.2503 18.25 11C18.25 9.74971 17.25 8.5 16.25 8.5C15.25 8.5 14.75 9.5 14.75 9.5H14.25C13.6977 9.5 13.25 9.05228 13.25 8.5V7.5C13.25 6.94772 12.8023 6.5 12.25 6.5H10.25C9.69772 6.5 9.25 6.05228 9.25 5.5V5C9.25 5 10.25 4.5 10.25 3.5C10.25 2.5 9.00029 1.5 7.75 1.5C6.49971 1.5 5.25 2.5 5.25 3.5C5.25 4.5 6.25 5 6.25 5V5.5C6.25 6.05228 5.80228 6.5 5.25 6.5H2.75C2.19772 6.5 1.75 6.94772 1.75 7.5Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}
