import {gql} from '@apollo/client'

export const GET_COMPANY_PAGE_DATA = gql`
  query GetCompanyPageData($instrumentId: ID) {
    instrument(id: $instrumentId) {
      id
      quote {
        currency {
          code
        }
        dividendYield
        dailyRange {
          max {
            amount
          }
          min {
            amount
          }
        }
        currentPrice {
          amount
        }
        beta5y
        marketCap {
          amount
        }
        fiftyTwoWeekRange {
          max {
            amount
          }
          min {
            amount
          }
        }
        grossMargin
        lastTradeDate
        percentChange
        priceChange {
          amount
        }
        shortInterestRatio
        annualDividend {
          amount
        }
        extendedHoursChange
        extendedHoursDate
        extendedHoursPercentChange
        extendedHoursPrice
        extendedHoursType
        description
      }
      quoteHistoricalSummary {
        fiveYearReturnPercent
        sinceIPOReturnPercent
        benchmarkFiveYearReturnPercent
        benchmarkOneYearReturnPercent
        oneYearReturnPercent
        fiveYearAnnualizedReturnPercent
        benchmarkFiveYearAnnualizedReturnPercent
        benchmarkSinceIPOReturnPercent
      }
      quoteFundamentals {
        dynamic {
          ceo {
            data {
              value
            }
          }
          marketCapPerEmployee {
            data {
              value
            }
          }
          website {
            data {
              value
            }
          }
          marketCapitalization {
            data {
              value
              unit
            }
          }
          numberOfEmployees {
            data {
              value
            }
          }
        }
        annual {
          revenue {
            data {
              date
            }
          }
        }
        quarterly {
          revenue {
            data {
              date
            }
          }
        }
      }
      symbol
      exchange
      name
      instrumentId
      # foolishFactors {
      #   asOf
      #   overall {
      #     percentile
      #   }
      #   composite {
      #     growth {
      #       weight
      #       percentile
      #     }
      #     marketBuzz {
      #       weight
      #       percentile
      #     }
      #     quality {
      #       weight
      #       percentile
      #     }
      #     safety {
      #       weight
      #       percentile
      #     }
      #     valuation {
      #       weight
      #       percentile
      #     }
      #   }
      # }
    }
  }
`

export const GET_COMPANY_ACCESSIBLE_RECOMMENDATIONS = gql`
  query GetCompanyAccessibleRecommendations($instrumentId: ID) {
    instrument(id: $instrumentId) {
      instrumentId
      accessibleFoolRecommendations {
        id
        action
        actionDate
        portfolio {
          displayName
          identifier
          product
        }
        content {
          path
          publishAt
          uuid
        }
        performance {
          percentReturn
        }
      }
      alternativeInstruments {
        instrumentId
        symbol
      }
    }
  }
`

export const GET_COMPANY_FINANCIALS = gql`
  query GetCompanyFinancials($instrumentId: ID) {
    instrument(id: $instrumentId) {
      quoteFundamentals {
        dynamic {
          revenueTtm {
            data {
              value
              unit
              date
            }
          }
          grossProfitsTtm {
            data {
              value
              unit
              date
            }
          }
          ebitdaTtm {
            data {
              value
              unit
              date
            }
          }
        }
        annual {
          revenue {
            yearOverYear {
              value
              unit
              date
            }
            data {
              value
              unit
              date
            }
          }
          grossProfits {
            yearOverYear {
              date
              unit
              value
            }
            data {
              value
              unit
              date
            }
          }
          ebitda {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          netIncome {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          grossProfitMargin {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          employees {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          marketCapFiscal {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          marketCapPerEmployee {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          epsFullyDiluted {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          netCash {
            data {
              date
              unit
              value
            }
          }
          accountsReceivable {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          inventory {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          longTermDebt {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          shortTermDebt {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          totalDebt {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          returnOnAverageTotalEquity {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          returnOnAssets {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          average5YrReturnOnInvestedCapital {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          netOperatingFreeCashFlow {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          freeCashFlow {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
        }
        quarterly {
          revenue {
            yearOverYear {
              value
              unit
              date
            }
            data {
              value
              unit
              date
            }
          }
          grossProfits {
            yearOverYear {
              date
              unit
              value
            }
            data {
              value
              unit
              date
            }
          }
          ebitda {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          netIncome {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          grossProfitMargin {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          employees {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          marketCapFiscal {
            data {
              value
              unit
              date
            }
            yearOverYear {
              value
              unit
              date
            }
          }
          marketCapPerEmployee {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          netCash {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          accountsReceivable {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          inventory {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          longTermDebt {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          shortTermDebt {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          totalDebt {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          returnOnAverageTotalEquity {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          returnOnAssets {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          average5YrReturnOnInvestedCapital {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          freeCashFlow {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          netOperatingFreeCashFlow {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
        }
      }
    }
  }
`

export const GET_COMPANY_VALUATION = gql`
  query GetCompanyValuation($instrumentId: ID) {
    instrument(id: $instrumentId) {
      quoteFundamentals {
        dynamic {
          peRatioCurrent {
            data {
              date
              unit
              value
            }
          }
          priceToBookCurrent {
            data {
              date
              unit
              value
            }
          }
          priceToSalesCurrent {
            data {
              date
              unit
              value
            }
          }
          priceToTangibleBookValueCurrent {
            data {
              date
              unit
              value
            }
          }
        }
        annual {
          priceToEarningsRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToBookRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToSalesRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToTangibleBookValueFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToFreeCashFlowRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          enterpriseValueToEbitda {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          freeCashFlow {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          marketCapFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
        }
        quarterly {
          priceToEarningsRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToBookRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToSalesRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToTangibleBookValueFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          priceToFreeCashFlowRatioFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          enterpriseValueToEbitda {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          freeCashFlowYield {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
          marketCapFiscal {
            data {
              date
              unit
              value
            }
            yearOverYear {
              date
              unit
              value
            }
          }
        }
      }
    }
  }
`

export const COMPANY_SIDEBAR_QUERY = gql(`
  query getCompanySideBarData($instrumentId: ID!) {
    instrument(id: $instrumentId) {
      predictionByInstrument {
        maxDrawdownRiskPerYear
        returnEstimateHigh
        returnEstimateLow
        cmaTagLabel
      }
      peerInstruments {
        instrumentId
        symbol
        quote {
          currentPrice {
            amount
            currencyCode
          }
          percentChange
        }
      }
    }
  }
`)

export const GET_INVESTOR_PROFILE = gql`
  query GetInvestorProfile($contentAuthorId: String!) {
    contentAuthor(id: $contentAuthorId) {
      byline
      authorId
      email
      firstName
      foolUid
      id
      largeAvatarUrl
      lastName
      longBio
      shortBio
      smallAvatarUrl
      twitterUsername
      username
      uuid
      videoUrl
      additionalDisclosures
      marthetronHoldings {
        id
        instrument {
          exchange
          symbol
          name
        }
        details {
          ... on StockHolding {
            isShort
            lastTradeDate
            strategy
          }
          ... on OptionHolding {
            isShort
            lastTradeDate
            expiration
            strikePrice
            optionType
            strategy
          }
        }
      }
    }
  }
`

export const ACCESSIBLE_PORTFOLIOS_QUERY = gql`
  query AccessiblePortfolios {
    accessiblePortfolios {
      product
      displayName
    }
  }
`

export const ACCESSIBLE_SERVICES_QUERY = gql`
  query AccessibleServices {
    user {
      products {
        id
        productId
        shortName
        slug
        archived
        portfolios {
          vaulted
        }
      }
    }
  }
`

export const ACCESSIBLE_STOCK_RANKER_PORTFOLIOS_QUERY = gql`
  query AccessibleStockRanker {
    stockRankerPortfolios {
      id
      name
      portfolio {
        product
        slug
        displayName
        identifier
        goal
        vaulted
        arePositionsRanked
      }
    }
  }
`

export const GET_ACCESSIBLE_REPORTS = gql`
  query AccessibleReports($limit: Int, $offset: Int, $keyword: String) {
    user {
      recentReports
    }
    contents(
      dataSource: "ffe.standalone_report.ReportHome"
      limit: $limit
      offset: $offset
      keyword: $keyword
    ) {
      uuid
      headline
      publishAt
      lastUpdated
      path
      images {
        featured
        url
        name
      }
      aiSummary
      instruments {
        instrumentId
        symbol
        name
        exchange
        quote {
          currentPrice {
            currencyCode
            amount
          }
          marketCap {
            amount
            currencyCode
          }
          percentChange
        }
        predictionByInstrument {
          cmaTagLabel
        }
      }
      product {
        productId
      }
    }
  }
`

export const GET_REPORT_DETAIL = gql`
  query GetReportDetail($path: String!) {
    contentDetailByPath(path: $path, parseBody: true, rootPath: "/premium/") {
      headline
      disclosure
      byline
      images {
        featured
        url
        name
      }
      instruments {
        instrumentId
        exchange
        symbol
        name
        quote {
          percentChange
          currentPrice {
            amount
            currencyCode
          }
        }
        quantScore {
          value
        }
      }
      publishAt
      lastUpdated
      body
      authors {
        author {
          authorId
          username
          smallAvatarUrl
          byline
        }
      }
    }
  }
`

export const HUB_ARTICLE_QUERY = gql`
  query HubArticles($collectionName: String!) {
    curatedArticles(collectionName: $collectionName) {
      headline
      link
    }
  }
`

export const VAULTED_PRODUCTS_QUERY = gql(`
  query VaultedPortfolios($foolIqid: Int) {
    vaultedPortfolios(foolIQID: $foolIqid) {
      benchmarkReturn
      portfolioFrozenAsOf
      return
      returnVsBenchmark
    }
  }
`)
export const VAULT_FINAL_REPORT = gql`
  query VaultReports($tags: [String!], $productIds: [Int!], $limit: Int) {
    contents(tags: $tags, productIds: $productIds, limit: $limit) {
      averageRating
      commentCount
      headline
      path
      publishAt
      uuid
      url
      tags {
        name
        slug
      }
    }
  }
`

export const REALMONEY_TWRR_QUERY = gql`
  query DailyTimeWeightedRateOfReturn(
    $portfolioId: String!
    $startDate: String
    $endDate: String
  ) {
    realMoneyPortfolio(
      portfolioId: $portfolioId
      startDate: $startDate
      endDate: $endDate
    ) {
      dailyTimeWeightedRateOfReturn {
        benchmarkName
        benchmarkTwrr
        portfolioTwrr
        periodThruDate
        inceptionDate
        benchmarkPeriodPercentReturn
        portfolioPeriodPercentReturn
      }
    }
  }
`
