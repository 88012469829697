'use client'

import {useEffect, useState} from 'react'
import {usePathname} from 'next/navigation'
import Heading from '@fool/jester-ui/Heading'
import getPageLayoutData from '~data/getPageLayoutData'
import ServiceMenu from './service-menu'
import StrategiesMenu from './strategies-menu'
import {PageLayoutData} from '~data/types/page'

function HeaderTitle() {
  const pathname = usePathname()
  const [pageInfo, setPageInfo] = useState<PageLayoutData>({})
  useEffect(() => {
    setPageInfo(getPageLayoutData(pathname))
  }, [pathname])
  // This is being duplicated a lot, pull this from somewhere else
  const defaultValues = {
    showTickerTape: false,
    headerTitle: null,
    hasServiceMenu: false,
    hasStrategiesMenu: false,
  }
  const {headerTitle, hasServiceMenu, hasStrategiesMenu, HeaderComponent} = {
    ...defaultValues,
    ...pageInfo,
  }

  let title: React.ReactNode = null

  if (hasServiceMenu) {
    title = (
      <ServiceMenu className="w-fit [&>button]:bg-primary-0 [&>*]:border-none" />
    )
  } else if (hasStrategiesMenu) {
    title = <StrategiesMenu />
  } else if (headerTitle) {
    title = HeaderComponent ? (
      <HeaderComponent headerTitle={headerTitle} />
    ) : (
      <Heading as="h1" looksLike="h2">
        {headerTitle}
      </Heading>
    )
  }

  return (
    <>
      {title && (
        <div className="flex items-center pr-3 pt-4 lg:pt-0">{title}</div>
      )}
    </>
  )
}

export default HeaderTitle
