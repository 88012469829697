'use client'
// ^ this file needs the 'use client' pragma
import {ApolloLink, HttpLink} from '@apollo/client'
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries'
import {createHash} from 'crypto'
import {onError} from '@apollo/client/link/error'
import type {ErrorResponse} from '@apollo/client/link/error'

async function getSHA256(arrayBuffer: ArrayBuffer): Promise<string> {
  const buffer = Buffer.from(arrayBuffer)
  const hash = createHash('sha256')
  hash.update(buffer)
  return hash.digest('hex')
}

import {
  NEXT_PUBLIC_APIKEY,
  NEXT_PUBLIC_FOOL_ENV,
  NEXT_PUBLIC_IS_PREVIEW,
  NEXT_PUBLIC_PREMIUM_GRAPHQL_API_URL,
} from '~data/constants'
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr'
import {Session} from 'next-auth/types'
import React from 'react'

// you need to create a component to wrap your app in
export function ApolloWrapper({
  session,
  children,
}: {
  session: Session | null
  children: React.ReactNode
}) {
  const errorLink = onError(
    ({
      graphQLErrors,
      operation,
      forward,
      // networkError,
      // response,
    }: ErrorResponse) => {
      // Current documentation for this is sparse.
      // Keeping this here but commented out for reference.
      // console.log(
      //   `Apollo Client Provider Errors: \n ,
      //   graphQLErrors: ${JSON.stringify(graphQLErrors)} \n
      //   networkError: ${JSON.stringify(networkError)} \n
      //   operation: ${JSON.stringify(operation)} \n
      //   response: ${JSON.stringify(response)} \n`,
      // )

      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.message.includes('The operation was aborted')) {
            // Attempt operation one more time if aborted
            forward(operation)
          }
        })
      }
    },
  )

  // have a function to create a client for you
  function makeClient() {
    const environment =
      NEXT_PUBLIC_IS_PREVIEW === 'true'
        ? NEXT_PUBLIC_FOOL_ENV + '-preview'
        : NEXT_PUBLIC_FOOL_ENV + ''
    const httpLink = createPersistedQueryLink({
      sha256: getSHA256,
      useGETForHashedQueries: true,
    }).concat(
      new HttpLink({
        // this needs to be an absolute url, as relative urls cannot be used in SSR
        uri: NEXT_PUBLIC_PREMIUM_GRAPHQL_API_URL,
        // you can disable result caching here if you want to
        // (this does not work if you are rendering your page with `export const dynamic = 'force-static'`)
        headers: {
          Apikey: NEXT_PUBLIC_APIKEY || '',
          Authorization: session ? `Bearer ${session?.accessToken}` : '',
          Environment: environment,
        },
        // fetchOptions: {cache: 'no-store'},
        // you can override the default `fetchOptions` on a per query basis
        // via the `context` property on the options passed as a second argument
        // to an Apollo Client data fetching hook, e.g.:
        // const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: 'force-cache' }}})
      }),
    )

    return new NextSSRApolloClient({
      // use the `NextSSRInMemoryCache`, not the normal `InMemoryCache`
      cache: new NextSSRInMemoryCache(),
      name: 'premium-front',
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              errorLink,
              // in a SSR environment, if you use multipart features like
              // @defer, you need to decide how to handle these.
              // This strips all interfaces with a `@defer` directive from your queries.
              new SSRMultipartLink({
                stripDefer: true,
              }),
              httpLink,
            ])
          : errorLink.concat(httpLink),
    })
  }

  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  )
}
