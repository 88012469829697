import {parseISO, isToday, format} from 'date-fns'

const toDate = (date: string): Date => {
  if (date.includes('T')) {
    return parseISO(date)
  }
  // Ensure that the date is in the correct format/timezone
  const newDate = new Date(date)
  return new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000)
}

//Dates returned in the format mm/dd/yy
const abbreviatedNumberDate = (date: string) => {
  return format(toDate(date), 'MM/dd/yy')
}

// For Articles or Comments, to display either the time of the current day
// or a previous date.
const timeOrDate = (dateStr: string, dateFmt = 'MMM d') => {
  const date = parseISO(dateStr)
  date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
  if (isToday(date)) {
    return format(date, 'h:mm a')
  }
  return format(date, dateFmt)
}

export {abbreviatedNumberDate, timeOrDate, toDate}
