import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import {Product} from '~types/__generated__/graphql'

const PRODUCTS_WITHOUT_SCORECARD = ['rule-your-retirement', 'epic-bundle']

export const getNestedProducts = (products: Partial<Product>[]) => {
  const portfolioProducts = [
    ...products.filter(
      (product) =>
        !product.archived &&
        product.showInNav &&
        !PRODUCTS_WITHOUT_SCORECARD.includes(`${product?.slug}`),
    ),
  ]

  const allChildProductIds = portfolioProducts.flatMap(
    (product) => product.childProducts || [],
  )

  const topLevelPortfolioProducts = portfolioProducts.filter(
    (product) => !allChildProductIds.includes(product?.productId || NaN),
  )

  const nestedProducts = topLevelPortfolioProducts.flatMap((product) => {
    const childProducts = product.childProducts!.map((childProductId) => {
      return products.find((p) => p.productId === childProductId)
    })

    const childPortfolios = childProducts.flatMap(
      (childProduct) => childProduct?.portfolios || [],
    )
    if (!childPortfolios.length) {
      return product
    }

    const allChildPortfolios = uniqBy(
      [...(product?.portfolios || []), ...childPortfolios],
      'displayName',
    )
    return {
      ...product,
      portfolios: orderBy(allChildPortfolios, 'displayName', 'asc'),
    }
  })

  return orderBy(
    [
      ...nestedProducts.filter(
        (product) => product?.portfolios?.length || product,
      ),
    ],
    'shortName',
    'asc',
  )
}
