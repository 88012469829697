'use client'

import Skeleton from 'react-loading-skeleton'
import {useParams, usePathname, useRouter} from 'next/navigation'
import {Suspense, useTransition} from 'react'
import {DropDownMenu, DropdownItem} from './dropdown'
import {PortfolioStrategyPortfolioType} from '~types/__generated__/graphql'
import {CheckmarkIcon} from '@fool/jester-ui/Icon'
import {Link} from '~components/common/link'

function StrategyDropDownComponent({
  selected,
  item,
}: {
  selected: boolean
  item: DropdownItem
}) {
  return (
    <>
      {item.key === 'back-to-strategies' ? (
        <Link
          href="/strategy-tools/strategies"
          className="text-primary-100 w-full block font-black px-16px py-8px border-t-1px border-primary-8"
        >
          Back to Strategies
        </Link>
      ) : (
        <span className="flex w-full items-center justify-between px-16px py-8px">
          <span
            className={`block truncate  ${
              selected ? 'font-black' : 'font-normal'
            }`}
          >
            {item.label.replace(' Strategy', '')}
          </span>
          {selected ? <CheckmarkIcon size="lg" /> : null}
        </span>
      )}
    </>
  )
}

export default function StrategiesMenu() {
  const {strategyStyle, portfolioType} = useParams<{
    strategyStyle: string
    portfolioType: string
  }>()
  const pathname = usePathname()
  const router = useRouter()
  const [isPending, startTransition] = useTransition()

  const items = [
    {label: 'Cautious Strategy', key: 'cautious'},
    {label: 'Moderate Strategy', key: 'moderate'},
    {label: 'Aggressive Strategy', key: 'aggressive'},
    {label: 'Back to Strategies', key: 'back-to-strategies'},
  ]

  const onChange = (selected: DropdownItem) => {
    startTransition(() => {
      const current = strategyStyle
      let to = pathname.replace(current, selected.key)
      if (current !== selected.key) {
        // Reset the portfolioType to strategy_port if the dropdown changes
        to = to.replace(
          portfolioType,
          PortfolioStrategyPortfolioType.StrategyPort.toLowerCase(),
        )
      }
      return router.push(to)
    })
  }

  return (
    <>
      {items && (
        <Suspense fallback={<Skeleton height={40} width={240} />}>
          {/* TODO: why didn't we use the Select component? */}
          <DropDownMenu
            items={items}
            DropdownItemComponent={StrategyDropDownComponent}
            dropdownClasses="z-50"
            buttonClasses={isPending ? 'opacity-70 cursor-wait' : ''}
            defaultSelected={strategyStyle}
            onChange={onChange}
          />
        </Suspense>
      )}
    </>
  )
}
