import {
  createListenerMiddleware,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import accountReducer from './account.slice'
import brazeStore from './braze.slice'
import routeReducer from './route.slice'
import buttonReducer from './button.slice'
import navigationReducer from './navigation.slice'
import newRecsReducer from './new-recs.slice'
import rankingsReducer from './rankings.slice'
import modalReducer from './modal.slice'
import userReducer from './user.slice'
import watchesReducer from './watches.slice'
import myStocksReducer from '~app/(main)/my-stocks/my-stocks.slice'
import commentsReducer from './comments.slice'
import notificationsReducer from './notifications.slice'
import paymentDetailsReducer from './payment-details.slice'
import gameplanReducer from './gameplan.slice'
import advisorNotesReducer from './advisor-notes.slice'
import {ApolloClient} from '@apollo/client'
import {setMyStocksListeners} from '~app/(main)/my-stocks/utils/listeners'
import type {Session} from 'next-auth/types'

// TODO: Take inventory of how states are sliced here.
// Make sure they are separated by domain data, app state and UI state

const rootReducer = combineReducers({
  account: accountReducer,
  braze: brazeStore,
  button: buttonReducer,
  comments: commentsReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  newRecs: newRecsReducer,
  user: userReducer,
  watches: watchesReducer,
  rankings: rankingsReducer,
  myStocks: myStocksReducer,
  paymentDetails: paymentDetailsReducer,
  notifications: notificationsReducer,
  gameplan: gameplanReducer,
  route: routeReducer,
  advisorNotes: advisorNotesReducer,
})

export const createStore = (
  apolloClient: ApolloClient<object>,
  session?: Session | null,
) => {
  const myStocksListenerMiddleware = setMyStocksListeners(
    createListenerMiddleware({
      extra: {apolloClient, session},
    }),
  )

  return configureStore({
    reducer: rootReducer,
    devTools: {
      trace: true,
    },
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: {
            apolloClient,
            session,
          },
        },
      }).prepend(myStocksListenerMiddleware.middleware)
    },
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']
