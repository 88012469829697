import {useQuery} from '@apollo/client'
import {Link} from '~components/common/link'
import Heading from '@fool/jester-ui/Heading'
import {HUB_ARTICLE_QUERY} from '~data/queries.js'
import {
  StockAdvisorJesterHat,
  EpicJesterHat,
  EpicPlusJesterHat,
  FoolPortfoliosJesterHat,
  FoolOneJesterHat,
} from '~components/jester-temp/icons.tsx'
import {UserTier} from '~data/api/tiers'
import {HubArticlesQuery} from '~types/__generated__/graphql'
import {useSidebar} from '~components/ui/sidebar'
import {cn} from '~lib/utils'

interface LogoProps {
  userTier?: UserTier | null
}

export function Logo({userTier}: LogoProps) {
  const sideBarState = useSidebar()

  // For members with a single service (i.e. L1 SA), we want to redirect to that
  // service's homepage. For members with multiple services, we want to redirect
  // to the premium homepage.
  const homepageLink = userTier?.tier === 1 ? '/my-services/stock-advisor' : '/'

  const {data: hubArticlesData} = userTier
    ? useQuery<HubArticlesQuery>(HUB_ARTICLE_QUERY, {
        variables: {
          collectionName: 'Premium Hub Articles',
        },
      })
    : {data: null}

  return (
    <div
      className={cn(
        'flex transition-margin duration-300 h-[45px] justify-left gap-2',
        sideBarState.open ? 'mx-6' : 'mx-0 mt-2',
      )}
    >
      <div>
        {userTier?.tier === 1 && <StockAdvisorJesterHat />}
        {userTier?.tier === 2 && <EpicJesterHat />}
        {userTier?.tier === 3 && <EpicPlusJesterHat />}
        {userTier?.tier === 4 && <FoolPortfoliosJesterHat />}
        {userTier?.tier === 5 && <FoolOneJesterHat />}
      </div>
      <div
        className={cn(
          'transition-opacity duration-300 flex flex-col',
          sideBarState.open ? 'opacity-100' : 'opacity-0',
        )}
      >
        <Link href={homepageLink}>
          <Heading
            as="h4"
            looksLike="h4"
            className="text-content-4 font-black whitespace-nowrap"
          >
            {userTier?.name || 'Fool'}
          </Heading>
        </Link>

        {hubArticlesData?.curatedArticles?.length !== undefined &&
          hubArticlesData?.curatedArticles?.length > 0 &&
          hubArticlesData?.curatedArticles[0].link && (
            <Link href={hubArticlesData?.curatedArticles[0].link}>
              <p className="text-content-4 text-caption-regular font-bold whitespace-nowrap">
                About {userTier?.name}
              </p>
            </Link>
          )}
      </div>
    </div>
  )
}
