import {useState, Fragment, useEffect} from 'react'
import {Combobox, Transition} from '@headlessui/react'
import {ChevronBottomIcon, CheckmarkIcon} from '@fool/jester-ui/Icon'
import {FunctionComponent} from 'react'
import {twMerge} from 'tailwind-merge'

export type DropdownItem = {
  label: string
  key: string
}

export type DropDownMenuProps = {
  items: DropdownItem[]
  buttonClasses?: string
  defaultSelected?: string
  className?: string
  dropdownClasses?: string
  onChange?: (selected: DropdownItem) => void
  DropdownItemComponent?: FunctionComponent<{
    selected: boolean
    item: DropdownItem
  }>
}

export function DropDownMenu({
  items,
  className,
  dropdownClasses,
  buttonClasses,
  defaultSelected,
  onChange,
  DropdownItemComponent,
}: DropDownMenuProps) {
  const getDefaultSelected = () => {
    const selected = items.filter(
      ({key}) => defaultSelected && defaultSelected === key,
    )
    return selected[0]
  }

  const [selected, setSelected] =
    useState<ReturnType<typeof getDefaultSelected>>(getDefaultSelected())
  const [buttonLabel, setButtonLabel] = useState<string>('')

  useEffect(() => {
    selected && setButtonLabel(selected.label)
  }, [selected])

  useEffect(() => {
    if (onChange && selected) {
      onChange(selected)
    }
  }, [selected])

  const handleComboboxChange = (
    item: ReturnType<typeof getDefaultSelected>,
  ) => {
    setSelected(item)
  }

  return (
    <div className={twMerge('relative', className)}>
      <Combobox value={selected} onChange={handleComboboxChange} by="key">
        <Combobox.Button
          className={twMerge(
            'outline-none text-content-100 font-black flex items-center transition-colors hover:text-primary-100',
            buttonClasses,
          )}
        >
          <span className="text-heading-h2">{buttonLabel}</span>
          <ChevronBottomIcon />
        </Combobox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={twMerge(
              'mt-8px w-full shadow-nav rounded-[8px] bg-primary-0 absolute overflow-hidden',
              dropdownClasses,
            )}
          >
            <div
              /*
                Applies translate-x-0 (transform: translateX(0px)) to set the
                drop-down menu as the container element for possible
                fixed-position ads.
                - https://www.w3.org/TR/css-transforms-1/#module-interactions
                - https://stackoverflow.com/a/15885486
                */
              className={`translate-x-0 `}
            >
              <Combobox.Options className="outline-none">
                {items.map((item) => {
                  return (
                    <Combobox.Option
                      key={item.key}
                      className={({active, selected}) =>
                        ` hover:bg-primary-8 cursor-pointer ${
                          active ? 'bg-primary-8 ' : ''
                        } ${selected ? 'font-bold text-primary-100' : ''}`
                      }
                      value={item}
                    >
                      {({selected}) =>
                        DropdownItemComponent ? (
                          <DropdownItemComponent
                            selected={selected}
                            item={item}
                          />
                        ) : (
                          <span className="flex items-center justify-between px-16px py-8px">
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {item.label}
                            </span>
                            {selected ? <CheckmarkIcon size="lg" /> : null}
                          </span>
                        )
                      }
                    </Combobox.Option>
                  )
                })}
              </Combobox.Options>
            </div>
          </div>
        </Transition>
      </Combobox>
    </div>
  )
}
