import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {AdvisorNotesResponse} from '~data/api/advisor-notes-types'

export type AdvisorNotesState = {
  allStocksAdvisorNotes?: {
    [uuid: string]: AdvisorNotesResponse | undefined
  }
}

const initialState: AdvisorNotesState = {}

const advisorNotesSlice = createSlice({
  name: 'advisorNotes',
  initialState,
  reducers: {
    // Set advisor notes specifically for My Stocks Coverage tabs
    setMyStocksAdvisorNotes: (
      state,
      action: PayloadAction<{
        type?: string
        uuid: string
        advisorNotes: AdvisorNotesResponse
      }>,
    ) => {
      if (!state.allStocksAdvisorNotes) {
        state.allStocksAdvisorNotes = {}
      }
      state.allStocksAdvisorNotes[action.payload.uuid] =
        action.payload.advisorNotes

      // Reset the all-stocks state if the type is reset since a ticker has been added or deleted
      if (
        action.payload.type === 'reset' &&
        state.allStocksAdvisorNotes['all-stocks']
      ) {
        state.allStocksAdvisorNotes['all-stocks'] = null
      }
    },
  },
})

export const getMyStocksCurrentAdvisorNotes = (
  state: RootState,
  uuid: string,
) => {
  // Check to see if the notes exist based on the uuid argument passed in
  if (state.advisorNotes?.allStocksAdvisorNotes?.[uuid]) {
    return state.advisorNotes.allStocksAdvisorNotes[uuid]
  }
}

export const {setMyStocksAdvisorNotes} = advisorNotesSlice.actions
export default advisorNotesSlice.reducer
