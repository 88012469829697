export default function Fool24Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M20 7C20.13 7 20.25 7.12 20.25 7.25V19.25C20.25 19.38 20.13 19.5 20 19.5H4C3.87 19.5 3.75 19.38 3.75 19.25V7.25C3.75 7.12 3.87 7 4 7H20ZM20 5.25H4C2.9 5.25 2 6.15 2 7.25V19.25C2 20.35 2.9 21.25 4 21.25H20C21.1 21.25 22 20.35 22 19.25V7.25C22 6.15 21.1 5.25 20 5.25Z"
        fill="currentColor"
      />
      <path
        d="M15.5 9.25C15.77 9.25 16 9.48 16 9.75V16.75C16 17.02 15.77 17.25 15.5 17.25H6.5C6.23 17.25 6 17.02 6 16.75V9.75C6 9.48 6.23 9.25 6.5 9.25H15.5ZM15.5 7.75H6.5C5.4 7.75 4.5 8.65 4.5 9.75V16.75C4.5 17.85 5.4 18.75 6.5 18.75H15.5C16.6 18.75 17.5 17.85 17.5 16.75V9.75C17.5 8.65 16.6 7.75 15.5 7.75Z"
        fill="currentColor"
      />
      <path
        d="M7 2L12.21 6L17 2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.94 11.13C19.4591 11.13 19.88 10.7091 19.88 10.19C19.88 9.67085 19.4591 9.25 18.94 9.25C18.4209 9.25 18 9.67085 18 10.19C18 10.7091 18.4209 11.13 18.94 11.13Z"
        fill="currentColor"
      />
      <path
        d="M18.94 13.63C19.4591 13.63 19.88 13.2091 19.88 12.69C19.88 12.1709 19.4591 11.75 18.94 11.75C18.4209 11.75 18 12.1709 18 12.69C18 13.2091 18.4209 13.63 18.94 13.63Z"
        fill="currentColor"
      />
    </svg>
  )
}
