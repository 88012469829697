'use client'
import {useSelector} from 'react-redux'
import {Fragment, useEffect, useState, useMemo, useRef} from 'react'
import {Transition} from '@headlessui/react'
import {CheckmarkIcon} from '@fool/jester-ui/Icon'
import {XMarkIcon} from '@heroicons/react/20/solid'
import {TOAST_NOTIFICATION_DURATION} from '~data/constants'
import {RootState} from '~data/client/store'

function Notification(notification: {
  title?: string
  message: React.ReactNode | string
  type: string
}) {
  const [show, setShow] = useState(false)
  const [hover, setHover] = useState(false)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const notificationTypeBgColor =
    notification.type === 'success'
      ? 'bg-secondary-green-4'
      : notification.type === 'error'
        ? 'bg-secondary-red-4'
        : notification.type === 'default'
          ? 'bg-content-0'
          : 'bg-primary-0'

  useEffect(() => {
    setShow(true)

    const setTimer = () => {
      timeoutId.current = setTimeout(() => {
        setShow(false)
      }, TOAST_NOTIFICATION_DURATION)
    }

    if (!hover) {
      setTimer()
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [hover])

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`
          pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg mb-2
          shadow-lg ring-1 ring-black ring-opacity-5 ${notificationTypeBgColor}
        `}
        onMouseEnter={() => {
          setHover(true)
          if (timeoutId.current) {
            clearTimeout(timeoutId.current)
          }
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <div className="p-4">
          <div className="flex items-start">
            {notification.type === 'success' && (
              <div className="font-bold text-secondary-green-50">
                <CheckmarkIcon size="lg" />
              </div>
            )}
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {notification.title && (
                <p className="text-sm font-bold text-gray-900">
                  {notification.title}
                </p>
              )}
              <p className="text-sm text-gray-500">{notification.message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  setShow(false)
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default function ToastNotification() {
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  )

  const nonExpiredNotifications = useMemo(() => {
    return notifications.map((notification, index) => {
      return (
        <Notification
          key={`${notification.message}-${index}`}
          title={notification.title}
          message={notification.message}
          type={notification.type}
        />
      )
    })
  }, [notifications])

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed z-[100] inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <div className="w-full md:max-w-sm flex justify-end items-end flex-col">
          {nonExpiredNotifications}
        </div>
      </div>
    </div>
  )
}
