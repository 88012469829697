import {Maybe} from 'graphql/jsutils/Maybe'
import {Product} from '~types/__generated__/graphql'

export function getPortfolio(product: Maybe<Product>, portfolioSlug: string) {
  const portfolio = product?.portfolios.find(
    (portfolio) => portfolio.slug === portfolioSlug,
  )
  return portfolio || null
}

export function getVaultedPortfolioSlug(portfolioSlug: string) {
  return portfolioSlug === 'stock-advisor'
    ? // Account for the SA edge-case
      'stockadvisortominclusion'
    : portfolioSlug
}
