'use client'

import {useFool24LivestreamSchedule} from '~hooks/use-fool24-livestream-schedule'
import './fool24-on-air-indicator.css'

export default function Fool24OnAirIndicator() {
  const {isLive} = useFool24LivestreamSchedule()

  if (!isLive) return null

  return (
    <span className="text-secondary-red-50 relative inline-flex h-2 w-2 ml-2">
      <span className="animate-[fool24Ping_1.5s_cubic-bezier(0,0,0.2,1)_1s_infinite] absolute inline-flex h-full w-full rounded-full border-current border-[1px]"></span>
      <span className="relative inline-flex rounded-full h-2 w-2 bg-[currentColor]"></span>
    </span>
  )
}
