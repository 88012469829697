import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {
  IBillingAddress,
  ICreditCardOnFile,
} from '~data/api/account/account-types'
import type {RootState} from '~data/client/store'

import type {PaymentDetailFormType} from '~types/payment-details'

type PaymentDetailsState = {
  activeForm: PaymentDetailFormType | null

  cardOnFile: ICreditCardOnFile | null
  billingAddress: IBillingAddress | null
}

const initialState: PaymentDetailsState = {
  cardOnFile: null,
  billingAddress: null,
  activeForm: null,
}

const paymentDetailSlice = createSlice({
  name: 'paymentDetails',
  initialState,
  reducers: {
    setCardOnFile: (
      state,
      action: PayloadAction<{cardOnFile: ICreditCardOnFile | null}>,
    ) => {
      state.cardOnFile = action.payload.cardOnFile
    },
    setBillingAddress: (
      state,
      action: PayloadAction<{billingAddress: IBillingAddress | null}>,
    ) => {
      state.billingAddress = action.payload.billingAddress
    },
    updateActiveForm: (
      state,
      action: PayloadAction<{activeForm: PaymentDetailFormType | null}>,
    ) => {
      state.activeForm = action.payload.activeForm
    },
  },
})

const getActiveFormType = (state: RootState) => state.paymentDetails.activeForm
const getCardOnFile = (state: RootState) => state.paymentDetails.cardOnFile
const getBillingAddress = (state: RootState) =>
  state.paymentDetails.billingAddress

export const {updateActiveForm, setCardOnFile, setBillingAddress} =
  paymentDetailSlice.actions
export {getActiveFormType, getCardOnFile, getBillingAddress}
export default paymentDetailSlice.reducer
