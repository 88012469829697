import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit'
import type {TypedStartListening} from '@reduxjs/toolkit'
import {
  invalidateBothHoldings,
  setInitialHoldingsLoad,
  updateActivePortfolioSummary,
  updateActivePortfolioUuid,
} from '~app/(main)/my-stocks/my-stocks.slice'
import {
  createTransactions,
  deleteHoldingFromPortfolios,
  deleteMyStocksTransaction,
  fetchHoldings,
  fetchWatchlistHoldings,
  getHoldings,
  getMyStocksPortfolios,
  getMyStocksTransactions,
  getWatchlistHoldings,
} from './thunks'
import {PortfolioUuidType} from '~types/my-stocks'
import {
  ALTERNATE_ACTIVE_PORTFOLIO_VIEW,
  MY_STOCK_PORTFOLIOSPAGE,
} from '~data/constants'
import {ApolloClient} from '@apollo/client'
import type {Session} from 'next-auth/types'
import {AppDispatch, RootState} from '~data/client/store'
import {selectIsMyStocksRoute} from './selectors'

type AppStartListening = TypedStartListening<
  RootState,
  AppDispatch,
  {apolloClient: ApolloClient<object>; session: Session | null}
>

export const setMyStocksListeners = (
  myStocksListenerMiddleware: ReturnType<typeof createListenerMiddleware>,
) => {
  const startAppListening =
    myStocksListenerMiddleware.startListening as AppStartListening

  // Whenever a portfolio has invalid holdings from any asset update the portfolio,
  // dispatch fetchHoldings to update
  startAppListening({
    actionCreator: invalidateBothHoldings,
    effect: async (
      action: {payload: PortfolioUuidType[]},
      listenerApi: {
        extra: {apolloClient: ApolloClient<object>; session: Session | null}
        dispatch: AppDispatch
      },
    ) => {
      const {apolloClient} = listenerApi.extra
      // Refetch Portfolio Holdings
      try {
        action.payload.forEach((portfolioUuid: PortfolioUuidType) => {
          // Fetch holdings for all non-empty portfolio uuids
          if (
            portfolioUuid !== '' &&
            portfolioUuid !== ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks
          ) {
            listenerApi.dispatch(fetchHoldings({portfolioUuid, apolloClient}))
          }
        })
      } catch (error) {
        console.error('Error fetching holdings', error)
      }

      // Also Refetch Watchlist Holdings
      try {
        action.payload.forEach((portfolioUuid) => {
          // Fetch watchlist holdings for all non-empty portfolio uuids
          if (
            portfolioUuid !== '' &&
            portfolioUuid !== ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks
          ) {
            listenerApi.dispatch(
              fetchWatchlistHoldings({portfolioUuid, apolloClient}),
            )
          }
        })
      } catch (error) {
        console.error('Error fetching holdings', error)
      }

      // Refetch Transactions all at once
      try {
        listenerApi.dispatch(
          getMyStocksTransactions({
            portfolioUuids: [
              ...action.payload.map((uuid) => uuid || ''),
              ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
            ],
            reset: true,
          }),
        )
        listenerApi.dispatch(
          fetchHoldings({
            portfolioUuid: ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
            apolloClient,
          }),
        )
        listenerApi.dispatch(
          fetchWatchlistHoldings({
            background: true,
            portfolioUuid: ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
            apolloClient,
          }),
        )
      } catch (error) {
        console.error('Error fetching transactions', error)
      }
    },
  })

  /**
   * Listen to change in active portfolio uuid action to fetch holdings and transactions
   */
  startAppListening({
    predicate: (action, currentState, previousState) => {
      return (
        // TODO: Link this string directly to the action type
        ((action.type === 'myStocks/updateActivePortfolioUuid') as boolean) &&
        currentState.myStocks.activePortfolioUuid !== null &&
        currentState.myStocks.activePortfolioUuid !==
          previousState.myStocks.activePortfolioUuid
      )
    },
    effect: async (_, listenerApi) => {
      const {apolloClient} = listenerApi.extra
      const {myStocks: myStocksState} = listenerApi.getState() as RootState
      const activePortfolioUuid = myStocksState.activePortfolioUuid
      // TODO: This shouldnt ever be true because of the predicate...??
      if (!activePortfolioUuid) return

      // Get accounts if it has not been fetched
      if (!myStocksState.portfolios) {
        listenerApi.dispatch(getMyStocksPortfolios({isInitial: true}))
      }

      //Need to fetch holdings for all portfolios to render summary and coverage sections
      listenerApi.dispatch(
        getHoldings({portfolioUuid: activePortfolioUuid, apolloClient}),
      )

      listenerApi.dispatch(
        getWatchlistHoldings({
          portfolioUuid: activePortfolioUuid,
          apolloClient,
        }),
      )

      // TODO: Probably don't need these intials anymore, cleanup later
      listenerApi.dispatch(setInitialHoldingsLoad({holdingsStatus: true}))

      // TODO: Making assumptions that summary is definitely set, might need to break up
      // Update to the correct portfolio summary
      listenerApi.dispatch(
        updateActivePortfolioSummary({
          activePortfolioSummary:
            myStocksState.portfolioSummaryLookup[activePortfolioUuid],
        }),
      )

      // Fetch transactions
      listenerApi.dispatch(
        getMyStocksTransactions({
          portfolioUuids: [activePortfolioUuid || ''],
          page: 1,
        }),
      )
    },
  })

  /**
   * Listen to route change to update active portfolio uuid
   */
  startAppListening({
    predicate: (_, currentState, previousState) => {
      return (
        selectIsMyStocksRoute(currentState) &&
        currentState.route.currentRoute !== previousState.route.currentRoute
      )
    },
    effect: async (
      action,
      listenerApi: {
        extra: {apolloClient: ApolloClient<object>; session: Session | null}
        dispatch: AppDispatch
        getState: () => RootState
      },
    ) => {
      const state = listenerApi.getState() as RootState
      const currentRoute = state.route.currentRoute
      const params = new URLSearchParams(currentRoute.split('?')[1])
      const currentUuid = params.get('uuid')

      if (currentUuid) {
        // If uuid in param, set active portfolio uuid
        listenerApi.dispatch(
          updateActivePortfolioUuid({portfolioUuid: currentUuid}),
        )
      } else {
        // If no uuid, set active portfolio to all stocks
        // Set active uuid to All Stocks
        // TODO: Phase out specifying All Stocks as a portfolio uuid
        // since that is technically an invalid uuid
        if (currentRoute.includes(MY_STOCK_PORTFOLIOSPAGE)) {
          listenerApi.dispatch(
            updateActivePortfolioUuid({
              portfolioUuid: ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllPortfolios,
            }),
          )
        } else {
          listenerApi.dispatch(
            updateActivePortfolioUuid({
              portfolioUuid: ALTERNATE_ACTIVE_PORTFOLIO_VIEW.AllStocks,
            }),
          )
        }
      }
    },
  })

  // Detect change in number of transactions in a portfolio to refetch Portfolios
  // This is to keep the holdings column up-to-date in the All Portfolios view
  startAppListening({
    matcher: isAnyOf(
      createTransactions.fulfilled,
      deleteMyStocksTransaction.fulfilled,
      deleteHoldingFromPortfolios.fulfilled,
    ),
    effect: async (action, {dispatch}) => {
      dispatch(getMyStocksPortfolios({isInitial: false}))
    },
  })

  // Listen for when the `fetchMoreTransactions` is set to true in the state
  // This means the user clicked the 'Show More' button and additional transactions need to be fetched
  startAppListening({
    predicate: (_, currentState, previousState) => {
      return (
        currentState.myStocks.fetchMoreTransactions === true &&
        currentState.myStocks.fetchMoreTransactions !==
          previousState.myStocks.fetchMoreTransactions
      )
    },
    effect: async (
      _,
      listenerApi: {
        extra: {
          apolloClient: ApolloClient<object>
          session: Session | null
        }
        dispatch: AppDispatch
        getState: () => RootState
      },
    ) => {
      const {myStocks: myStocksState} = listenerApi.getState() as RootState
      const activeUuid = myStocksState.activePortfolioUuid
      const pageNumber = activeUuid
        ? myStocksState.transactionsLookup?.[activeUuid].currentPage + 1
        : 1
      listenerApi.dispatch(
        getMyStocksTransactions({
          token: listenerApi.extra?.session?.accessToken,
          portfolioUuids: [activeUuid || ''],
          page: pageNumber,
          loadMoreTransactions: true,
        }),
      )
    },
  })

  return myStocksListenerMiddleware
}
