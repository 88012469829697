import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {Rank} from '~types/rankings'
import {RankedStock, StockRanking} from '~types/__generated__/graphql'

const transformData = (data: StockRanking | undefined) => {
  let rankings: Rank[] = []
  if (data && data.rankedStocks && data?.rankedStocks.length) {
    rankings = data.rankedStocks.reduce(
      (acc: Rank[] = [], row: RankedStock) => {
        const yoyFreeCashFlow = row.instrument?.quoteFundamentals?.annual
          ?.freeCashFlowYield?.yearOverYear
          ? row.instrument?.quoteFundamentals?.annual?.freeCashFlowYield
              ?.yearOverYear[0]?.value
          : null
        const ranking: Rank = {
          id: row.instrumentId,
          instrumentId: row.instrumentId,
          rank: row.currentRank.value,
          company: row.instrument.name,
          symbol: row.instrument.symbol,
          exchange: row.instrument.exchange,
          dividendYield: row.instrument.quote?.dividendYield ?? null,
          searchField: `${row.instrument.symbol} ${row.instrument.name}`,
          sector: row.instrument.sector,
          currentPrice: row.instrument.quote?.currentPrice,
          closePrice: row.instrument.quote?.closePrice?.amount,
          dailyChange: row.instrument.quote?.percentChange ?? undefined,
          latestRec: row.latestRecommendation?.publishAt,
          revenueGrowth: row.instrument.quote?.revenueGrowth,
          beta: row.instrument.quote?.beta5y,
          peRatio: row.instrument.quote?.peRatio,
          grossMargin: row.instrument.quote?.grossMargin,
          yoyFreeCashFlow: yoyFreeCashFlow ? parseFloat(yoyFreeCashFlow) : null,
          marketCap: row.instrument.quote?.marketCap?.amount,
          oneYearReturn:
            row.instrument?.quoteHistoricalSummary?.oneYearReturnPercent,
          fiveYearReturn:
            row.instrument?.quoteHistoricalSummary?.fiveYearReturnPercent,
          sinceIpo:
            row.instrument?.quoteHistoricalSummary?.sinceIPOReturnPercent,
          cmaTagLabel:
            row.instrument?.predictionByInstrument?.cmaTagLabel ?? null,
          totalRecs: row.instrument?.accessibleFoolRecommendations,
          quant5Y: row.instrument?.quantScore?.value || undefined,
          quantRank: row.instrument?.quantScore?.rank || undefined,
        }
        acc.push(ranking)
        return acc
      },
      [] as Rank[],
    )
  }
  return rankings
}

const transformAsOfDate = (data: StockRanking | undefined) => {
  return data?.asOfDate
}

const initialState: {
  rankings: Rank[]
  asOfDate: string | undefined
} = {
  rankings: [],
  asOfDate: undefined,
}

const rankingsSlice = createSlice({
  name: 'rankings',
  initialState,
  reducers: {
    transform: (state, action: PayloadAction<StockRanking>) => {
      state.rankings = transformData(action.payload)
      state.asOfDate = transformAsOfDate(action.payload)
    },
    setRankings: (state, action: PayloadAction<Rank[]>) => {
      state.rankings = action.payload
      state.asOfDate = action.payload?.find(
        ({lastUpdated}) => lastUpdated,
      )?.lastUpdated
    },
  },
})

export const {transform, setRankings} = rankingsSlice.actions

export default rankingsSlice.reducer
