'use client'
import classNames from 'classnames'
import {usePathname} from 'next/navigation'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getSiteBanner} from '~data/client/braze.slice'
import {useBraze} from '~hooks/use-braze'

function currentPathMatchesPathRegex(
  pathName: string,
  pagePathRegex?: string,
): boolean {
  if (!pagePathRegex) {
    return true
  }

  const regex = new RegExp(pagePathRegex)
  return regex.exec(pathName) !== null
}

export default function SiteBanner() {
  const brazeClient = useBraze()
  const siteBannerData = useSelector(getSiteBanner)
  const pathName = usePathname()

  useEffect(() => {
    if (brazeClient && siteBannerData?.id) {
      brazeClient.logContentCardImpression([siteBannerData.id])
    }
  }, [brazeClient, siteBannerData])

  const siteBanner = useMemo(() => {
    if (!siteBannerData) {
      return null
    }

    if (!currentPathMatchesPathRegex(pathName, siteBannerData.pagePathRegex)) {
      return null
    }

    return (
      <div
        id="braze-cc-site-banner"
        className={classNames(
          siteBannerData.backgroundColorClass || 'bg-brand-blue',
          siteBannerData.textColorClass || 'text-primary-0',
          'py-2 text-center font-medium',
        )}
      >
        {siteBannerData.linkUrl && (
          <a
            href={siteBannerData.linkUrl}
            target={siteBannerData.linkTarget || '_blank'}
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation()
              brazeClient?.logContentCardClick(siteBannerData.id)
            }}
          >
            {siteBannerData.message}
          </a>
        )}
        {!siteBannerData.linkUrl && siteBannerData.message}
      </div>
    )
  }, [siteBannerData, pathName])

  return siteBanner
}
