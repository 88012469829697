import {createSelector, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import type {ClassicCard} from '@braze/web-sdk'

export enum ContentCardExtrasField {
  BackgroundColor = 'background-color',
  Icon = 'icon',
  LinkTarget = 'link-target',
  PagePathRegex = 'page-path-regex',
  Site = 'site',
  TextColor = 'text-color',
  Type = 'type',
}

enum ContentCardType {
  HomepageBanner = 'homepage-banner',
  HomepageFulfillment = 'homepage-fulfillment-block',
  HomepageOnboarding = 'homepage-onboarding-cards',
  HomepageSpecialCoverage = 'homepage-special-coverage-block',
  MyStocksUpdates = 'mystocks-updates',
  SiteBanner = 'sitewide-banner',
}

export type BrazeContentCard = Pick<
  ClassicCard,
  | 'id'
  | 'viewed'
  | 'title'
  | 'description'
  | 'url'
  | 'linkText'
  | 'extras'
  | 'pinned'
  | 'dismissible'
  | 'clicked'
> & {created: string | undefined}

export type OnboardingCardData = {
  id: string
  clicked: boolean
  linkTarget?: string
  linkUrl: string
  message: string
  title?: string
}

export type SiteBannerData = {
  id: string | undefined
  message: string
  backgroundColorClass?: string
  linkTarget?: string
  linkUrl?: string
  pagePathRegex?: string
  textColorClass?: string
}

export type BrazeState = {
  contentCards: BrazeContentCard[]
}

const initialState: BrazeState = {
  contentCards: [],
}

const brazeSlice = createSlice({
  name: 'braze',
  initialState,
  reducers: {
    logContentCardClick: (state, action: PayloadAction<string>) => {
      state.contentCards = state.contentCards.map((card) =>
        card.id === action.payload ? {...card, clicked: true} : card,
      )
    },
    setContentCards: (state, action: PayloadAction<BrazeContentCard[]>) => {
      state.contentCards = action.payload
    },
  },
})

const getOnboardingCards = createSelector(
  (state: RootState) => state.braze.contentCards,
  (cards: BrazeContentCard[]): OnboardingCardData[] =>
    cards
      .filter(
        ({id, url, extras}) =>
          extras[ContentCardExtrasField.Type] ===
            ContentCardType.HomepageOnboarding &&
          id &&
          url,
      )
      .map(({id, description, clicked, extras, title, url}) => ({
        id: id!,
        clicked,
        linkTarget: extras[ContentCardExtrasField.LinkTarget],
        linkUrl: url!,
        message: description,
        title,
      })),
)

const getSiteBanner = createSelector(
  (state: RootState) => state.braze.contentCards,
  (cards: BrazeContentCard[]): SiteBannerData | null => {
    const card = cards.filter(
      ({extras}) =>
        extras[ContentCardExtrasField.Type] === ContentCardType.SiteBanner,
    )?.[0]

    if (!card) {
      return null
    }

    return {
      id: card.id,
      message: card.description,
      linkTarget: card.extras[ContentCardExtrasField.LinkTarget],
      linkUrl: card.url,
      pagePathRegex: card.extras[ContentCardExtrasField.PagePathRegex],
      backgroundColorClass: card.extras[ContentCardExtrasField.BackgroundColor],
      textColorClass: card.extras[ContentCardExtrasField.TextColor],
    }
  },
)

export {getOnboardingCards, getSiteBanner}
export const {logContentCardClick, setContentCards} = brazeSlice.actions
export default brazeSlice.reducer
