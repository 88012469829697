import RankingsHeader from '~components/layout/rankings-header.tsx'
import VaultHeader from '~components/layout/vault-header'
import type {PageDataObject} from '~data/types/page'
import GameplanHeader from '~components/layout/gameplan-header'

const pageData: PageDataObject = {
  '/': {
    layoutData: {
      headerTitle: 'Dashboard',
      showTickerTape: true,
      enableRightBar: false,
    },
    meta: {
      title: 'Dashboard',
    },
  },
  '/account(.*)': {
    layoutData: {
      headerTitle: 'Account',
      enableRightBar: false,
    },
    meta: {
      title: 'Account',
    },
  },
  '/new-recs': {
    layoutData: {
      headerTitle: 'New Recs',
      enableRightBar: false,
    },
    meta: {
      title: 'New Recs',
    },
  },
  '/rankings': {
    layoutData: {
      headerTitle: 'Rankings',
      HeaderComponent: RankingsHeader,
      enableRightBar: false,
    },
    meta: {
      title: 'Rankings',
    },
  },
  '/my-stocks(/.*)?': {
    layoutData: {
      headerTitle: 'My Stocks',
      enableRightBar: false,
    },
    meta: {
      title: 'My Stocks',
    },
  },
  '/news-and-analysis/media': {
    layoutData: {
      headerTitle: 'Coverage',
      enableRightBar: false,
    },
    meta: {
      title: 'Coverage',
    },
  },
  '/ai-summit': {
    layoutData: {
      enableRightBar: true,
      headerTitle: 'AI Summit',
    },
  },
  '/ai-summit/1516': {
    layoutData: {
      enableRightBar: true,
      headerTitle: 'AI Summit',
    },
  },
  '/news-and-analysis(/.*)?': {
    layoutData: {
      headerTitle: 'Coverage',
    },
    meta: {
      title: 'Coverage',
    },
  },
  '/investor-profile(/.*)?': {
    layoutData: {
      headerTitle: null,
      enableRightBar: false,
    },
    meta: {
      title: 'Investor Profile',
    },
  },
  '/my-services/scorecards/all-services': {
    layoutData: {
      headerTitle: 'Scorecards',
      enableRightBar: false,
      showTickerTape: false,
    },
    meta: {
      title: 'Scorecards',
    },
  },
  '/my-services/scorecards/vault': {
    layoutData: {
      headerTitle: 'Scorecards',
      enableRightBar: false,
      showTickerTape: false,
    },
    meta: {
      title: 'Scorecards',
    },
  },
  '/my-services/vault(/.*)?': {
    layoutData: {
      headerTitle: 'Vault',
      HeaderComponent: VaultHeader,
      hasServiceMenu: false,
      enableRightBar: false,
      showTickerTape: false,
    },
    meta: {
      title: 'Vault',
    },
  },
  '/my-services/market-reports': {
    layoutData: {
      headerTitle: 'Market Reports',
      enableRightBar: false,
    },
    meta: {
      title: 'Market Reports',
    },
  },
  '/my-services/options(.*)': {
    layoutData: {
      hasServiceMenu: true,
      headerTitle: 'Options',
      enableRightBar: false,
    },
    meta: {
      title: 'Options',
    },
  },
  '/my-services(/.*)/about': {
    layoutData: {
      hasServiceMenu: true,
      enableRightBar: false,
      showTickerTape: false,
    },
    meta: {
      title: 'About',
    },
  },
  '/my-services(/.*)': {
    layoutData: {
      hasServiceMenu: true,
      enableRightBar: false,
      showTickerTape: false,
    },
    meta: {
      title: 'Scorecards',
    },
  },
  '/reports(/.*)?': {
    layoutData: {
      headerTitle: 'My Reports',
      enableRightBar: false,
    },
    meta: {
      title: 'Market Reports',
    },
  },
  '/strategy-tools/strategies': {
    layoutData: {
      headerTitle: 'Portfolio Strategies',
      enableRightBar: false,
    },
    meta: {
      title: 'Portfolio Strategies',
    },
  },
  '/strategy-tools/strategies/(cautious|moderate|aggressive)?': {
    layoutData: {
      hasStrategiesMenu: true,
      enableRightBar: false,
    },
    meta: {
      title: 'Portfolio Strategies',
    },
  },
  '/strategy-tools/strategies/(cautious|moderate|aggressive)/(.+)?': {
    layoutData: {
      hasStrategiesMenu: true,
      enableRightBar: true,
    },
    meta: {
      title: 'Portfolio Strategies',
    },
  },
  '/strategy-tools/indicators(/.*)?': {
    layoutData: {
      headerTitle: 'Indicators',
      enableRightBar: false,
    },
    meta: {
      title: 'Indicators',
    },
  },
  '/strategy-tools/simulators': {
    layoutData: {
      headerTitle: 'Simulators',
      enableRightBar: false,
    },
    meta: {
      title: 'Simulators',
    },
  },
  '/contact': {
    layoutData: {
      headerTitle: 'Contact Us',
      enableRightBar: false,
    },
    meta: {
      title: 'Contact Us',
    },
  },
  '/company(/.*)?': {
    layoutData: {
      headerTitle: '',
    },
    // Metadata is set on company's main page.tsx file
  },
  '/coverage/investing(/.*)?': {
    // TODO: may be incorrect, but leaving it as is
    // free article
    layoutData: {
      enableRightBar: false,
    },
  },
  '/investing/(.*)?': {
    // correct
    // free article
    layoutData: {
      enableRightBar: false,
    },
  },
  '/coverage/retirement(/.*)?': {
    // TODO: may be incorrect, but leaving it as is
    // free article
    layoutData: {
      enableRightBar: false,
    },
  },
  '/retirement/(.*)?': {
    // correct
    // free article
    layoutData: {
      enableRightBar: false,
    },
  },
  '/earnings/(.*)?': {
    // /earnings/call-transcripts/ free article
    layoutData: {
      enableRightBar: false,
    },
  },
  '/search': {
    layoutData: {
      headerTitle: 'Search Results',
      enableRightBar: false,
    },
  },
  '/gameplan(/.*)?': {
    layoutData: {
      headerTitle: 'GamePlan',
      HeaderComponent: GameplanHeader,
      enableRightBar: false,
    },
    meta: {
      title: 'GamePlan',
    },
  },
  '/stock-advisor/latest-rec(/.*)?': {
    layoutData: {
      enableRightBar: false,
    },
  },
  '/rule-breakers/latest-rec(/.*)?': {
    layoutData: {
      enableRightBar: false,
    },
  },
}

export default pageData
