'use client'
import {
  getUserPreference,
  postUserPreference,
} from '~data/api/user-preference.ts'
import {Product} from '~types/__generated__/graphql'
import {SecondaryNavItem} from './main-navigation'
import classNames from 'classnames'
import {StarIcon} from '@fool/jester-ui/Icon'
import {useSession} from 'next-auth/react'
import {RootState} from '~data/client/store'
import {useDispatch, useSelector} from 'react-redux'
import {updateStarredProducts} from '~data/client/navigation.slice'

const STARRED_PRODUCTS_PREF_KEY = 'starred-products'

export const getStarredProducts = async (token: string) => {
  return getUserPreference<string[]>(STARRED_PRODUCTS_PREF_KEY, token)
}

export const setStarredProducts = async (products: string[], token: string) => {
  return postUserPreference(
    STARRED_PRODUCTS_PREF_KEY,
    JSON.stringify([...products]),
    token,
  )
}

export const getMappedNavProducts = async (
  productPortfolios: Partial<Product>[],
  token: string,
  starredProducts: string[],
) => {
  const nonVaultedProductPortfolios = productPortfolios.filter(
    (productPortfolio) => {
      return (
        productPortfolio?.portfolios?.some((portfolio) => !portfolio.vaulted) ||
        productPortfolio.productId === 1321
      )
    },
  )

  const seenSlugs = new Set<string>()

  return nonVaultedProductPortfolios
    .map((product) => {
      if (
        product.portfolios?.length &&
        product.portfolios?.length > 1 &&
        product.slug !== 'stock-advisor'
      ) {
        return {
          name: product.shortName as string,
          slug: product.slug,
          children: product.portfolios.map((portfolio) => {
            if (portfolio.vaulted) return
            return {
              name: portfolio.displayName,
              href: `/my-services/${portfolio.slug}`,
            }
          }),
          starred: product.slug
            ? starredProducts.includes(product.slug)
            : undefined,
        }
      } else if (
        product.portfolios?.length === 1 &&
        !product.portfolios[0].vaulted
      ) {
        const portfolio = product.portfolios[0]
        return {
          name: portfolio.displayName,
          href: `/my-services/${portfolio.slug}`,
          slug: portfolio.slug,
          //Needed to fix cases where product slug doesn't match portfolio slug
          starred: portfolio.slug
            ? starredProducts.includes(portfolio.slug)
            : undefined,
        }
      } else {
        return {
          name: product.shortName as string,
          href: `/my-services/${product.slug}`,
          slug: product.slug,
          starred: product.slug
            ? starredProducts.includes(product.slug)
            : undefined,
        }
      }
    })
    .filter((item) => {
      // Prevent duplicates
      if (!item?.slug || seenSlugs.has(item.slug)) {
        return false
      }
      seenSlugs.add(item.slug)
      return true
    })
    .sort((a, b) => {
      if (a.starred !== b.starred) {
        return a.starred && !b.starred ? -1 : 1
      } else {
        return a.name.localeCompare(b.name)
      }
    })
}

export const toggleStarredProduct = (
  starredProducts?: string[],
  productSlug?: string,
) => {
  if (!productSlug) return starredProducts || []
  const starred = new Set(starredProducts)
  if (starred.has(productSlug)) {
    starred.delete(productSlug)
  } else {
    starred.add(productSlug)
  }

  return [...starred]
}

export const StarButton = ({
  item,
  className,
  onStarClick,
}: {
  item: SecondaryNavItem
  className?: string
  onStarClick?: () => void
}) => {
  const session = useSession()
  const starredProducts = useSelector(
    (state: RootState) => state.navigation.starredProducts,
  )
  const dispatch = useDispatch()
  return (
    item.slug && (
      <button
        className={classNames(
          'flex [&_path]:stroke-2',
          className,
          !item.starred && '[&_path]:fill-none',
        )}
        onClick={(e) => {
          e.stopPropagation()
          if (item.slug) {
            const starred = toggleStarredProduct(starredProducts, item.slug)
            dispatch(updateStarredProducts({starredProducts: starred}))
            if (session.data?.accessToken) {
              setStarredProducts(starred, session.data.accessToken)
            }
            onStarClick?.()
          }
        }}
      >
        <StarIcon />
      </button>
    )
  )
}
