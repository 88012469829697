import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {Product, UserQuery} from '~types/__generated__/graphql'
import {getNestedProducts} from '~components/scorecards/lib/accessible-products-portfolios/helpers'

type StockSymbol = string

export type UserType = {
  uid: string
  uuid: string
  email: string
  level?: number | null
  accessibleProductIds: number[]
  products: Partial<Product>[]
  nestedProducts: Partial<Product>[]
  isStaff?: boolean
  recentlyViewedStocks: {
    [key: StockSymbol]: {name: string; exchange: string; instrumentId: number}
  }
}

const initialState: UserType = {
  uid: '',
  uuid: '',
  email: '',
  level: 0,
  accessibleProductIds: [],
  isStaff: false,
  recentlyViewedStocks: {},
  products: [],
  nestedProducts: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserFromGQL: (state, action: PayloadAction<UserQuery['user']>) => {
      if (action.payload && action.payload.products) {
        state.uid = String(action.payload.uid)
        state.uuid = action.payload.uuid
        state.email = action.payload.email
        state.level = action.payload.level || 0
        state.accessibleProductIds = action.payload.accessibleProductIds
        state.products = action.payload.products
        state.nestedProducts = getNestedProducts(action.payload.products)
      }
    },
    setRecentlyViewedStocks: (
      state,
      action: PayloadAction<{
        stock: {
          symbol: string
          name: string
          exchange: string
          instrumentId: number
        }
      }>,
    ) => {
      state.recentlyViewedStocks = {
        ...state.recentlyViewedStocks,
        [action.payload.stock.symbol]: {
          name: action.payload.stock.name,
          exchange: action.payload.stock.exchange,
          instrumentId: action.payload.stock.instrumentId,
        },
      }
    },
  },
})

export const {setUserFromGQL, setRecentlyViewedStocks} = userSlice.actions
export default userSlice.reducer
