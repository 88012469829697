'use client'
import {Transition} from '@headlessui/react'
import {usePathname} from 'next/navigation'
import {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
  isValidElement,
} from 'react'
import {createPortal} from 'react-dom'
import {
  MainNavItem,
  SecondaryNavItem,
  secondaryClasses,
} from '../main-navigation'
import {StarButton} from '../product-prefs'
import TertiaryNav from './tertiary-nav'
import SecondaryNavLink from './secondary-nav-link'
import {PrimaryNavLink} from './primary-nav-link'
import {cn} from '~components/lib/utils'
import {useSidebar} from '~components/ui/sidebar'
import Heading from '@fool/jester-ui/Heading'

export default function SecondaryNav({
  item,
  isPending,
  onNavigate,
}: {
  item: MainNavItem
  isPending: boolean
  onNavigate: (href: string) => void
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [tertiaryOpen, setTertiaryOpen] = useState<Set<string>>(new Set())
  const [secondaryNav, setSecondaryNav] = useState<HTMLDivElement | null>(null)
  const [bodyElement, setBodyElement] = useState<HTMLElement | null>(null)
  const pathname = usePathname()
  const prevPathname = useRef(pathname)
  const [subNavRef, setSubNavRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    setBodyElement(document.body)
  }, [])

  useEffect(() => {
    if (!isOpen || !subNavRef) return
    const shiftNav = () => {
      if (subNavRef && secondaryNav) {
        const secondaryNavRect = secondaryNav.getBoundingClientRect()
        // First set the initial position
        subNavRef.style.top = `${
          secondaryNavRect.top + secondaryNavRect.height / 2
        }px`

        // Wait for the browser to apply the position
        requestAnimationFrame(() => {
          const rect = subNavRef.getBoundingClientRect()
          if (rect.top < 0) {
            subNavRef.style.marginTop = `${rect.top * -1}px`
          } else if (rect.bottom > window.innerHeight) {
            subNavRef.style.marginTop = `${window.innerHeight - rect.bottom}px`
          }
        })
      }
    }

    requestAnimationFrame(() => shiftNav())
  }, [subNavRef, isOpen])

  const handleNavigate = useCallback(
    (href: string) => {
      requestAnimationFrame(() => {
        setIsOpen(false)
        setTertiaryOpen(new Set())
        onNavigate(href)
      })
    },
    [onNavigate],
  )

  useEffect(() => {
    if (prevPathname.current === pathname) {
      return
    }
    prevPathname.current = pathname
    setTertiaryOpen(new Set())
    setIsOpen(false)
  }, [pathname])

  const isHovering = useCallback(() => {
    // For tertiary items, keep the full hover check
    if (tertiaryOpen.size > 0) {
      const hover = secondaryNav?.matches(':hover')
      const focus = secondaryNav?.contains(document.activeElement)
      const subNavHover = subNavRef?.matches(':hover')
      const subNavFocus = subNavRef?.contains(document.activeElement)
      const secondaryItemHover = Array.from(
        secondaryNav?.querySelectorAll('[data-secondary-nav-item]') || [],
      ).some(
        (item) =>
          item.matches(':hover') || item.contains(document.activeElement),
      )
      const tertiaryHover = Array.from(
        document.querySelectorAll('[data-tertiary-popper]'),
      ).some(
        (popper) =>
          popper.matches(':hover') || popper.contains(document.activeElement),
      )

      return (
        hover ||
        focus ||
        subNavHover ||
        subNavFocus ||
        secondaryItemHover ||
        tertiaryHover
      )
    }

    // For regular items, only check if mouse is actually over the nav
    return secondaryNav?.matches(':hover') || false
  }, [secondaryNav, subNavRef, tertiaryOpen])

  const sidebar = useSidebar()
  let timeoutForHoverCheck: NodeJS.Timeout | null = null

  return (
    <div
      ref={setSecondaryNav}
      className="grid relative isolation-auto"
      onMouseLeave={() => {
        if (timeoutForHoverCheck) {
          clearTimeout(timeoutForHoverCheck)
        }
        timeoutForHoverCheck = setTimeout(() => {
          if (!isPending && !tertiaryOpen.size && !isHovering()) {
            setIsOpen(false)
          }
        }, 150)
      }}
    >
      <PrimaryNavLink
        item={item}
        pathname={pathname}
        isPending={isPending}
        isHovered={isOpen}
        onNavigate={handleNavigate}
        onMouseOver={() => setIsOpen(true)}
      />
      <Transition.Root show={isOpen || tertiaryOpen.size > 0} as={Fragment}>
        <div
          ref={setSubNavRef}
          className={cn(
            'fixed -translate-y-1/2 top-1/2 w-[18rem] z-10',
            sidebar.open
              ? 'left-[var(--sidebar-width)]'
              : 'left-[var(--sidebar-width-icon)]',
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`relative flex ${
                item.name === 'My Services' ? '' : ''
              } flex-col scrollbar-nav overflow-y-auto bg-secondary-nav max-h-screen p-16px text-primary-0 shadow-large`}
            >
              {sidebar.state === 'collapsed' && (
                <Heading
                  as="h3"
                  className="text-primary-0 pb-3 border-b border-content-border mb-2"
                >
                  {item.name}
                </Heading>
              )}
              {(item.children || []).map((secondary) => {
                if (isValidElement(secondary)) {
                  return secondary
                }
                secondary = secondary as SecondaryNavItem
                if (secondary?.children) {
                  return (
                    <TertiaryNav
                      item={secondary}
                      key={secondary.name}
                      setTertiaryOpen={(open) => {
                        open
                          ? setTertiaryOpen((previousState) => {
                              previousState.add(
                                (secondary as SecondaryNavItem).name,
                              )
                              return new Set(previousState)
                            })
                          : setTertiaryOpen((previousState) => {
                              previousState.delete(
                                (secondary as SecondaryNavItem).name,
                              )
                              return new Set(previousState)
                            })
                      }}
                      setSecondaryOpen={() => {
                        if (!isHovering()) {
                          setIsOpen(false)
                        }
                      }}
                      onNavigate={handleNavigate}
                    />
                  )
                } else {
                  return secondary.href ? (
                    <div
                      className={cn(
                        'flex items-center h-[40px] gap-x-[8px]',
                        secondaryClasses,
                      )}
                      key={secondary.name}
                      data-secondary-nav-item
                    >
                      <StarButton item={secondary} className="relative z-30" />
                      <SecondaryNavLink
                        item={secondary}
                        isPending={isPending}
                        onNavigate={handleNavigate}
                        className="flex-1 pl-0"
                      >
                        {secondary.name} {secondary.icon}
                      </SecondaryNavLink>
                    </div>
                  ) : secondary.onClick ? (
                    <div
                      className={cn(
                        'flex items-center h-[40px] gap-x-[8px]',
                        secondaryClasses,
                      )}
                      key={secondary.name}
                      data-secondary-nav-item
                    >
                      {typeof secondary.starred === 'boolean' && (
                        <StarButton
                          item={secondary}
                          className="relative z-30"
                        />
                      )}
                      <a onClick={secondary.onClick} className="flex-1 pl-0">
                        {secondary.name} {secondary.icon}
                      </a>
                    </div>
                  ) : null
                }
              })}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
      {bodyElement &&
        !isPending &&
        createPortal(
          <div
            aria-hidden="true"
            className={cn(
              'fixed inset-0 w-screen h-screen tertiary-nav-mask bg-black transition-opacity duration-300 ease-in-out',
              tertiaryOpen.size > 0 ? 'opacity-60' : 'opacity-0',
            )}
            style={{pointerEvents: 'none', left: 'var(--sidebar-width-full)'}}
          />,
          bodyElement,
        )}
    </div>
  )
}
