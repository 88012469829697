import {clsx, type ClassValue} from 'clsx'
import {twJoin} from 'tailwind-merge'
import type {Row} from '@tanstack/react-table'

export function cn(...inputs: ClassValue[]) {
  return twJoin(clsx(inputs))
}

export function joinWithCommaEndingWithAnd(values: string[]) {
  if (values.length === 0) return ''
  if (values.length === 1) return values[0]
  const last = values[values.length - 1]
  const initial = values.slice(0, -1)
  return `${initial.join(', ')}, and ${last}`
}

export function filterArchivedAndVaultedProducts<T>(products: T): T {
  return (
    products as Array<{archived: boolean; portfolios: {vaulted: boolean}[]}>
  )?.filter(
    (product) =>
      !product?.archived &&
      !product?.portfolios.some((portfolio) => portfolio?.vaulted),
  ) as T
}

export const createSortingFn = <T extends object>(field: keyof T) => {
  return (rowA: Row<unknown>, rowB: Row<unknown>): number => {
    const valueA = (rowA.original as T)[field] ?? 0
    const valueB = (rowB.original as T)[field] ?? 0
    if (valueA < valueB) return -1
    if (valueA > valueB) return 1
    return 0
  }
}
