import {Session} from 'next-auth/types'
import {FoolSession} from '~auth/options'
import {L1_GAMEPLAN_PRODUCTS, L2_PLUS_GAMEPLAN_PRODUCTS} from '~data/constants'

export type UserTier = {
  tier: number
  title: string
  name: string // consoldate to one once ''Stock Advisor' vs 'Premium' is resolved
  hasQuant5YAccess: boolean
  hasAISummaryAccess: boolean
  hasMultipleScorecards: boolean
  hasDynamicStrategies: boolean
  hasOptionsAccess: boolean | ((session: FoolSession) => boolean)
  //mustVerifyAccessibleStockForQuant: boolean
  gamePlanProducts: number[]
}

type getUserTierProps = {
  session: Session | null | undefined
}

function checkForOptionsService(session: FoolSession): boolean {
  return (
    !!session &&
    !!session.accessibleProductIds &&
    !!session.accessibleProductIds.includes(1321)
  )
}

function processTier(tier: UserTier, session: FoolSession | undefined | null) {
  if (!session) {
    return tier
  }
  function updateTier<Key extends keyof UserTier>(
    key: Key,
    value: UserTier[Key],
  ) {
    tier[key] = value
  }
  for (const featureKey in tier) {
    const featureValue = tier[featureKey as keyof UserTier]
    if (typeof featureValue === 'function') {
      const processedValue = featureValue(session)
      // Update tier function with the returned value
      updateTier(featureKey as keyof UserTier, processedValue)
    }
  }
  return tier
}

export function getUserTier({session}: getUserTierProps): UserTier {
  const userTier = session ? session.membershipLevel : 0
  if (userTier === 5) {
    return processTier(tier5, session)
  }
  if (userTier === 4) {
    return processTier(tier4, session)
  }
  if (userTier === 3) {
    return processTier(tier3, session)
  }
  if (userTier === 2) {
    return processTier(tier2, session)
  }
  if (userTier === 1) {
    return processTier(tier1, session)
  }
  return invalidTier
}

export const getTierFromLevel = (level: number): number => {
  // explicit checks in case we need to add additional logic.
  if (level === 5) {
    return 5
  }
  if (level === 4) {
    // confirm 3 or 4
    return 3
  }
  if (level === 3) {
    return 2
  }
  if (level === 2) {
    return 1
  }
  return 0
}

export const invalidTier: UserTier = {
  tier: 0,
  title: 'Fool',
  name: 'Fool',
  hasQuant5YAccess: false,
  hasAISummaryAccess: false,
  hasMultipleScorecards: false,
  hasDynamicStrategies: false,
  hasOptionsAccess: false,
  gamePlanProducts: [],
}

export const tier1: UserTier = {
  tier: 1,
  title: 'Premium',
  name: 'Stock Advisor',
  hasQuant5YAccess: false,
  hasAISummaryAccess: false,
  hasMultipleScorecards: false,
  hasDynamicStrategies: false,
  hasOptionsAccess: checkForOptionsService,
  gamePlanProducts: L1_GAMEPLAN_PRODUCTS,
}

export const tier2: UserTier = {
  tier: 2,
  title: 'Epic',
  name: 'Epic',
  hasQuant5YAccess: true,
  hasAISummaryAccess: true,
  hasMultipleScorecards: true,
  hasDynamicStrategies: true,
  hasOptionsAccess: checkForOptionsService,
  gamePlanProducts: L2_PLUS_GAMEPLAN_PRODUCTS,
}

export const tier3: UserTier = {
  tier: 3,
  title: 'Epic Plus',
  name: 'Epic Plus',
  hasQuant5YAccess: true,
  hasAISummaryAccess: true,
  hasMultipleScorecards: true,
  hasDynamicStrategies: true,
  hasOptionsAccess: checkForOptionsService,
  gamePlanProducts: L2_PLUS_GAMEPLAN_PRODUCTS,
}

export const tier4: UserTier = {
  tier: 4,
  title: 'Fool Portfolios',
  name: 'Fool Portfolios',
  hasQuant5YAccess: true,
  hasAISummaryAccess: true,
  hasMultipleScorecards: true,
  hasDynamicStrategies: true,
  hasOptionsAccess: checkForOptionsService,
  gamePlanProducts: L2_PLUS_GAMEPLAN_PRODUCTS,
}

export const tier5: UserTier = {
  tier: 5,
  title: 'One',
  name: 'Fool One',
  hasQuant5YAccess: true,
  hasAISummaryAccess: true,
  hasMultipleScorecards: true,
  hasDynamicStrategies: true,
  hasOptionsAccess: checkForOptionsService,
  gamePlanProducts: L2_PLUS_GAMEPLAN_PRODUCTS,
}

export const accessibleUserTiers = [tier1, tier2, tier3, tier4, tier5].map(
  (item) => item.tier,
)
