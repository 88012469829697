import Heading from '@fool/jester-ui/Heading'
import Tooltip from '~components/tooltip/tooltip.tsx'
import {InfoIcon} from '@fool/jester-ui/Icon'
import {useSelector} from 'react-redux'

import {RootState} from '~data/client/store.ts'
import {abbreviatedNumberDate} from '~utils/formatters/dates.tsx'
import RankingsDisclaimer from 'src/app/(main)/rankings/rankings-disclaimer'

export default function RankingsHeader({headerTitle}: {headerTitle: string}) {
  const asOfDate = useSelector((state: RootState) => state.rankings.asOfDate)

  return (
    <div className="flex flex-col gap-4px">
      <Heading as="h1" looksLike="h2">
        {headerTitle}
        <Tooltip text={<RankingsDisclaimer />}>
          <span className="text-content-70 ml-4px">
            <InfoIcon size="sm" />
          </span>
        </Tooltip>
      </Heading>
      {!!asOfDate && (
        <div className="text-caption-bold text-content-70">
          Updated {abbreviatedNumberDate(asOfDate)}
        </div>
      )}
    </div>
  )
}
