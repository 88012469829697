'use client'

import DashboardIcon from '~components/nav-icons/dashboard.tsx'
import NewRecsIcon from '../../nav-icons/new-recs.tsx'
import RankingsIcon from '../../nav-icons/rankings.tsx'
import MyStocksIcon from '../../nav-icons/my-stocks.tsx'
import NewsAndAnalysisIcon from '../../nav-icons/news-and-analysis.tsx'
import MyServicesIcon from '../../nav-icons/my-services.tsx'
import StrategyToolsIcon from '../../nav-icons/strategy-tools.tsx'
import DiscussIcon from '../../nav-icons/discuss.tsx'
import classNames from 'classnames'
import {Link} from '~components/common/link'
import {UserTier} from '~data/api/tiers.ts'
import {Portfolio} from '~types/my-stocks.ts'
import {SyncedIndicator} from '~components/common/icon-synced-indicator.tsx'
import {Fragment, ReactNode, useCallback} from 'react'
import {Divider} from '../../divider.tsx'
import MyReportsIcon from '~components/nav-icons/my-reports.tsx'
import DatabasesIcon from '~components/nav-icons/databases.tsx'
import {Product} from '~types/__generated__/graphql.ts'
import GamePlanIcon from '~components/nav-icons/gameplan.tsx'
import VaultIcon from '~components/nav-icons/vault.tsx'
import {modalShowState} from '~data/client/modal.slice.ts'
import {updateActiveForm} from 'src/app/(main)/my-stocks/my-stocks.slice.ts'
import {MY_STOCKS_FORM_TYPE, MY_STOCKS_NAVITEM_ID} from '~data/constants'
import {AppDispatch} from '~data/client/store.ts'
import {MY_STOCK_DEFAULTPAGE} from '~data/constants.ts'
import {PlusIcon} from '@fool/jester-ui/Icon'
import Skeleton from 'react-loading-skeleton'
import {MY_STOCKS_SLUG} from '~data/constants.ts'
import Fool24Icon from '~components/nav-icons/fool-24.tsx'
import Fool24OnAirIndicator from './fool24-on-air-indicator'
import {setModalAfterNavigation} from '~data/client/modal.slice.ts'

export type NavItem = {
  id?: string
  name: string
  nameSkeleton?: ReactNode // Skeleton for loading state such as when fetching data for My Services
  href?: string
  newTab?: boolean
  icon?: React.ReactNode
  starred?: boolean
  slug?: string
  type?: string
  uuid?: string
  onClick?: () => void
  disablePrefetch?: boolean
}

export type DatabaseItem = {
  name: string
  href: string
  newTab: boolean
}

export interface MainNavItem extends NavItem {
  children?: (SecondaryNavItem | ReactNode)[]
}

export interface SecondaryNavItem extends NavItem {
  children?: NavItem[] | undefined
}

const recommendationsLink =
  '/news-and-analysis/articles?filter=%257B%2522tags%2522%253A%255B%2522buy-recommendation%252Cstarter-stocks%252Csell-recommendation%252Chold%252Cguidance-change%252Crecommendation%252Callocation%252Cdividend%252Cranking%252Cetf-rankings%252Cconservative-portfolio%252Caggressive-portfolio%252Cmoderate-portfolio%252Cselector%252Conboarding%252Clevel-rankings%2522%255D%257D'
const latestEarningsLink =
  '/news-and-analysis/articles?filter=%257B%2522tags%2522%253A%255B%2522earnings-transcript%252Cearnings%252Cfiling-summary%2522%255D%257D'
const marketNewsLink =
  '/news-and-analysis/articles?filter=%257B%2522tags%2522%253A%255B%2522analysis%252C%2520bull-and-bear%252C%25205-and-3%252C%2520mindset%252C%2520coverage%252C%2520commentary%252C%2520highlights%252C%2520live_chat%252C%2520deep-dive%252C%2520assets-watchlist%252C%2520livechat%252C%2520wwtn%252C%2520industry-snapshot%252C%2520probability-scenarios%252C%2520ai-report%252C%2520focus-areas%2522%252C%2522newsamatic%252Cfirst-movers%252Cgroupsamatic%252Cquick-news%252Cmidday-buzz%2522%252C%2522newsamatic%252Cbreakfast-news%252Cgroupsamatic%252Cquick-news%252Cmidday-buzz%252Clunchtime-news%252Cevening-news%2522%255D%257D'
const researchAnalysisLink =
  '/news-and-analysis/articles?filter=%257B%2522tags%2522%253A%255B%2522analysis%252C%2520bull-and-bear%252C%25205-and-3%252C%2520mindset%252C%2520coverage%252C%2520commentary%252C%2520highlights%252C%2520live_chat%252C%2520deep-dive%252C%2520assets-watchlist%252C%2520livechat%252C%2520wwtn%252C%2520industry-snapshot%252C%2520probability-scenarios%252C%2520ai-report%252C%2520focus-areas%2522%252C%2522analysis%252Cbull-and-bear%252C5-and-3%252Cmindset%252Ccoverage%252Ccommentary%252Chighlights%252Clive_chat%252Cdeep-dive%252Cassets-watchlist%252Clivechat%252Cwwtn%252Cindustry-snapshot%252Cprobability-scenarios%252Cai-report%252Cfocus-areas%2522%255D%257D'
const scoreboardLatestLink =
  '/news-and-analysis/media?filter=%257B%2522tags%2522%253A%255B%2522fool-live-motley-fool-debates%2522%255D%257D'
const scoreboardLeaderboardLink =
  '/4056/coverage/all-time-scoreboard-leaderboard'

const MONEYBALL_PRODUCT_ID = 4685
const MONEYBALL_NAV_ITEM = {
  name: 'Moneyball',
  href: 'https://stockdata.fool.com/moneyball',
  newTab: true,
} as DatabaseItem

const CRYPTOBALL_PRODUCT_ID = 4700 // Not a conventional service, but has a product ID for entitlement-check purposes.
const CRYPTOBALL_NAV_ITEM = {
  name: 'Cryptoball',
  href: 'https://stockdata.fool.com/cryptoball',
  newTab: true,
} as DatabaseItem

const MICROBALL_PRODUCT_ID = 4710 // Only being entitled to L5, L1-L4 must purchase
const MICROBALL_NAV_ITEM = {
  name: 'Microball',
  href: 'https://stockdata.fool.com/microball',
  newTab: true,
} as DatabaseItem

const AIBALL_PRODUCT_ID = 4716 // Only being entitled to L3+
const AIBALL_NAV_ITEM = {
  name: 'AIball',
  href: 'https://stockdata.fool.com/aiball',
  newTab: true,
} as DatabaseItem

const showPortfolioModal = (
  dispatch: AppDispatch,
  routeToPath: (path: string, origin?: string) => Promise<void>,
  pathname: string,
) => {
  if (pathname.includes(MY_STOCKS_SLUG)) {
    // Already on My Stocks page - just show modal directly
    dispatch(updateActiveForm({activeForm: MY_STOCKS_FORM_TYPE.AddPortfolio}))
    dispatch(modalShowState(true))
    return
  }

  // Set up navigation sequence to ensure modal is shown after navigation
  dispatch(
    setModalAfterNavigation({
      path: MY_STOCK_DEFAULTPAGE,
      origin: 'Create Portfolio in main nav',
      modalConfig: {
        formType: MY_STOCKS_FORM_TYPE.AddPortfolio,
      },
    }),
  )
}

const isMoneyballEntitled = (
  userTier?: UserTier | null,
  userAccessibleProductIds?: number[],
) => {
  if (!userTier || !userAccessibleProductIds?.length) {
    return false
  }
  return (
    userTier.tier >= 1 ||
    userAccessibleProductIds.includes(MONEYBALL_PRODUCT_ID)
  )
}

const isCryptoballEntitled = (
  userTier?: UserTier | null,
  userAccessibleProductIds?: number[],
) => {
  if (!userTier || !userAccessibleProductIds?.length) {
    return false
  }
  return (
    userTier.tier >= 4 ||
    userAccessibleProductIds.includes(CRYPTOBALL_PRODUCT_ID)
  )
}

const isMicroballEntitled = (userAccessibleProductIds?: number[]) => {
  if (!userAccessibleProductIds?.length) {
    return false
  }
  return userAccessibleProductIds.includes(MICROBALL_PRODUCT_ID)
}

const isAIballEntitled = (
  userTier?: UserTier | null,
  userAccessibleProductIds?: number[],
) => {
  if (!userTier || !userAccessibleProductIds?.length) {
    return false
  }
  return (
    userTier.tier >= 3 || userAccessibleProductIds.includes(AIBALL_PRODUCT_ID)
  )
}

/**
 * Creates a navigation item for My Services based on the services a
 * member has. If a member has only one service, it displays the service name
 * instead of "My Services".
 *
 * TODO: We probably should memoize this computation in the MainNavigation
 * component to prevent unnecessary recalculations when services data changes.
 * Note: This would require broader refactoring of the navigation architecture
 * to properly implement - including component typing, function signatures,
 * and data flow between components - without introducing abundant TypeScript
 * errors throughout this file and other navigation components.
 */
export const getMyServicesNavItem = (
  productsWithPrefs: SecondaryNavItem[] | null,
  userTier?: UserTier | null,
  productPortfolios?: Product[] | [],
) => {
  const myServicesIcon = <MyServicesIcon aria-hidden="true" />

  if (!productsWithPrefs?.length) {
    // Just show a skeleton while fetching data because we can't assume what
    // label to show (e.g. "My Services" or "Stock Advisor")
    return {
      name: '',
      nameSkeleton: (
        <Skeleton
          baseColor="#171E4D"
          highlightColor="#373D5B"
          width={120}
          height={20}
        />
      ),
      href: '/my-services',
      disablePrefetch: true,
      icon: myServicesIcon,
    }
  }

  if (productsWithPrefs.length === 1) {
    const singleService = productsWithPrefs[0]
    return {
      name: singleService.name,
      href: singleService.href,
      icon: myServicesIcon,
    }
  }

  return {
    name: 'My Services',
    href: '/my-services',
    icon: myServicesIcon,
    disablePrefetch: true, // Disable prefetch of non-existent /premium/my-services page
    children: [
      ...(productsWithPrefs || []),
      <ServicesTierConditionalItems
        tier={userTier?.tier || 0}
        key="services"
        productPortfolios={productPortfolios ? productPortfolios : []}
      />,
    ],
  }
}

export const getNavItems = (
  productsWithPrefs: SecondaryNavItem[] | null,
  userTier?: UserTier | null,
  userAccessibleProductIds?: number[],
  productPortfolios?: Product[] | [],
  myStockPortfolios?: Portfolio[] | [],
  myStockPortfolioLoadingStatus?: string,
  dispatch?: AppDispatch,
  routeToPath?: (path: string, origin?: string) => void,
  pathname?: string,
) => {
  if (userTier?.tier === 0) {
    return [
      {
        name: 'My Reports',
        href: '/reports',
        icon: <MyReportsIcon aria-hidden="true" />,
      },
    ]
  }

  const databasesChildren = [] as DatabaseItem[]

  if (isMoneyballEntitled(userTier, userAccessibleProductIds)) {
    // Moneyball Database
    databasesChildren.push(MONEYBALL_NAV_ITEM)
  }

  if (isCryptoballEntitled(userTier, userAccessibleProductIds)) {
    // Cryptoball Database
    databasesChildren.push(CRYPTOBALL_NAV_ITEM)
  }

  if (isMicroballEntitled(userAccessibleProductIds)) {
    // Microball Database
    databasesChildren.push(MICROBALL_NAV_ITEM)
  }

  if (isAIballEntitled(userTier, userAccessibleProductIds)) {
    // AIball Database
    databasesChildren.push(AIBALL_NAV_ITEM)
  }

  const toolkitChildren = [
    {
      name: userTier && userTier.tier >= 2 ? 'Fool IQ+' : 'Fool IQ',
      href: 'https://stockdata.fool.com/',
      newTab: true,
    },
    {name: 'Portfolio Strategies', href: '/strategy-tools/strategies'},
    {name: 'Indicators', href: '/strategy-tools/indicators'},
    {name: 'Simulators', href: '/strategy-tools/simulators'},
    {name: 'CAPS', href: 'https://caps.fool.com/', newTab: true},
  ]

  return [
    {name: 'Dashboard', href: '/', icon: <DashboardIcon aria-hidden="true" />},
    ...[
      {
        name: 'New Recs',
        href: '/new-recs',
        icon: <NewRecsIcon aria-hidden="true" />,
      },
    ],
    ...[
      {
        name: 'Rankings',
        href: '/rankings',
        icon: <RankingsIcon aria-hidden="true" />,
      },
    ],
    {
      id: MY_STOCKS_NAVITEM_ID,
      name: 'My Stocks',
      href: '/my-stocks',
      icon: <MyStocksIcon aria-hidden="true" />,
      children: [
        {name: 'All Holdings', href: '/my-stocks'},
        {name: 'All Portfolios', href: '/my-stocks/portfolios'},
        <Divider
          key="divider-my-stocks"
          noBaseStyles
          className="bg-primary-0 h-[1px] min-h-[1px] my-8px"
        />,
        myStockPortfolioLoadingStatus !== 'succeeded' && (
          <Fragment key="skeletons">
            <Skeleton
              baseColor="#171E4D"
              highlightColor="#373D5B"
              width={204}
              height={24}
              className="my-2 px-16px"
            />
            <Skeleton
              baseColor="#171E4D"
              highlightColor="#373D5B"
              width={204}
              height={24}
              className="my-2 px-16px"
            />
            <Skeleton
              baseColor="#171E4D"
              highlightColor="#373D5B"
              width={204}
              height={24}
              className="my-2 px-16px"
            />
          </Fragment>
        ),
        ...(myStockPortfolios && myStockPortfolioLoadingStatus === 'succeeded'
          ? myStockPortfolios.map((portfolio) => ({
              name: portfolio?.name || '',
              icon:
                portfolio?.type === 'plaid' ? (
                  <div className="text-primary-0 inline-flex ml-2 md:ml-1 md:align-[-0.25rem]">
                    <SyncedIndicator aria-hidden="true" />
                  </div>
                ) : (
                  ''
                ),
              href: `/my-stocks/stocks?uuid=${portfolio?.uuid}`,
            }))
          : []),

        <Divider
          key="divider-my-stocks-2"
          noBaseStyles
          className="bg-primary-0 h-[1px] min-h-[1px] my-8px"
        />,
        {
          name: 'Create Portfolio',
          icon: (
            <div className="inline-flex ml-1 md:ml-0 md:align-[-0.15rem]">
              <PlusIcon size="sm" />
            </div>
          ),
          onClick: () =>
            showPortfolioModal(
              dispatch as AppDispatch,
              routeToPath as (path: string, origin?: string) => Promise<void>,
              pathname as string,
            ),
        },
      ],
    },
    {
      name: 'Coverage',
      href: '/news-and-analysis',
      icon: <NewsAndAnalysisIcon aria-hidden="true" />,
      children: [
        {name: 'Articles', href: '/news-and-analysis/articles'},
        {name: 'Analyst Insights', href: '/news-and-analysis/analyst-insights'},
        {name: 'Video Library', href: '/news-and-analysis/media'},
        {name: 'Fool24', href: '/news-and-analysis/fool24'},
        <Divider
          key="divider-coverage"
          noBaseStyles
          className="bg-primary-0 h-[1px] min-h-[1px] my-8px"
        />,
        {
          name: 'Daily News',
          href: `${marketNewsLink}`,
        },
        {
          name: 'Recommendations',
          href: `${recommendationsLink}`,
        },
        {
          name: 'Latest Earnings',
          href: `${latestEarningsLink}`,
        },
        {
          name: 'Research & Analysis',
          href: `${researchAnalysisLink}`,
        },
        {
          name: 'Scoreboard - Latest',
          href: `${scoreboardLatestLink}`,
        },
        {
          name: 'Scoreboard - Leaderboard',
          href: `${scoreboardLeaderboardLink}`,
        },
      ],
    },
    getMyServicesNavItem(productsWithPrefs, userTier, productPortfolios),
    {
      name: 'Databases',
      href: 'https://stockdata.fool.com/',
      icon: <DatabasesIcon aria-hidden="true" />,
      children: databasesChildren,
    },
    {
      name: 'Toolkit',
      href: '/strategy-tools',
      icon: <StrategyToolsIcon aria-hidden="true" />,
      disablePrefetch: true, // Disable prefetch of non-existent /premium/strategy-tools page
      children: toolkitChildren,
    },
    {
      name: userTier && userTier.tier >= 2 ? 'GamePlan+' : 'GamePlan',
      href: '/gameplan',
      icon: <GamePlanIcon aria-hidden="true" />,
    },
    {
      name: 'My Reports',
      href: '/reports',
      icon: <MyReportsIcon aria-hidden="true" />,
    },
    {
      name: 'Discuss',
      href: 'https://community2.fool.com',
      icon: <DiscussIcon aria-hidden="true" />,
      newTab: true,
    },
    {
      name: 'Fool24',
      href: '/news-and-analysis/fool24',
      icon: <Fool24Icon aria-hidden="true" />,
    },
  ]
}

export const checkActiveLink = (item: MainNavItem, pathname: string) => {
  if (item.name === 'Dashboard') {
    return item.href === pathname
  }
  return pathname.includes(item?.href || '')
}

// TODO: Still used by mobile-nav, where desktop (shadcn/radix) nav uses
// PrimaryNavLink from primary-nav-link.tsx. Eventually we should migrate
// mobile-nav to use the new component to avoid maintaining both.
export const PrimaryNavLink = ({
  item,
  pathname,
  isPending,
  onNavigate,
  collapsed,
}: {
  item: NavItem & {href: string}
  pathname: string
  isPending: boolean
  onNavigate: (href: string) => void
  collapsed?: boolean
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (item.onClick) {
        item.onClick()
        return
      }

      if (item.newTab) {
        return
      }

      e.preventDefault()
      onNavigate(item.href!)
    },
    [item, onNavigate],
  )

  return (
    <Link
      href={item.href}
      target={item.newTab ? '_blank' : '_self'}
      onClick={handleClick}
      className={classNames(
        checkActiveLink(item, pathname)
          ? 'bg-content-100 bg-opacity-[.4] font-black hover:bg-secondary-nav'
          : 'group-hover:bg-secondary-nav hover:bg-secondary-nav hover:font-black font-regular',
        'group flex text-primary-0 px-24px py-4 transition-all',
        isPending && 'opacity-70 cursor-wait',
      )}
    >
      <figure
        className={classNames(
          checkActiveLink(item, pathname)
            ? 'text-primary-0'
            : 'text-content-70',
          'size-24px group-hover:text-primary-0 transition ease-in-out duration-300 transform',
          isPending && 'opacity-70', // dim icon during route transition
        )}
      >
        {item.icon}
      </figure>
      {!collapsed && (
        <span className="flex items-center ml-8px">
          {item.nameSkeleton ? item.nameSkeleton : item.name}
          {item.name === 'Fool24' && <Fool24OnAirIndicator />}
        </span>
      )}
    </Link>
  )
}

export const secondaryClasses =
  'p-8px rounded-[4px] flex gap-x-8px hover:bg-content-92 items-center cursor-pointer transition ease-in-out duration-300 transform'

export const ServicesTierConditionalItems = ({
  tier,
  productPortfolios,
}: {
  tier: number
  productPortfolios: Product[] | []
}) => {
  const vaultedServices = productPortfolios?.filter((service) =>
    service?.portfolios?.some((portfolio) => portfolio.vaulted),
  )
  return (
    <>
      <Divider
        noBaseStyles
        className="bg-primary-0 h-[1px] min-h-[1px] my-8px"
      />
      {vaultedServices?.length > 0 && tier >= 4 && (
        <Link href="/my-services/scorecards/vault" className={secondaryClasses}>
          <div className="w-8">
            <VaultIcon />
          </div>
          Vault
        </Link>
      )}
      <Link
        href="/my-services/scorecards/all-services"
        className={secondaryClasses}
      >
        All Services
      </Link>
      {tier >= 5 && (
        <>
          <Divider
            noBaseStyles
            className="bg-primary-0 h-[1px] min-h-[1px] my-8px"
          />
          <div className="uppercase text-label-regular text-content-24 p-8px">
            Fool Worldwide
          </div>
          <Link
            href="https://www.fool.com/premium/one/international-hop/www.fool.com.au/"
            className={secondaryClasses}
          >
            Australia
          </Link>
          <Link
            href="https://www.fool.com/premium/one/international-hop/www.fool.ca/"
            className={secondaryClasses}
          >
            Canada
          </Link>
          <Link
            href="https://www.fool.com/premium/one/international-hop/www.fool.co.uk/"
            className={secondaryClasses}
          >
            United Kingdom
          </Link>
        </>
      )}
    </>
  )
}
