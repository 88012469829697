'use client'
import type {Card, ClassicCard, ContentCards} from '@braze/web-sdk'
import {throttle} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  BrazeContentCard,
  ContentCardExtrasField,
  setContentCards,
} from '~data/client/braze.slice'
import {AppDispatch} from '~data/client/store'
import {BRAZE_API_KEY, BRAZE_BASE_URL, ENVIRONMENT} from '~data/constants'

const CONTENT_CARD_REFRESH_THROTTLE_MS = 3 * 60 * 1000 // 3 mins
const CONTENT_CARD_SITE_FILTER = 'premium'
const PAGE_VIEW_EVENT_NAME: string = 'epic_2_page_view'

export type BrazeConfiguration = {
  apiKey: string
  baseUrl: string
  enableLogging: boolean
}

type BrazeModule = typeof import('@braze/web-sdk')

export class BrazeClient {
  braze: BrazeModule

  constructor(braze: BrazeModule) {
    this.braze = braze
  }

  logContentCardClick(contentCardId?: string) {
    if (!contentCardId) {
      return
    }

    const card = this.braze
      .getCachedContentCards()
      .cards.find((c) => c.id === contentCardId)

    if (card) {
      this.braze.logContentCardClick(card)
    }
  }

  logContentCardImpression(contentCardIds: string[]) {
    const cards = this.braze
      .getCachedContentCards()
      .cards.filter((c) => !!c.id && contentCardIds.includes(c.id))

    if (cards?.length) {
      this.braze.logContentCardImpressions(cards)
    }
  }

  logOnboardingHubDismissed() {
    return new Promise<void>((resolve, reject) => {
      this.braze.logCustomEvent('onboarding-hub-dismissed')
      this.braze.requestContentCardsRefresh()
      this.braze.requestImmediateDataFlush((success) =>
        success ? resolve() : reject(),
      )
    })
  }

  logPageViewEvent(pageName: string | null | undefined, pagePath: string) {
    this.braze.logCustomEvent(PAGE_VIEW_EVENT_NAME, {
      page_path: pagePath,
      page_name: pageName,
    })
  }

  // Braze rate limits this call pretty heavily so let's avoid making any
  // unnecessary calls
  requestContentCardsRefresh = throttle(
    () => this.braze.requestContentCardsRefresh(),
    CONTENT_CARD_REFRESH_THROTTLE_MS,
  )

  setUser(accountId: string) {
    this.braze.changeUser(accountId)
    this.braze.openSession()
  }
}

export function useBraze(): BrazeClient | null {
  const dispatch: AppDispatch = useDispatch()
  const [brazeClient, setBrazeClient] = useState<BrazeClient | null>(null)
  const [isInitializing, setIsInitializing] = useState(false)

  useEffect(() => {
    if (!BRAZE_API_KEY || !BRAZE_BASE_URL || isInitializing) {
      return
    }

    setIsInitializing(true)

    if (brazeClient) {
      return
    }

    import('@braze/web-sdk').then((braze) => {
      braze.initialize(BRAZE_API_KEY!, {
        baseUrl: BRAZE_BASE_URL!,
        enableLogging: ENVIRONMENT === 'development',
        allowUserSuppliedJavascript: true,
        serviceWorkerLocation: '/premium/service-worker.js',
        serviceWorkerScope: '/premium/',
      })

      braze.automaticallyShowInAppMessages()
      braze.subscribeToContentCardsUpdates((contentCards: ContentCards) => {
        // braze.Card and all derived classes contain methods which means we
        // can't put them directly in a Redux store so we'll turn them into
        // JSON-serializable objects first
        const cards = contentCards.cards
          .filter(
            // Ignore all other card types (ImageOnly, CaptionedImage, ControlCard)
            (card: Card): card is ClassicCard =>
              card instanceof braze.ClassicCard &&
              card.extras[ContentCardExtrasField.Site] ===
                CONTENT_CARD_SITE_FILTER,
          )
          .map(
            (card): BrazeContentCard => ({
              id: card.id,
              viewed: card.viewed,
              title: card.title,
              description: card.description,
              created: card.created?.toISOString(),
              url: card.url,
              linkText: card.linkText,
              extras: card.extras,
              pinned: card.pinned,
              dismissible: card.dismissible,
              clicked: card.clicked,
            }),
          )

        dispatch(setContentCards(cards))
      })

      setBrazeClient(new BrazeClient(braze))
    })
  }, [])

  return brazeClient
}
