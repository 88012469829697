export default function VaultIcon({size}: {size?: string}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.333a2.667 2.667 0 0 1 2.667-2.667h18.666A2.667 2.667 0 0 1 28 9.333v13.333a2.667 2.667 0 0 1-2.667 2.667H6.667A2.667 2.667 0 0 1 4 22.666V9.333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13.333"
        cy="15.999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        r="5.333"
      />
      <circle
        cx=".667"
        cy=".667"
        transform="matrix(1 0 0 -1 23.333 13.334)"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        r=".667"
      />
      <path
        d="M25.333 25.334v2.667M6.667 25.334v2.667M24 13.334v6.667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
