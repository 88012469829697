export default function AccessibilityIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 4.2059C17.8574 4.98967 18.9866 6.11438 19.7758 7.46867C20.565 8.82297 20.9868 10.3599 20.9995 11.9273C21.0121 13.4947 20.6152 15.0383 19.848 16.4051C19.0808 17.772 17.9699 18.9148 16.6253 19.7204C15.2807 20.526 13.749 20.9664 12.1819 20.9982C10.6147 21.0299 9.06649 20.6518 7.6904 19.9012C6.3143 19.1507 5.15808 18.0538 4.3362 16.7191C3.51432 15.3844 3.05527 13.8581 3.0045 12.2915L3 11.9999L3.0045 11.7083C3.0549 10.154 3.50719 8.63927 4.31728 7.3118C5.12736 5.98432 6.26759 4.88941 7.6268 4.1338C8.98602 3.37818 10.5178 2.98766 12.0729 3.0003C13.628 3.01293 15.1532 3.4283 16.5 4.2059Z"
        stroke="#4E5474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4987 9.63341C15.711 9.75563 15.8711 9.95155 15.9486 10.184V10.183C16.0259 10.4154 16.0153 10.6681 15.9187 10.8931C15.8221 11.1182 15.6463 11.3 15.4246 11.404L15.3156 11.448L12.9996 12.219V13.196L14.8316 15.946L14.8906 16.046C15.0022 16.2644 15.0296 16.5162 14.9677 16.7535C14.9058 16.9908 14.7589 17.1971 14.5548 17.3331C14.3507 17.4691 14.1038 17.5255 13.8609 17.4913C13.6181 17.4572 13.3962 17.335 13.2376 17.148L13.1676 17.055L11.9996 15.303L10.8316 17.055L10.7556 17.155C10.6094 17.324 10.4108 17.4392 10.1914 17.4822C9.97212 17.5251 9.74473 17.4934 9.54557 17.392L9.44457 17.332L9.34457 17.256C9.17553 17.1098 9.06035 16.9112 9.01739 16.6919C8.97443 16.4726 9.00618 16.2452 9.10757 16.046L9.16757 15.946L10.9996 13.196V12.22L8.68357 11.449L8.57457 11.405C8.35323 11.3007 8.17783 11.1188 8.08158 10.8938C7.98533 10.6688 7.97491 10.4164 8.05229 10.1842C8.12967 9.9521 8.28948 9.75639 8.50147 9.63416C8.71345 9.51193 8.96289 9.47167 9.20257 9.521L9.31557 9.551L11.9996 10.446L14.6836 9.551L14.7966 9.521C15.0365 9.47119 15.2863 9.51119 15.4987 9.63341Z"
        fill="currentColor"
      />
      <path
        d="M10.9389 6.43934C11.2202 6.15804 11.6017 6 11.9996 6C12.3974 6 12.7789 6.15804 13.0602 6.43934C13.3415 6.72064 13.4996 7.10218 13.4996 7.5C13.4996 7.89783 13.3415 8.27936 13.0602 8.56066C12.7789 8.84197 12.3974 9 11.9996 9C11.6017 9 11.2202 8.84197 10.9389 8.56066C10.6576 8.27936 10.4996 7.89783 10.4996 7.5C10.4996 7.10218 10.6576 6.72064 10.9389 6.43934Z"
        fill="currentColor"
      />
    </svg>
  )
}
