export default function DiscussIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.5 20L12.5 16H18C19 16 20 15 20 14V6C20 5 19 4 18 4H6C5 4 4 5 4 6V14C4 15 5 16 6 16H7.5V20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
