'use client'
// make client in case we need to hook into an load events.
import Script from 'next/script'

const toggleWidget = () => {
  if (window.UserWay) {
    window.UserWay.widgetToggle()
  }
}

function UserWayLoader() {
  return (
    <>
      <Script
        src="https://accessibilityserver.org/widget.js"
        data-account="JPpJAc3inv"
        defer={true}
      />
    </>
  )
}

export {UserWayLoader, toggleWidget}
